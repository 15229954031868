import React, { Component } from 'react'
import { uuid } from 'utils'
import { TextField, Card, CardContent, Button, Slider } from '@material-ui/core';
import { consume, SnackbarContext } from 'contexts'
import { FormContext, ErrorBanner, ConfirmationDialog, LabeledCheckbox } from 'components'
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Close'
import UndoIcon from '@material-ui/icons/Undo'
import FilterIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import { escapeRegex, compose } from 'utils'
import withStyles from 'styles'
import { get } from 'lodash'

const TAG_PAGE_SIZE = 50
const DEFAULT_TAG_WEIGHT = 5000
export class TagList extends Component {

  state = { newTag: "", pageSize: TAG_PAGE_SIZE, filter: '', showDeleted: false }

  constructor(props) {
    super(props)
    this.htmlElmId = uuid()
  }

  handleAddTag = () => {
    const text = this.state.newTag.toLowerCase().trim()
    if (text.match(/^[A-Za-z0-9 ]+$/)) {
      this.setState({ newTag: "", newTagError: undefined })
      if (!(this.props.value || []).find(t => t.text === text)) {
        this.props.onChange && this.props.onChange({ target: { value: [{ text, weight: DEFAULT_TAG_WEIGHT, autoTag: false, deleted: false }, ...this.props.value] } })
      } else {
        this.setState({ newTagError: "Tag already exists for this tile" })
      }
    } else {
      this.setState({ newTagError: "Tag must contain only letters and numbers" })
    }
  }

  handleRemoveTag = (tag) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    const value = [...this.props.value]
    const deleteIndex = value.findIndex((e) => e.text === tag)
    value[deleteIndex] = { ...value[deleteIndex], deleted: !value[deleteIndex].deleted }
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  handleEditConfirm = () => {
    const value = [...this.props.value]
    const editIndex = value.findIndex((e) => e.text === this.state.editTag.text)
    value[editIndex] = { ...this.state.editTag, autoTag: false }
    this.props.onChange && this.props.onChange({ target: { value } })
    this.setState({ editTag: undefined })
  }

  addTagKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleAddTag()
      event.preventDefault();
      event.stopPropagation()
    }
  }

  render = () => {
    const fromIndex = (this.state.page || 0) * this.state.pageSize
    const toIndex = fromIndex + this.state.pageSize

    const filterParts = this.state.filter.split(/\s+/g)
    const filtered = this.state.filter ?
      (this.props.value || []).filter(tag => (tag.deleted === this.state.showDeleted && filterParts.every(part => tag.text.toLowerCase().match(escapeRegex(part.toLowerCase()))))) :
      (this.props.value || []).filter(tag => tag.deleted === this.state.showDeleted)
    const tags = filtered.slice(fromIndex, toIndex)
    const showMore = (filtered.length > tags.length)

    return (
      <Card>
        <CardContent>
          <ConfirmationDialog title={`Set Tag Rank - ${get(this.state, 'editTag.text')}`} open={!!this.state.editTag} onConfirm={this.handleEditConfirm}
            onCancel={() => this.setState({ editTag: undefined })}>
            <div style={{ width: 300, padding: 10 }}>
              <Slider
                style={{ marginTop: 20 }}
                defaultValue={20}
                valueLabelFormat={(v) => `${(Math.ceil(v / 100))}%`}
                aria-labelledby="tag rank"
                step={10}
                min={0}
                max={10000}
                valueLabelDisplay="auto"
                value={get(this.state, 'editTag.weight')}
                onChange={(e, weight) => this.setState({ editTag: { ...this.state.editTag, weight } })}
              />
            </div>
          </ConfirmationDialog>
          <FormContext context={this.state} onChange={(state) => this.setState({ ...state })}>
            <TextField fullWidth member="filter" label="Filter" InputProps={{
              startAdornment:
                <InputAdornment position="end">
                  <FilterIcon />
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={() => this.setState({ filter: '' })}>
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
            }} />
            <LabeledCheckbox member="showDeleted" />
            <div className={this.props.classes.tagList}>
              <div className={this.props.classes.tagContainer}>
                {tags.map((tag, i) =>
                  <div className={this.props.classes.tag} key={tag.text} onClick={() => this.setState({ editTag: { ...tag } })}
                    style={{ background: `hsl(245,${parseInt(tag.weight / 25)}%,80%)` }}>
                    <span className={this.props.classes.tagText}>{tag.text}</span>
                    <span className={this.props.classes.tagText}>{Math.ceil(tag.weight / 100)}%</span>
                    <IconButton onClick={this.handleRemoveTag(tag.text)} className={this.props.classes.removeButton}>
                      {tag.deleted ? <UndoIcon /> : <DeleteIcon />}
                    </IconButton>
                  </div>)}
              </div>
              <div>
                {showMore &&
                  <Button fullWidth variant='contained' color='primary' onClick={() => this.setState({ pageSize: (this.state.pageSize + TAG_PAGE_SIZE) })}>Show More...</Button>
                }
              </div>
            </div>
            <ErrorBanner>
              {this.state.newTagError}
            </ErrorBanner>
            <TextField fullWidth member="newTag" label="Add Tag" onKeyDown={this.addTagKeyDown} error={!!this.state.newTagError}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleAddTag}>
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
              }} />
          </FormContext>
        </CardContent>
      </Card>
    )
  }
}

const styles = {
  tag: {
    display: 'inline-block',
    margin: 4,
    cursor: 'pointer',
    padding: '0px 4px 0px 4px',
    marginRight: 4,
    borderRadius: 4,
    fontSize: "0.8em",
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  tagText: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  tagContainer: {
    marginTop: 8,
    marginBottom: 8,
  },
  tagList: {
    marginBottom: 16,
  },
  removeButton: {
    padding: 0,
  }
}

export default compose(
  withStyles(styles),
  consume(SnackbarContext),
)(TagList)