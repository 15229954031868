class TokenStore{
  set auth(value){
    global.localStorage.auth = value || ''
  }

  get auth(){
    return global.localStorage.auth
  }

  set refresh(value){
    global.localStorage.refresh = value || ''
  }

  get refresh(){
    return global.localStorage.refresh
  }

  set actorType(value){
    global.localStorage.actorType = value || ''
  }

  get actorType(){
    return global.localStorage.actorType
  }

  destroy = async () => {
    global.localStorage.removeItem('auth')
    global.localStorage.removeItem('refresh')
    await this.actorUnauthenticated()
  }

  actorUnauthenticated = async () => {
    const actorType = this.actorType
    global.localStorage.removeItem('actorType')
    if (actorType === 'merchant' && process.env.REACT_APP_MERCHANT_DASHBOARD_URL) {
      window.location.href = process.env.REACT_APP_MERCHANT_DASHBOARD_URL
      await new Promise(resolve => setTimeout(resolve, 5000)) // Delay to prevent display of SD login page
    }
  }

  protected = endpoint => {
    endpoint.headers(headers => {
      if(this.auth) {
        headers.Authorization = `Bearer ${this.auth}`
        headers['X-Authorization'] = `Bearer ${this.auth}`
      }
      return headers
    })

    endpoint.resultsHandler(result => {
      if(result && result.data && result.data.type === 'credentials' && result.data.attributes){
        this.auth    = result.data.attributes.auth
        this.refresh = result.data.attributes.refresh
      }
      return result
    })
    return endpoint
  }
}

const TokenStoreSingleton = new TokenStore()
const ProtectResourceDecorator = TokenStoreSingleton.protected.bind(TokenStoreSingleton)
export { TokenStoreSingleton as TokenStore, ProtectResourceDecorator }
