import React, { Component } from 'react'
import qs from "qs";
import { withRouter } from 'react-router-dom'

class QueryStringProvider extends Component {

  constructor(props){
    super(props)
    this.state = {urlState: this.urlState}
  }

  componentDidMount(){
    this.setState({urlState: this.urlState})
  }

  get urlState(){
    return this.buildURLState(this.parsedURLState()[this.props.nameSpace] || {})
  }

  parsedURLState = () => qs.parse(window.location.search.slice(1), { allowDots: true })

  serializeUrlState = (state) => {
    const sharedUrlState = this.parsedURLState()
    const mergedState    = {...sharedUrlState[this.props.nameSpace], ...state}
    const qsObject       = sharedUrlState[this.props.nameSpace] = {...mergedState.filter} || {}

    Object.keys(qsObject).forEach((key) => {
      if (qsObject[key] === '' || qsObject === undefined || (this.props.filter || {})[key] !== undefined)
        delete qsObject[key]
    })
    if (mergedState.page > 1) qsObject.page  = mergedState.page
    if (mergedState.order)    qsObject.order = mergedState.order
    return [qs.stringify(sharedUrlState, {arrayFormat: 'repeat', allowDots: true}), this.buildURLState(qsObject)]
  }

  updateUrlState = (state, callback) => {
    const [queryString, updatedState] = this.serializeUrlState(state)
    this.props.history.replace(`${window.location.pathname}?${queryString}`)
    this.setState({urlState: updatedState}, callback)
  }

  buildURLState(inputState) {
    const {page, order, ...filter} = inputState
    return {
      page: Number(page) || 1,
      order: order || null,
      filter: filter || {}
    }
  }

  handleOrderChange = (order, callback) => {
    this.updateUrlState({order, page: 1}, callback)
  }

  handleFilterChange = (filter, callback) => {
    this.updateUrlState({filter, page: 1}, callback)
  }

  handlePageChange = (page, callback) => {
    this.updateUrlState({...this.urlState, page}, callback)
  }

  render = () => {
    const { type: Type, ...props } = this.props
    const { order, page, filter } = this.state.urlState
    return (
      <Type
        {...props}
        order={order}
        page={page}
        filter={{...this.props.filter, ...filter}}
        onOrderChange={this.handleOrderChange}
        onFilterChange={this.handleFilterChange}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const QueryStringComponent = withRouter(QueryStringProvider)
export default nameSpace => Type => props =>
  <QueryStringComponent type={Type} nameSpace={nameSpace} {...props}/>