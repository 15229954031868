import React, { Component } from 'react'
import { DatePicker as DPicker } from '@material-ui/pickers';

export class DatePicker extends Component{

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? date.format('YYYY-MM-DDThh:mm:ssZ') : date
      }
    })
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  openOnFocus = () =>{
    if(this._picker && !this.value){
      this._picker.wrapper.open()
      this._picker = null
    }
  }

  render = () =>
    <DPicker
      format="DD/MM/YYYY"
      {...this.props}
      //ref={_picker => this._picker = _picker}
      onChange={this.handleChange}
      value={this.value}
      onFocus={this.openOnFocus}
    />

}

export default DatePicker