import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'

export class Routes extends Component{

  render = () =>
    <Switch>
      <Route exact path="/segments"                    component={Containers.Segment.List} requires={Authorization.superAdmin}/>
      <Route exact path="/segments/new"                component={Containers.Segment.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/segments/:id"                component={Containers.Segment.Show} requires={Authorization.superAdmin}/>
      <Route exact path="/segments/:id/edit"           component={Containers.Segment.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/variant_types"                    component={Containers.VariantType.List} requires={Authorization.superAdmin}/>
      <Route exact path="/variant_types/new"                component={Containers.VariantType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/variant_types/:id"                component={Containers.VariantType.Show} requires={Authorization.superAdmin}/>
      <Route exact path="/variant_types/:id/edit"           component={Containers.VariantType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/explore_feeds"                    component={Containers.ExploreFeed.List} requires={Authorization.contentAdmin}/>
      <Route exact path="/explore_feeds/new"                component={Containers.ExploreFeed.Form} requires={Authorization.contentAdmin}/>
      <Route exact path="/explore_feeds/:id/edit"           component={Containers.ExploreFeed.Form} requires={Authorization.contentAdmin}/>
      <Route exact path="/module_types"                    component={Containers.ModuleType.List} requires={Authorization.superAdmin}/>
      <Route exact path="/module_types/new"                component={Containers.ModuleType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/module_types/:id/edit"           component={Containers.ModuleType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/requested_changes"                    component={Containers.RequestedChange.List}/>
      <Route exact path="/requested_changes/tiles"              component={Containers.RequestedChange.ListTiles}/>
      <Route exact path="/stores"                    component={Containers.Store.List}/>
      <Route exact path="/stores/new"                component={Containers.Store.Form}/>
      <Route exact path="/stores/:id"                component={Containers.Store.Show}/>
      <Route exact path="/stores/:id/edit"           component={Containers.Store.Form}/>
      <Route exact path="/file_uploads"              component={Containers.FileUpload.List} requires={Authorization.superAdmin}/>
      <Route exact path="/tags"                    component={Containers.Tag.List} requires={Authorization.superAdmin}/>
      <Route exact path="/tags/new"                component={Containers.Tag.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/tags/:id"                component={Containers.Tag.Show} requires={Authorization.superAdmin}/>
      <Route exact path="/tags/:id/edit"           component={Containers.Tag.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/merchants"                    component={Containers.Merchant.List}/>
      <Route exact path="/merchants/new"                component={Containers.Merchant.Form}/>
      <Route exact path="/merchants/:id"                component={Containers.Merchant.Show}/>
      <Route exact path="/merchants/:id/edit"           component={Containers.Merchant.Form}/>
      <Route exact path="/tiles"                    component={Containers.Tile.List}/>
      <Route exact path="/tiles/new"                component={Containers.Tile.Form}/>
      <Route exact path="/tiles/:id"                component={Containers.Tile.Show}/>
      <Route exact path="/tiles/:id/edit"           component={Containers.Tile.Form}/>
      <Route exact path="/tiles/:id/promotions/new"                component={Containers.Promotion.Form}/>
      <Route exact path="/promotions/:id/edit"           component={Containers.Promotion.Form}/>
      <Route exact path="/categories"                    component={Containers.Category.List} requires={Authorization.contentAdmin}/>
      <Route exact path="/categories/new"                component={Containers.Category.Form} requires={Authorization.contentAdmin}/>
      <Route exact path="/categories/:id"                component={Containers.Category.Show} requires={Authorization.contentAdmin}/>
      <Route exact path="/categories/:id/order"          component={Containers.Category.Order} requires={Authorization.contentAdmin}/>
      <Route exact path="/categories/:id/grid"           component={Containers.Category.Grid} requires={Authorization.contentAdmin}/>
      <Route exact path="/categories/:id/edit"           component={Containers.Category.Form} requires={Authorization.contentAdmin}/>
      <Route exact path="/media_items/:id"                component={Containers.MediaItem.Show}/>
      <Route exact path="/order_types"                    component={Containers.OrderType.List} requires={Authorization.superAdmin}/>
      <Route exact path="/order_types/new"                component={Containers.OrderType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/order_types/:id"                component={Containers.OrderType.Show} requires={Authorization.superAdmin}/>
      <Route exact path="/order_types/:id/edit"           component={Containers.OrderType.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/profile"                    component={Containers.User.Profile}/>
      <Route exact path="/users"                      component={Containers.User.List} requires={Authorization.superAdmin}/>
      <Route exact path="/users/new"                  component={Containers.User.Form} requires={Authorization.superAdmin}/>
      <Route exact path="/users/:id"                  component={Containers.User.Show} requires={Authorization.superAdmin}/>
      <Route exact path="/users/:id/edit"             component={Containers.User.Form} />
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/"                           component={() =>  <Redirect to={"/tiles"}/>}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} />
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.superAdmin}/>
      <Route component={Containers.Status.NotFound} />
    </Switch>

}

export default withRouter(Routes)