import React from "react"
import Autocomplete from "./Autocomplete"
import { humanize } from "utils"
import * as API from 'api'
import PropTypes from "prop-types"
import pluralize from 'pluralize'
import {camelized, capitalize} from 'utils'

export const AutocompleteSearch = (props) => {
  const {
    name,
    fields,
    subject = name,
    searchableField = "name",
    filterName = "search",
    filter,
    ...rest
  } = props

  const defaultOnSuggestionsFetchRequested = async (text, callback) => {
    const type = pluralize(camelized(subject, {lower: true}))
    const klass = capitalize(type)
    const { data } = await API[klass].index({
      options: {
        fields: fields || {[type]: `id,${searchableField}`},
        filter: { [filterName]: text, ...filter },
        page: { number: 1, size: 15 }
      }
    })
    callback(data)
  }

  const defaultGetOptionLabel = option => {
    if (option && option[searchableField]) {
      return option[searchableField]
    }

    return ""
  }

  const getOptionLabel = props.getOptionLabel || defaultGetOptionLabel
  const onSuggestionsFetchRequested = props.onSuggestionsFetchRequested || defaultOnSuggestionsFetchRequested

  return (
      <Autocomplete
          key={name}
          fullWidth
          name={name}
          label={props.label || humanize(name)}
          getOptionLabel={getOptionLabel}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          {...rest}
      />
  )
}

AutocompleteSearch.propTypes = {
  name: PropTypes.string.isRequired,
  subject: PropTypes.string,
  getOptionLabel: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  searchableField: PropTypes.string,
  filterName: PropTypes.string,
}

export default AutocompleteSearch