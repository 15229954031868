import React, { useState, useEffect } from 'react'
import { getLinesForWidth } from 'utils'
import styles from 'css/merchant_portal/tiles/portrait_tile_preview.module.css'

const deriveImageUrl = (image, setImageUrl) => {
  if (typeof image === "string") {
    setImageUrl(image)
  } else if (image instanceof File || image instanceof Blob) {
    const url = URL.createObjectURL(image)
    setImageUrl(url)
    return () => URL.revokeObjectURL(url)
  } else if (image?.url) {
    setImageUrl(image?.url)
  } else {
    setImageUrl(undefined)
  }
}

const PortraitTilePreview = ({ tileImage, logoImage, logoText }) => {
  const [logoTextLines, setLogoTextLines] = useState([])
  const [logoTextWidth, setLogoTextWidth] = useState(250)

  const [tileUrl, setTileUrl] = useState()
  const [logoUrl, setLogoUrl] = useState()

  useEffect(() => {
    if (logoText) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = "bold 50px CircularStd,sans-serif";

      // Min width target 250, max 350
      const lines = logoText.split('\n').flatMap(text => getLinesForWidth(ctx, text, 350))
      const measuredWidth = Math.max(...lines.map(line => ctx.measureText(line).width), 250)
      setLogoTextLines(lines)
      setLogoTextWidth(Math.ceil(measuredWidth))
    } else {
      setLogoTextLines([])
    }
  }, [logoText])

  useEffect(() => {
    return deriveImageUrl(tileImage, setTileUrl)
  }, [tileImage, setTileUrl])

  useEffect(() => {
    return deriveImageUrl(logoImage, setLogoUrl)
  }, [logoImage, setLogoUrl])

  return (
    <div className={styles.tileWrapper}>
      <div className={styles.tileImages}>
        {tileImage && tileImage !== '_delete' && <div className={styles.tileImage}>
          <img key={tileUrl || 'unknown'} src={tileUrl} alt='Tile Image' draggable="false" />
        </div>}
        {logoImage && logoImage !== '_delete' && <div className={styles.tileDarkOverlay} />}
        {logoImage && logoImage !== '_delete' && <div className={styles.logoImage}>
          <img src={logoUrl} alt='Tile Logo' draggable="false" />
        </div>}
        {(!logoImage || logoImage === '_delete') && logoText && <div id="tilePreviewSvgContainer" className={styles.logoImage}>
          <svg viewBox={`0 0 ${Math.ceil(logoTextWidth || 0)} ${logoTextLines.length * 50}`} xmlns="http://www.w3.org/2000/svg">
            <style dangerouslySetInnerHTML={{ __html: `.logo-text { font: bold 50px CircularStd,sans-serif; }` }} />
            {(logoTextLines || []).map((line, i) =>
              <text key={i} fill="white" x={`${Math.floor(logoTextWidth / 2)}`} y={`${(i + 1) * 50 - 6}`} textAnchor="middle" className="logo-text">{line}</text>)}
          </svg>
        </div>}
      </div>
    </div>
  )
}

export default PortraitTilePreview