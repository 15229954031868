import React, { Component } from 'react'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, consume } from 'contexts'

export class Reset extends Component{

  get token(){
    return window.location.search.replace(/^.*?token=([^$&]+)/,'$1')
  }

  submit = async () => {
    await this.props.tokens.actions.reset({...this.state, token: this.token})
    await this.props.tokens.actions.verify()
    this.props.history.push('/')
  }

  errorFor = key => {
    try{
      return this.props.tokens.errors.reset.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} errorContext={{
        password:             this.errorFor('password'),
        passwordConfirmation: this.errorFor('passwordConfirmation'),
      }} onChange={state => this.setState(state)}>
        <CardContent>
          <Typography variant='h6'>Reset your password</Typography>
          {this.props.tokens.errors.reset && <ErrorBanner>{this.props.tokens.errors.reset.title}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth member='password'             type='password' autoFocus/>
            <TextField fullWidth member='passwordConfirmation' type='password' label='Password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button variant='contained' color='secondary' fullWidth onClick={this.submit}>Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export default consume(TokensContext)(Reset)