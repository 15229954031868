import BaseContext from 'contexts/base/BaseContext'
import * as API from 'api'

export default class VersionsContext extends BaseContext {
  static contextName = 'versions'

  static initialState = {
    ...this.initialState,
    tag: ''
  }

  async getTag(){
    const {data: { tag }} = await API.API.tag()
    this.setState({tag})
  }
}
