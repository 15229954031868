import React, { Component } from 'react'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import { SnackbarContext, TokensContext, consume } from 'contexts'

export class ResendConfirmation extends Component{

  submit = async () => {
    if(this.state.submitting)
      return
    this.setState({submitting: true})
    try{
      await this.props.tokens.actions.resendConfirmation(this.state)
      this.props.snackbar.actions.show("Request Sent")
      this.props.history.push('/confirmation_resent')
    }finally{
      this.setState({submitting: false})
    }
  }

  renderError = () =>
    <ErrorBanner>
      {this.props.tokens.errors.resendConfirmation}
    </ErrorBanner>

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} onChange={state => this.setState(state)} onSubmit={this.submit}>
        <CardContent>
          <Typography variant='h6'>Didn't receive your confirmation email?</Typography>
          <Typography variant='body2'>Enter your email address to resend the confirmation email</Typography>
          {this.props.tokens.errors.resendConfirmation && <p>{this.renderError()}</p>}
          <TextField member='email' fullWidth/>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' color="primary" onClick={this.submit}>Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export default consume(TokensContext, SnackbarContext)(ResendConfirmation)