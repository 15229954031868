import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ModuleContentEditor from './ModuleContentEditor'
import { ConfirmationDialog, LabeledSelect } from 'components'
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Delete"
import { nextKey } from 'utils'

const ModuleListEditor = ({ value, onChange, moduleTypes, errors }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [addType, setAddType] = useState('')

  const addItem = () => {
    onChange({ target: { value: [...(value || []), { key: nextKey(value), moduleType: addType, moduleContent: {} }] } })
    setAddType('')
    setAddDialogOpen(false)
  }

  const handleChange = ({ target: { value: moduleContent } }) => {
    const newList = [...value]
    newList.splice(activeIndex, 1, { ...newList[activeIndex], moduleContent })
    onChange({ target: { value: newList } })
  }

  const renderDropZone = (provided, outerState) => (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ flex: '1 1 1' }}>
      {(value || []).map(renderItem)}
      {provided.placeholder}
      <div>
        <Droppable droppableId="remove" direction={'vertical'}>
          {(provided, innerState) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
              style={{
                flex: '1 1 1', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center',
                background: innerState.isDraggingOver ? '#ffdbdb' : 'transparent'
              }}>
              <IconButton onClick={() => setAddDialogOpen(true)} disabled={!!outerState.draggingFromThisWith}>
                {!outerState?.draggingFromThisWith ? <AddIcon /> : <RemoveIcon/>}
              </IconButton>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )

  const renderItem = (item, index) => (
    <Draggable key={item.key} draggableId={item.key} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} onClick={() => setActiveIndex(index)}>
          <div style={{ margin: '8px 0px', padding: 8, borderRadius: 10, background: index === activeIndex ? 'aliceblue' : '#EEE', color: 'black' }}>
            {item.moduleType}
          </div>
        </div>
      )}
    </Draggable>
  )

  const onDragEnd = ({ source, destination }) => {
    if (destination?.droppableId === 'remove') {
      const newList = [...value]
      newList.splice(source.index, 1);
      const newActiveIdx = newList.findIndex(val => val === value[activeIndex])
      setActiveIndex(newActiveIdx >= 0 ? newActiveIdx : 0)
      onChange({ target: { value: newList } })
    } else if (source && destination) {
      const newList = [...value]
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      setActiveIndex(newList.findIndex(val => val === value[activeIndex]))
      onChange({ target: { value: newList } })
    }
  }

  return <div>
    <div style={{ color: 'gray', marginTop: 16 }}>Modules</div>
    <ConfirmationDialog title="Add Module"
      open={addDialogOpen}
      onConfirm={addItem}
      onCancel={() => setAddDialogOpen(false)}
      canConfirm={!!addType}
      confirmLabel="Add"
      cancelLabel="Cancel">
      <LabeledSelect keyField="name" textField="name" options={moduleTypes} label="Module Type" fullWidth
        value={addType} onChange={({ target: { value } }) => setAddType(value)} />
    </ConfirmationDialog>
    <div style={{ display: 'flex' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction={'vertical'}>
          {renderDropZone}
        </Droppable>
      </DragDropContext>
      <div style={{ flex: '1 1 100%', marginLeft: 16, overflow: 'hidden' }}>
        {value?.[activeIndex] && <ModuleContentEditor module={value[activeIndex]} moduleTypes={moduleTypes} onChange={handleChange} errors={errors} moduleIndex={activeIndex} />}
      </div>
    </div>
  </div>
}

export default ModuleListEditor