import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { compose, isTouchDevice, uuid } from 'utils'
import withStyles from 'styles'
import { FormHelperText, IconButton, InputLabel } from '@material-ui/core'
import Clear from '@material-ui/icons/Clear'

export class FileDropZone extends Component {
  state = {
    fileInputId: uuid(),
    backgroundImage: 'none',
    dragging: false,
  }

  handleDrop = async event => {
    event.preventDefault();
    event.stopPropagation()
    const file = event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0]
    this.handleChange(file)
  }

  handleFileSelected = ({ target: { files: { "0": file } } }) => {
    this.fileInput.value = null
    this.handleChange(file)
  }

  handleDragOver = event => {
    event.preventDefault()
    if (!this.state.dragging) {
      this.setState({ dragging: true })
    }
  }

  handleDragEnter = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDragLeave = event => {
    this.setState({ dragging: false })
  }

  handleDragExit = event => {
    this.setState({ dragging: false })
  }

  handleSetFileInputRef = fileInput => {
    this.fileInput = fileInput
  }

  handleToggleFileInput = (e) => {
    this.fileInput.click()
    e.stopPropagation()
  }

  componentDidMount = () => {
    this.loadBackgroundImage()
  }

  componentDidUpdate = (prevProps) => {
    this.loadBackgroundImage(prevProps.value)
  }

  loadBackgroundImage = (prevValue) => {
    if (this.props.type === "image" && prevValue !== this.props.value) {
      this.setState({ backgroundImage: 'none' })
      if (this.props.value instanceof File || this.props.value instanceof Blob) {
        this.loadBackgroundImageFromFile(this.props.value)
      } else if (this.props.value && typeof this.props.value === 'string') {
        this.setState({ backgroundImage: 'url("' + this.props.value + '")', uploading: false })
      } else if (this.props.value && this.props.value.url) {
        this.setState({ backgroundImage: 'url("' + this.props.value.url + '")', uploading: false })
      } else {
        this.setState({ backgroundImage: 'none', uploading: false })
      }
    }
  }

  loadBackgroundImageFromFile = (file) => {
    if (this.props.type === "image" && FileReader && file) {
      var fr = new FileReader();
      fr.onload = () => {
        this.setState({ backgroundImage: 'url("' + fr.result + '")' })
      }
      fr.readAsDataURL(file);
    } else {
      this.setState({ backgroundImage: 'none' })
    }
  }

  handleChange = async file => {
    this.setState({ dragging: false })
    if (this.props.mutateFile) {
      file = await this.props.mutateFile(file)
    }
    const promise = (this.props.onChange && this.props.onChange({ target: { value: file } }))
    if (this.props.immediateUpload) {
      this.setState({ backgroundImage: 'none', uploading: true })
      if (promise != null && typeof promise.then === 'function') {
        promise.then(() => this.setState({ uploading: false }))
      }
    } else if (!this.props.noImmediateDisplay) {
      this.loadBackgroundImageFromFile(file)
    }
  }

  handleClear = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const clearValue = this.props.clearValue === undefined ? null : this.props.clearValue
    this.props.onChange && this.props.onChange({ target: { value: clearValue } })
  }

  render = () => {
    const { label, noLabel, error, helperText } = this.props
    return (
      <Fragment>
        {!noLabel && <InputLabel htmlFor={this.state.fileInputId} style={{ padding: '10px 0 5px 0' }}>{label}</InputLabel>}
        <input style={{ display: 'none' }} ref={this.handleSetFileInputRef} type='file' id={this.state.fileInputId} onChange={this.handleFileSelected} onClick={e => e.stopPropagation()} />
        <Button
          style={{
            backgroundImage: this.state.backgroundImage,
            width: (this.props.width || '100%'),
            height: (this.props.height || 250),
            minWidth: (this.props.width ? 'auto' : 250),
            position: 'relative',
            padding: (this.props.padding || 0),
          }}
          className={
            this.props.classes({
              dragging: this.state.dragging,
              error: !!error,
              dropZone: true,
            })
          } onDrop={this.handleDrop}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          onDragExit={this.handleDragExit}
          onClick={this.handleToggleFileInput}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          {(this.state.uploading || this.state.dragging || noLabel || this.props.noDropLabel) ? "" : <div style={{ background: '#FFFB', borderRadius: 5, padding: '2px 5px 2px 5px' }}>{this.props.message || "Drop or Click here"}</div>}
          {this.state.uploading && <div style={{ background: '#FFFB', borderRadius: 5, padding: '2px 5px 2px 5px' }}>{"Uploading..."}</div>}
          {this.props.clearable && (this.state.hover || isTouchDevice()) && <IconButton style={{ position: 'absolute', top: 2, right: 2, color: 'white', background: '#00000044', padding: 4 }} onClick={this.handleClear}><Clear /></IconButton>}
        </Button>
        <FormHelperText error={error} style={{ maxWidth: this.props.width }}>{helperText}</FormHelperText>
      </Fragment>
    )
  }
}

const styles = ({ palette }) => ({
  dropZone: {
    boxSizing: 'border-box',
    position: 'relative',
    height: 250,
    minWidth: 250,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'silver',
    display: 'flex',
    cursor: 'pointer',
    padding: 10,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundOrigin: 'content-box',
  },
  dragging: {
    backgroundImage: 'none',
    backgroundColor: '#efefef',
    border: '3px dashed silver'
  },
  error: {
    border: '3px solid #f44336',
  }
})

export default compose(
  withStyles(styles),
)(FileDropZone)