import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, VariantTypesContext } from 'contexts'

export class Show extends Component{

  static fetchDependencies({variantTypes,  match: { params: { id }}}){
    return variantTypes.actions.show(id, {
      include: ''
    })
  }

  get variantType(){
    return this.props.variantTypes.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Variant Type</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/variant_types/${this.variantType.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Type code</TableCell>
                <TableCell>{String(this.variantType.typeCode || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Description</TableCell>
                <TableCell>{String(this.variantType.description || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Width</TableCell>
                <TableCell>{String(this.variantType.width || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Height</TableCell>
                <TableCell>{String(this.variantType.height || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Enforce pixel size</TableCell>
                <TableCell>{String(this.variantType.enforcePixelSize || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Enforce ratio</TableCell>
                <TableCell>{String(this.variantType.enforceRatio || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Enforce minimum size</TableCell>
                <TableCell>{String(this.variantType.enforceMinimumSize || '')}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default provide(VariantTypesContext)(Dependent(Show))