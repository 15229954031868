// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    forgot: endpoint => endpoint.method('post').path(() => {
      return '/tokens/forgot'
    }),
    reset: endpoint => endpoint.method('post').path(() => {
      return '/tokens/reset'
    }),
    confirm: endpoint => endpoint.method('post').path(() => {
      return '/tokens/confirm'
    }),
    resendConfirmation: endpoint => endpoint.method('post').path(() => {
      return '/tokens/resend_confirmation'
    }),
    acceptInvite: endpoint => endpoint.method('post').path(() => {
      return '/tokens/accept_invite'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Segments = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/segments'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/segments'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/segments/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/segments/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/segments/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const CategoryFeaturedTiles = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/category_featured_tiles'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/category_featured_tiles'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/category_featured_tiles/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/category_featured_tiles/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/category_featured_tiles/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const VariantTypes = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/variant_types'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/variant_types'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/variant_types/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/variant_types/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/variant_types/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const ExploreFeedVersions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/explore_feed_versions'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/explore_feed_versions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/explore_feed_versions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const ModuleTypes = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/module_types'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/module_types'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/module_types/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/module_types/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/module_types/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const ExploreFeeds = ProtectedJsonAPI.extend(
  '',
  {
    showSegment: endpoint => endpoint.path(({countryCode, segment}) => {
      if (countryCode && segment) {
        return `/explore_feeds/segment/${countryCode}/${segment}`
      }
      throw new ApiPathError('show_segment', {countryCode, segment})
    }),
    index: endpoint => endpoint.path(() => {
      return '/explore_feeds'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/explore_feeds'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/explore_feeds/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/explore_feeds/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/explore_feeds/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const FileDownloads = ProtectedJsonAPI.extend(
  '',
  {
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/file_downloads/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const Stores = ProtectedJsonAPI.extend(
  '',
  {
    requestChange: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/stores/${id}/request_change`
      }
      throw new ApiPathError('request_change', {id})
    }),
    csvGenerate: endpoint => endpoint.method('post').path(() => {
      return '/stores/csv_generate'
    }),
    requestNew: endpoint => endpoint.method('post').path(() => {
      return '/stores/request_new'
    }),
    index: endpoint => endpoint.path(() => {
      return '/stores'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/stores'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/stores/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/stores/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/stores/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const MediaItems = ProtectedJsonAPI.extend(
  '',
  {
    create: endpoint => endpoint.method('post').path(() => {
      return '/media_items'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/media_items/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)

export const FileUploads = ProtectedJsonAPI.extend(
  '',
  {
    undo: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/file_uploads/${id}/undo`
      }
      throw new ApiPathError('undo', {id})
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/file_uploads'
    }),
    index: endpoint => endpoint.path(() => {
      return '/file_uploads'
    }),
  }
)

export const Tags = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/tags'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tags'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tags/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/tags/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/tags/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Merchants = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/merchants'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/merchants'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/merchants/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/merchants/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/merchants/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Promotions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({tileId}) => {
      if (tileId) {
        return `/tiles/${tileId}/promotions`
      }
      throw new ApiPathError('index', {tileId})
    }),
    create: endpoint => endpoint.method('post').path(({tileId}) => {
      if (tileId) {
        return `/tiles/${tileId}/promotions`
      }
      throw new ApiPathError('create', {tileId})
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Tiles = ProtectedJsonAPI.extend(
  '',
  {
    requestChange: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/tiles/${id}/request_change`
      }
      throw new ApiPathError('request_change', {id})
    }),
    xlsxGenerate: endpoint => endpoint.method('post').path(() => {
      return '/tiles/xlsx_generate'
    }),
    xlsxStatus: endpoint => endpoint.path(({fileDownloadId}) => {
      if (fileDownloadId) {
        return `/tiles/xlsx_status/${fileDownloadId}`
      }
      throw new ApiPathError('xlsx_status', {fileDownloadId})
    }),
    index: endpoint => endpoint.path(() => {
      return '/tiles'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tiles'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Orderings = ProtectedJsonAPI.extend(
  '',
  {
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/orderings/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/orderings/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Categories = ProtectedJsonAPI.extend(
  '',
  {
    updateOrder: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}/update_order`
      }
      throw new ApiPathError('update_order', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/categories'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/categories'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const OrderTypes = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/order_types'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/order_types'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
    syncAutoSort: endpoint => endpoint.path(() => {
      return '/sync_auto_sort'
    }),
    updateCategoryOrder: endpoint => endpoint.method('put').path(({id, categoryId}) => {
      if (id && categoryId) {
        return `/order_types/${id}/update_category_order/${categoryId}`
      }
      throw new ApiPathError('update_category_order', {id, categoryId})
    }),
  }
)

export const RequestedChanges = ProtectedJsonAPI.extend(
  '',
  {
    approve: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/requested_changes/${id}/approve`
      }
      throw new ApiPathError('approve', {id})
    }),
    reject: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/requested_changes/${id}/reject`
      }
      throw new ApiPathError('reject', {id})
    }),
    bulkReview: endpoint => endpoint.method('post').path(() => {
      return '/requested_changes/bulk_review'
    }),
    index: endpoint => endpoint.path(() => {
      return '/requested_changes'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/requested_changes/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/requested_changes/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)
