import React, { useState, useEffect } from 'react'
import { AutocompleteSearch, DraggableList, ErrorBanner, FileDropZone, FormContext, LabeledSelect } from 'components'
import { provide, consume, MediaItemsContext, CategoryFeaturedTilesContext, SnackbarContext, TilesContext, CategoriesContext } from 'contexts'
import { Button, Card, CardContent, IconButton, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { compose, errorStringsFromError } from 'utils'

const FeaturedTiles = ({ mediaItems, snackbar, categories, categoryFeaturedTiles, categoryId }) => {
  const [error, setError] = useState()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [featuredTiles, setFeaturedTiles] = useState(null)
  const [searchIn, setSearchIn] = useState('This Category')
  const handleListChange = (value) => {
    if (value.length > (featuredTiles?.length || 0)) {
      setCurrentIndex(value.length - 1)
    } else {
      setCurrentIndex(value.findIndex(ft => ft === featuredTiles[currentIndex]))
    }
    setFeaturedTiles(value)
  }
  const handleTileChange = (value) => {
    const newList = [...featuredTiles]
    newList[currentIndex] = value
    setFeaturedTiles(newList)
  }
  useEffect(() => {
    const fetchCategoryFeaturedTiles = async () => {
      const featuredTiles = await categoryFeaturedTiles.actions.indexAll({
        filter: { categoryId },
        include: 'tile,tileImage,logoImage',
        sort: 'position',
      })
      setFeaturedTiles(featuredTiles)
    }
    fetchCategoryFeaturedTiles()
  }, [categoryId])

  const handleSave = async () => {
    try {
      const category = await categories.actions.updateWithJson(
        { id: categoryId, categoryFeaturedTiles: featuredTiles },
        { include: 'categoryFeaturedTiles,categoryFeaturedTiles.tile,categoryFeaturedTiles.tileImage,categoryFeaturedTiles.logoImage' }
      )
      setError(null)
      setFeaturedTiles(category.categoryFeaturedTiles.sort((a, b) => b.position > a.position ? -1 : 1))
      snackbar.actions.show("Successfully Saved Featured Tiles")
    } catch (ex) {
      setError(ex)
    }
  }

  return (
    <Card style={{ marginTop: 16, marginBottom: 16 }}>
      <CardContent>
        <Typography variant='h5'>Featured Tiles</Typography>
        {error && <ErrorBanner>
          {errorStringsFromError(error)}
        </ErrorBanner>}
        {featuredTiles !== null && <DraggableList value={featuredTiles} onChange={handleListChange} keyed={true} direction={'horizontal'}
          style={{ display: 'flex', marginTop: 16, overflowX: 'scroll' }}
          addStyle={{ flex: '0 0 198px', minHeight: 132, border: 'solid 1px silver' }}
          keyExtractor={item => (item.id || `newItem${item.key}`)}
          render={(featuredTile, i, draggableProps) => {
            const tileImageSrc = featuredTile?.tileImage?.url
            const logoImageSrc = featuredTile?.logoImage?.url
            return <div key={i} {...draggableProps} onClick={() => setCurrentIndex(i)}>
              <div style={{ position: 'relative', borderRadius: 8, overflow: 'hidden', width: 198, height: 132, background: 'silver', marginRight: 8, border: (i === currentIndex) ? 'solid 2px #786dff' : undefined }}>
                <img src={tileImageSrc} style={{ maxWidth: '100%' }} />
                {logoImageSrc && <div style={styles.logoImageContainer}>
                  <img src={logoImageSrc} style={styles.logoImage} />
                </div>}
                {featuredTile?.tile &&
                  <FavouriteIcon style={{ position: 'absolute', top: 4, right: 4, width: '8%', height: 'auto' }} />
                }
              </div>
            </div>
          }} />}

        {currentIndex > -1 && <Card style={{ marginTop: 16, position: 'relative' }}>
          <CardContent>
            <FormContext context={featuredTiles[currentIndex]} onChange={handleTileChange}>
              <ImagePicker member="tileImage" width={312} height={208} mediaItems={mediaItems} snackbar={snackbar} definition={tileImageRequirements} />
              <ImagePicker member="logoImage" width={312} height={88} mediaItems={mediaItems} snackbar={snackbar} definition={logoImageRequirements} />
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '1 1 80%' }}>
                  <AutocompleteSearch fullWidth name='tile' filterName='nameAndMerchant' member="tile"
                    fields={{ tiles: 'id,name,internalName' }} getOptionLabel={o => o ? `${o.name} (${o.internalName})` : ''}
                    filter={searchIn === 'This Category' ? { categoryId } : null} />
                </div>
                <div style={{ flex: '1 1 20%' }}>
                  <LabeledSelect label="Search In" options={['This Category', 'Entire Directory']} fullWidth value={searchIn} onChange={({ target: { value } }) => setSearchIn(value)} />
                </div>
              </div>
              <TextField member="link" fullWidth />
            </FormContext>
            <IconButton onClick={() => setCurrentIndex(-1)} style={{ position: 'absolute', top: 0, right: 0 }}><CloseIcon /></IconButton>
          </CardContent>
        </Card>}

        {featuredTiles !== null &&
          <Button variant="contained" color="primary" fullWidth style={{ marginTop: 16 }} onClick={handleSave}>Save Featured Tiles</Button>
        }
      </CardContent>
    </Card>
  )
}

const tileImageRequirements = { enforceMinimumSize: true, enforceRatio: true, enforcePixelSize: false, width: 312, height: 208 }
const logoImageRequirements = { enforceMinimumSize: true, enforceRatio: false, enforcePixelSize: false, width: 100, height: 100 }

const ImagePicker = ({ label, value, onChange, mediaItems, snackbar, width, height, definition }) => {
  const handleChange = async ({ target: { value } }) => {
    // Upload a MediaItem, then set the object
    if (value === null) {
      onChange({ target: { value } })
    } else {
      try {
        const mediaItem = await mediaItems.actions.create({ file: value, definition })
        onChange({ target: { value: mediaItem } })
      } catch (error) {
        onChange({ target: { value: null } })
        snackbar.actions.show(`${label}: ${(error?.meta?.file?.[0] || "is invalid or failed to upload")}`, 30000)
        console.warn(error)
      }
    }
  }

  return <FileDropZone label={label} type={'image'} value={value} width={width} height={height}
    onChange={handleChange} clearable noDropLabel immediateUpload />
}

const FavouriteIcon = (props) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM12 0.801364C5.81516 0.801364 0.801364 5.81516 0.801364 12C0.801364 18.1848 5.81516 23.1986 12 23.1986C18.1848 23.1986 23.1986 18.1848 23.1986 12C23.1986 5.81516 18.1848 0.801364 12 0.801364Z" fill="white" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2145 8.51297L13.214 10.9126L15.6145 10.913C16.2773 10.913 16.8145 11.4502 16.8145 12.113C16.8145 12.7757 16.2773 13.313 15.6145 13.313L13.2147 13.3122L13.2145 15.713C13.2145 16.3757 12.6773 16.913 12.0145 16.913C11.3518 16.913 10.8145 16.3757 10.8145 15.713L10.8144 13.3122L8.41453 13.313C7.75179 13.313 7.21453 12.7757 7.21453 12.113C7.21453 11.4502 7.75179 10.913 8.41453 10.913L10.8151 10.9126L10.8145 8.51297C10.8145 7.85023 11.3518 7.31297 12.0145 7.31297C12.6773 7.31297 13.2145 7.85023 13.2145 8.51297Z" fill="white" />
</svg>

const styles = {
  logoImageContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '62.96%',
    height: '48.32%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
  },
}

export default compose(
  consume(SnackbarContext),
  provide(MediaItemsContext, TilesContext, CategoryFeaturedTilesContext, CategoriesContext),
)(FeaturedTiles)