import React, { Component } from 'react'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { provide, UsersContext } from 'contexts'
import { Authorization } from 'utils'

export class Profile extends Component{

  static fetchDependencies({users}){
    return users.actions.show(Authorization.user.id)
  }

  get currentUser(){
    return this.props.users.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h6'>Profile</Typography>
        <List>
          <ListItem>
            <ListItemSecondaryAction>
              <IconButton onClick={() => this.props.history.push(`/users/${this.currentUser.id}/edit`)}>
                <EditIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemIcon><PersonIcon/></ListItemIcon>
            <ListItemText primary={this.currentUser.name} secondary='name'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><EmailIcon/></ListItemIcon>
            <ListItemText primary={this.currentUser.email} secondary='email'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><FaceIcon/></ListItemIcon>
            <ListItemText primary={this.currentUser.role} secondary='role'/>
          </ListItem>

        </List>
      </CardContent>
    </Card>
}


export default provide(UsersContext)(Dependent(Profile))