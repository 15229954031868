import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AuditIcon from '@material-ui/icons/Security'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MerchantIcon from '@material-ui/icons/Store'
import StoreIcon from '@material-ui/icons/LocalMall'
import PeopleIcon from '@material-ui/icons/People'
import ModuleIcon from '@material-ui/icons/ViewModule'
import VariantIcon from '@material-ui/icons/AddPhotoAlternate'
import CategoryIcon from '@material-ui/icons/Book'
import OrderIcon from '@material-ui/icons/Sort'
import TagIcon from '@material-ui/icons/Label'
import ExploreIcon from '@material-ui/icons/Explore'
import FileUploadIcon from '@material-ui/icons/CloudUpload'
import TileIcon from '@material-ui/icons/Apps'
import PersonIcon from '@material-ui/icons/Person'
import DocumentIcon from '@material-ui/icons/Description'
import SegmentIcon from '@material-ui/icons/PeopleOutline'
import RequestedChangesIcon from '@material-ui/icons/Edit'
import TileChangesIcon from '@material-ui/icons/Image'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import { LaybuyMono } from 'components'
import { consume, provide, TokensContext, VersionsContext } from 'contexts'

export class Nav extends Component {

  state = { drawerOpen: false }

  componentDidMount() {
    this.props.versions.actions.getTag()
  }

  handleRequestOpen = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  render = () =>
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <LaybuyMono />
            <Link to='/'>Shop Directory</Link>
          </Typography>
          <IconButton color="inherit" onClick={this.handleRequestOpen}>
            <PersonIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({ drawerOpen: true })}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={!!this.state.open}
        onClose={this.handleRequestClose}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={this.props.tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{ paper: this.props.classes.drawer }}
        open={this.state.drawerOpen}
        onClose={() => this.setState({ drawerOpen: false })}
        onClick={() => this.setState({ drawerOpen: false })}
      >
        {
          Authorization.contentAdmin &&
          <Link to='/categories'>
            <ListItem button>
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Categories" />
            </ListItem>
          </Link>
        }

        <Link to='/tiles'>
          <ListItem button>
            <ListItemIcon>
              <TileIcon />
            </ListItemIcon>
            <ListItemText primary="Tiles" />
          </ListItem>
        </Link>

        {
          Authorization.superAdmin &&
          <Link to='/file_uploads'>
            <ListItem button>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              <ListItemText primary="File Uploads" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/tags'>
            <ListItem button>
              <ListItemIcon>
                <TagIcon />
              </ListItemIcon>
              <ListItemText primary="Tags" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/order_types'>
            <ListItem button>
              <ListItemIcon>
                <OrderIcon />
              </ListItemIcon>
              <ListItemText primary="Order Options" />
            </ListItem>
          </Link>
        }

        <Link to='/merchants'>
          <ListItem button>
            <ListItemIcon>
              <MerchantIcon />
            </ListItemIcon>
            <ListItemText primary="Merchants" />
          </ListItem>
        </Link>

        {
          Authorization.contentAdmin &&
          <Link to='/explore_feeds'>
            <ListItem button>
              <ListItemIcon>
                <ExploreIcon />
              </ListItemIcon>
              <ListItemText primary="Explore Feeds" />
            </ListItem>
          </Link>
        }

        <Link to='/stores'>
          <ListItem button>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Stores" />
          </ListItem>
        </Link>

        <Link to='/requested_changes/tiles'>
          <ListItem button>
            <ListItemIcon>
              <TileChangesIcon />
            </ListItemIcon>
            <ListItemText primary="Requested Tile Changes" />
          </ListItem>
        </Link>

        {
          Authorization.superAdmin &&
          <Link to='/module_types'>
            <ListItem button>
              <ListItemIcon>
                <ModuleIcon />
              </ListItemIcon>
              <ListItemText primary="Module Types" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/variant_types'>
            <ListItem button>
              <ListItemIcon>
                <VariantIcon />
              </ListItemIcon>
              <ListItemText primary="Variant Types" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/segments'>
            <ListItem button>
              <ListItemIcon>
                <SegmentIcon />
              </ListItemIcon>
              <ListItemText primary="Segments" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/users'>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
        }

        {
          Authorization.superAdmin &&
          <Link to='/apidocs'>
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon />
              </ListItemIcon>
              <ListItemText primary="Api Docs" />
            </ListItem>
          </Link>
        }

        {Authorization.superAdmin &&
          <Link to='/audits'>
            <ListItem button>
              <ListItemIcon>
                <AuditIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        }

        <div className={this.props.classes.version}>
          API {this.props.versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
}

const styles = theme => ({
  title: {
    flex: 1,
    '& > div': {
      top: 3,
      position: 'relative',
      display: 'inline-block',
      marginTop: 5,
      marginRight: 15
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  },
  drawer: {
    background: theme.palette.primary.dark,
    paddingTop: 10,
    '& a, & svg': {
      color: 'white',
      fill: 'white !important'
    },
    '& a > div': {
      paddingLeft: 30,
      paddingRight: 70
    }
  }
})
export default compose(
  consume(TokensContext),
  provide(VersionsContext),
  withStyles(styles)
)(Nav)