import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, StoresContext } from 'contexts'
import TileList from './TileList'

export class Show extends Component {
  static fetchDependencies({ stores, match: { params: { id } } }) {
    stores.actions.show(id, { include: 'merchant,merchant.tiles,merchant.tiles.categories,tileStores,tileStores.tile,tileStores.tile.categories' })
  }

  get store() {
    return this.props.stores.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Store - {this.store.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: 30 }} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/stores/${this.store.id}/edit`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head' nowrap>Merchant</TableCell>
              <TableCell>{String(this.store?.merchant?.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.store.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Latitude</TableCell>
              <TableCell>{String(this.store.latitude || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Longitude</TableCell>
              <TableCell>{String(this.store.longitude || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Address</TableCell>
              <TableCell>{String(this.store.address || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Suburb</TableCell>
              <TableCell>{String(this.store.suburb || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>City</TableCell>
              <TableCell>{String(this.store.city || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Postcode</TableCell>
              <TableCell>{String(this.store.postcode || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>State</TableCell>
              <TableCell>{String(this.store.state || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Country Code</TableCell>
              <TableCell>{String(this.store.countryCode || '')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <div style={{ marginTop: 16 }}>
          <Typography variant='h6'>Tiles</Typography>
          <TileList value={this.store.tileStores} />
        </div>
      </CardContent>
    </Card>
}


export default provide(StoresContext)(Dependent(Show))