import 'react-app-polyfill/ie11';
import './polyfills'
import './index.css';
import './DevTools';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from './containers';

// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();

ReactDOM.render(<AppContainer/>, document.getElementById('root'));

