import React, { Component } from 'react'
import { MenuItem, Menu, ListItemText, IconButton, TextField } from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const menuBackground = { 0: 'ivory', 1: 'honeydew', 2: 'aliceblue' }

export class CategoryPicker extends Component {
  state = { topLevelId: "1" }

  handleMenuItemClick = (event, category) => {
    this.props.onClick && this.props.onClick(category)
    if (this.props.onChange) {
      this.props.onChange({ target: { value: (category || {}).id } })
      this.setState({ anchorEl: null })
    }
    event.preventDefault()
    event.stopPropagation()
  }

  renderMenuItem = ({ category, level, topLevelId }) =>
    <MenuItem onClick={(e) => this.handleMenuItemClick(e, category)} key={category.id} value={category.id} style={{ backgroundColor: menuBackground[level] }}>
      <ListItemText style={{ marginLeft: (20 * level) }} primary={category.name} />
    </MenuItem>

  componentDidMount = () => {
    this.setState({ list: this.unfold([], this.topLevelCategories) })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.categories !== prevProps.categories) {
      this.setState({ list: this.unfold([], this.topLevelCategories) })
    }
  }

  get topLevelCategories() {
    return (this.props.categories || []).filter(c => !c.parentId)
  }

  unfold = (list, categories, level = 0, topLevelId = null) => {
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        list.push({ level, category, topLevelId })
        this.unfold(list, category.allChildren, level + 1, (topLevelId || category.id))
      })
    }
    return list
  }

  get isSelector() {
    return this.props.type === "selector"
  }

  onTextFieldClick = ({ currentTarget: anchorEl }) => {
    this.setState({ anchorEl })
  }

  renderPicker = () =>
    <>
      <Tooltip title='Select Category'>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={({ currentTarget: anchorEl }) => this.setState({ anchorEl })}
          style={this.isSelector ? { padding: 0 } : {}}
        >
          {this.isSelector ? <ArrowDropDownIcon /> : <ListIcon />}
        </IconButton>
      </Tooltip>
      <Menu
        id="customized-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={() => this.setState({ anchorEl: null })}
      >
        {(this.state.list || []).map(this.renderMenuItem)}
      </Menu>
    </>

  render = () => {
    const { label, fullWidth } = this.props
    if (this.isSelector) {
      const selected = this.props.categories.find(c => c.id === `${this.props.value}`)
      const selectedValue = ((selected && selected.label) || "")
      return <TextField label={label || "Category"} value={selectedValue} fullWidth={fullWidth} InputProps={{ endAdornment: this.renderPicker() }} onClick={this.onTextFieldClick} />
    } else {
      return this.renderPicker()
    }
  }
}

export default CategoryPicker