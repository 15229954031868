import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Promotions = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({tileId}) => {
      if (tileId) {
        return `/tiles/${tileId}/promotions`
      }
      throw new ApiPathError('index', {tileId})
    }),
    create: endpoint => endpoint.useFormData(true).method('post').path(({tileId}) => {
      if (tileId) {
        return `/tiles/${tileId}/promotions`
      }
      throw new ApiPathError('create', {tileId})
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.useFormData(true).method('put').path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/promotions/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)
