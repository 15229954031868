import APIContext from 'contexts/base/APIContext'

export default class OrderTypesContext extends APIContext {
  static contextName = 'orderTypes'

  updateCategoryOrder = async (item, options = {}) => {
    try {
      await this.performRequest('updateCategoryOrder')({ ...item, options })
      return true
    } catch(error) {
      console.log(error)
      return false
    }
  }

  syncAutoSort = async(options={}) => {
    return await this.performRequest('syncAutoSort')({ options })
  }

  fileUpload = async (item, options = {}) => {
    try {
      const { data: result } = await this.performRequest('fileUpload')({ ...item, options })
      return result
    } catch(error) {
      console.log(error)
      const { title, message } = error
      return { status: "Error", errors: [title, message] }
    }
  }
}