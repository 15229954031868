import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import UndoIcon from '@material-ui/icons/Undo'

export class DestroyButton extends Component {
  static defaultProps = {
    onDestroy: () => {}
  }

  handleDestroy = () => {
    const destory = !this.props.value
    this.props.onChange({target: {value: destory}})
    this.props.onDestroy(destory)
  }

  render = () => {
    return (
      <IconButton onClick={this.handleDestroy} color="primary" aria-label="Remove">
        {
          !!this.props.value ? <Tooltip title='Restore'><UndoIcon/></Tooltip> : <Tooltip title='Delete'><DeleteIcon/></Tooltip>
        }
      </IconButton>
    )
  }
}

export default DestroyButton