import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, SearchCombo, AutoSuggest, withConfirm, UploadButton, FormContext, LabeledCheckbox } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import StoreIcon from '@material-ui/icons/LocalMall'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import { compose, debounce, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, StoresContext, MerchantsContext, FileUploadsContext, FileDownloadsContext } from 'contexts'
import withStyles from 'styles'

export class List extends Component {
  state = {
    page: 1,
    uploadParams: { linkTiles: true }
  }

  static fetchDependencies({ stores, page, filter, tileId }) {
    if (filter.merchant) {
      filter = { ...filter, merchant: undefined, merchantId: filter.merchant.id }
    }
    if (tileId) {
      filter = { ...filter, tileId }
    }
    return stores.actions.index({
      page: page,
      pageSize: 8,
      include: 'primaryTile',
      fields: { stores: 'name,address,city,postcode,primaryTile', tiles: 'id' },
      filter
    })
  }

  showStore = id => () => {
    this.props.history.push(`/stores/${id}`)
  }

  editStore = id => event => {
    this.props.history.push(`/stores/${id}/edit`)
    event.stopPropagation()
  }

  deleteStore = ({ id, name, address, city }) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Store Deletion', details: { name, address, city } })) {
      this.props.stores.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  handleExportFile = async () => {
    let { filter } = this.props
    if (filter.merchant) {
      filter = { ...filter, merchant: undefined, merchantId: filter.merchant.id }
    }

    this.setState({ exporting: true })
    let fileDownload = await this.props.stores.actions.csvGenerate({
      filter: filter,
      downloadType: 'Store',
    })

    while (true) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      fileDownload = await this.props.fileDownloads.actions.show(fileDownload.id)
      if (fileDownload.status !== 'pending') {
        this.setState({ exporting: false })
        break;
      }
    }

    if (fileDownload.status === 'complete' && fileDownload.fileUrl) {
      window.location.href = fileDownload.fileUrl
    } else {
      console.log(fileDownload)
      this.props.snackbar.actions.show("Store export failed")
    }
  }

  get stores() {
    return this.props.stores.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.stores.errors.index) {
      errors = errors.concat(this.props.stores.errors.index)
    }
    if (this.props.stores.errors.destroy) {
      errors = errors.concat(this.props.stores.errors.destroy)
    }
    return errors
  }

  fetchMerchants = debounce(async (text, callback) => {
    const merchants = await this.props.merchants.actions.index({ pageSize: 5, filter: { name: text } })
    callback(merchants.map(m => ({ id: m.id, name: m.name })))
  }, 100)

  renderStoreListItem = (store) => {
    const { id, name, address, city, postcode } = store
    return (
      <ListItem button onClick={this.showStore(id)} key={id}>
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={<span>{address}<br />{city} {postcode}</span>} />
        <ListItemSecondaryAction>
          { this.props.tileId && `${store?.primaryTile?.id}` === `${this.props.tileId}` && <span className={this.props.classes.badge}>Primary</span> }
          <IconButton onClick={this.editStore(id)}><EditIcon /></IconButton>
          {Authorization.superAdmin && !this.props.embedded && <IconButton onClick={this.deleteStore(store)}><DeleteIcon /></IconButton>}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  handleFilterChange = (filter) => {
    filter = Object.keys(filter).filter(key => !!filter[key]).reduce((obj, key) => ({ ...obj, [key]: filter[key] }), {})
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  validateAreaFilter = (filter) => {
    if (filter && filter.area) {
      const { area } = filter
      return [area.latitude, area.longitude, area.latitudeDelta, area.longitudeDelta].every((value) => {
        return /^[-+]?[0-9]*\.?[0-9]+$/.test(value || "")
      })
    }
    return true
  }

  handleFileUpload = async (file) => {
    this.setState({ uploading: true })
    const result = await this.props.fileUploads.actions.create({
      uploadType: 'Store',
      ...this.state.uploadParams,
      file: file
    })
    const message = result.status === "Started" ? "Upload started, you will be emailed on completion" : "Error Uploading"
    this.props.snackbar.actions.show(message)
  }

  renderUploadConfirmationBody = () =>
    <FormContext onChange={uploadParams => this.setState({ uploadParams })} context={this.state.uploadParams}>
      <div><LabeledCheckbox member="linkTiles" label="Link Stores to Tiles" /></div>
    </FormContext>

  render = () =>
    <Card>
      <CardContent>
        {this.props.embedded ? this.renderList() : this.renderPage()}
      </CardContent>
    </Card>

  renderList = (searchCombo = undefined) => <>
    <Pagination totalPages={this.props.stores.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
    <MuiList dense>
      {this.stores.map(this.renderStoreListItem)}
    </MuiList>
    <Pagination totalPages={this.props.stores.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
  </>

  renderPage = () => {
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter} searchTextMember="searchText" validate={this.validateAreaFilter}>
        <AutoSuggest fullWidth member="merchant" labelProvider={item => item.name} onSuggestionsFetchRequested={this.fetchMerchants} />
        <TextField member="name" fullWidth />
        <TextField member="countryCode" fullWidth />
        <TextField member="tileId" fullWidth />
        <LabeledCheckbox member="noPrimaryTile" />
        <Card style={{ marginTop: 16 }}>
          <CardContent>
            <TextField member="area.latitude" />
            <TextField member="area.latitudeDelta" />
            <br />
            <TextField member="area.longitude" />
            <TextField member="area.longitudeDelta" />
          </CardContent>
        </Card>
      </SearchCombo>
    )
    return <>
      <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>
        Stores
        <div style={{ flex: 1 }}></div>
        <UploadButton renderConfirmationBody={this.renderUploadConfirmationBody} onUpload={this.handleFileUpload} />
        {!Authorization.contentAdmin ? undefined : this.state.exporting ?
          <CircularProgress /> :
          <Tooltip title='Export file'>
            <IconButton onClick={this.handleExportFile}><DownloadIcon /></IconButton>
          </Tooltip>
        }
      </Typography>
      {this.renderErrorMessages()}
      {this.renderList(searchCombo)}
      <FABFixed color='secondary' onClick={() => this.props.history.push('/stores/new')}>
        <AddIcon />
      </FABFixed>
    </>
  }
}

const styles = {
  badge: {
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#999',
    color: '#FFF',
    padding: 8,
    minWidth: '8em',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }
}

export default compose(
  Dependent,
  withConfirm,
  withStyles(styles),
  connectQueryString('stores'),
  provide(StoresContext),
  provide(MerchantsContext),
  provide(FileUploadsContext),
  provide(FileDownloadsContext),
  consume(SnackbarContext),
)(List)