import React, { Component, useRef } from 'react'
import { Pagination, ErrorBanner, withConfirm, FABFixed } from 'components'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import SaveIcon from '@material-ui/icons/Save'
import { compose, humanize, userFriendlyDate, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, RequestedChangesContext } from 'contexts'
import withStyles from 'styles'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { LegacyTilePreview, PortraitTilePreview } from 'containers/merchant_portal/tiles'
import { CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'

export class List extends Component {

  constructor(props) {
    super(props);
    this.commentRef = React.createRef()
    this.commentRef.current = {}
  }

  state = {
    page: 1,
    selections: {},
    rowErrors: {}
  }

  static fetchDependencies({ requestedChanges, page }) {
    return requestedChanges.actions.index({
      page: page,
      pageSize: 50,
      include: 'merchant,changeable',
      filter: { changeableType: 'Tile', status: 'pending' },
    })
  }

  get requestedChanges() {
    return this.props.requestedChanges.list
  }

  handlePageSelected = async page => {
    if (Object.keys(this.state.selections || {}).length === 0 || await this.props.confirm({ title: 'This will clear your selections, are you sure?' })) {
      this.setState({ selections: {}, rowErrors: {} })
      this.props.onPageChange(page, this.props.onDependencyUpdate)
    }
  }

  get errors() {
    let errors = []
    if (this.state.errors) {
      errors = errors.concat(this.state.errors)
    }
    if (this.props.requestedChanges.errors.index) {
      errors = errors.concat(this.props.requestedChanges.errors.index)
    }
    if (this.props.requestedChanges.errors.destroy) {
      errors = errors.concat(this.props.requestedChanges.errors.destroy)
    }
    return errors
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  showDiff = (activeChange) => () => {
    this.setState({ activeChange })
  }

  humanizeValue(value) {
    if (value && isNaN(value) && moment(value).isValid()) {
      return userFriendlyDate(value)
    }
    else if (typeof value == 'boolean') {
      return value ? 'TRUE' : 'FALSE'
    } else {
      return value
    }
  }

  handleBulkReview = async () => {
    const batchSize = parseInt(process.env.REACT_APP_APPROVAL_BATCH_SIZE || 10)
    this.setState({ saving: true, savingCount: Object.keys(this.state.selections).length, savedCount: 0 })
    try {
      const requestedChanges = Object.entries(this.state.selections).map(([key, { status }]) => ({ id: key, status, comment: (this?.commentRef?.current?.[key]?.value || '') }))
      const missingReasons = requestedChanges.filter(rc => rc.status === 'rejected' && (rc.comment || '').trim().length === 0)
      if (missingReasons.length > 0) {
        const rowErrors = missingReasons.reduce((rowErrors, rc) => ({ ...rowErrors, [rc.id]: { comment: 'Comment is required' } }), {})
        this.setState({ saving: false, rowErrors })
        this.props.snackbar.actions.show("You must provide a comment on rejected changes", 10000)
        return
      }
      const batchCount = Math.ceil(requestedChanges.length / batchSize)

      const totalResult = { approved: 0, rejected: 0, notFound: 0, error: 0 }
      const errors = []
      let anySuccess = false
      for (let batchNo = 0; batchNo < batchCount; batchNo++) {
        const batch = requestedChanges.slice(batchNo * batchSize, batchNo * batchSize + batchSize)
        try {
          const result = await this.props.requestedChanges.actions.bulkReview({
            requestedChanges: batch
          })
          totalResult.approved += result.approved
          totalResult.rejected += result.rejected
          totalResult.notFound += result.notFound
          anySuccess = true
        } catch (error) {
          totalResult.error += batch.length
          errors.push(...errorStringsFromError(error))
        }
        this.setState({ savedCount: this.state.savedCount + batch.length })
      }

      const message = [
        totalResult.error > 0 ? `${totalResult.error} error` : undefined,
        totalResult.approved > 0 ? `${totalResult.approved} approved` : undefined,
        totalResult.rejected > 0 ? `${totalResult.rejected} rejected` : undefined,
        totalResult.notFound > 0 ? `${totalResult.notFound} not found (may have been edited or approved elsewhere)` : undefined
      ].filter(m => !!m).join(', ')
      if (anySuccess) {
        this.setState({ selections: {}, errors })
        const outcome = errors.length > 0 ? 'Some Errors Occurred' : 'Success'
        await this.props.snackbar.actions.show(`${outcome} - ${message}`, 30000)
        await this.props.onDependencyUpdate()
        if (this.props.requestedChanges.totalPages <= this.props.page) {
          this.props.onPageChange(this.props.requestedChanges.totalPages, this.props.onDependencyUpdate)
        }
      } else {
        this.props.snackbar.actions.show(errors.join(', '), 30000)
      }
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '), 30000)
    }
    this.setState({ saving: false, savingCount: undefined, savedCount: 0 })
  }

  renderMediaItem = (mediaItem) => {
    return <a href={`/media_items/${mediaItem?.id}`} target="_blank" alt="Show Media Item">
      <img src={mediaItem?.url} style={{ maxWidth: 300, maxHeight: 300, width: 'auto', height: 'auto', backgroundColor: 'silver' }} />
    </a>
  }

  renderSavingDialog = () => {
    return <Dialog open={!!this.state.saving}>
      <DialogTitle>Saving {this.state.savedCount} of {this.state.savingCount}...</DialogTitle>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  }

  renderDiff = () => {
    const classes = this.props.classes
    const activeChange = this.state.activeChange
    const diff = activeChange?.diff || []
    const media = activeChange?.media || []

    return <Dialog open={!!activeChange} onClose={this.showDiff(undefined)} maxWidth="xl">
      <DialogContent>
        <div className={classes.changes}>
          <Table breakpoint={400} className={classes.changes}>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Old Value</TableCell>
                <TableCell>New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diff.map(change =>
                <TableRow key={change.field}>
                  <TableCell>{humanize(change.field)}</TableCell>
                  <TableCell>{this.humanizeValue(change.from)}</TableCell>
                  <TableCell>{this.humanizeValue(change.to)}</TableCell>
                </TableRow>
              )}
              {media.map(change =>
                <TableRow key={change.field}>
                  <TableCell>{humanize(change.field)}</TableCell>
                  <TableCell>{this.renderMediaItem(change.from)}</TableCell>
                  <TableCell>{this.renderMediaItem(change.to)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={classes.dialogButton} color='default' variant='contained' onClick={this.showDiff(undefined)}>Close</Button>
      </DialogActions>
    </Dialog>
  }

  selectAllAs = (status) => () => {
    if (status === 'clear') {
      this.setState({ selections: {} })
    } else {
      this.setState({ selections: this.requestedChanges.reduce((selections, record) => ({ ...selections, [record.id]: { ...this.state.selections[record.id], status } }), {}) })
    }
  }

  handleSelectionsChange = (selections) => this.setState({ selections })

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' paragraph>Requested Tile Changes</Typography>
        {this.requestedChanges.length > 0 && <div>
          Select all as:
          <Button onClick={this.selectAllAs('approved')}>Approve</Button>
          <Button onClick={this.selectAllAs('rejected')}>Reject</Button>
          <Button onClick={this.selectAllAs('clear')}>Clear</Button>
        </div>}
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.requestedChanges.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <InnerList requestedChanges={this.props.requestedChanges.list} selections={this.state.selections} commentRef={this.commentRef}
          onSelectionsChange={this.handleSelectionsChange} showDiff={this.showDiff} loading={this.props.loading} rowErrors={this.state.rowErrors} />
        {this.renderDiff()}
        {this.renderSavingDialog()}
        <Pagination totalPages={this.props.requestedChanges.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        {!this.props.loading && Object.keys(this.state.selections).length > 0 &&
          <FABFixed variant="extended" color="secondary" onClick={this.handleBulkReview}>
            <SaveIcon style={{ marginRight: 8 }} />Save Selected
          </FABFixed>}
      </CardContent>
    </Card>
}

// Use a seperate component for the list itself so we can memoize it and save renders that cause the page to stutter
const InnerList = React.memo(({ selections, rowErrors, onSelectionsChange, requestedChanges, showDiff, loading, commentRef }) => {
  const classes = useStylesHook()

  const renderChangeDescription = (requestedChange) => {
    if (requestedChange) {
      const { id, details: { images }, changeable: { images: imagesBefore } } = requestedChange || {}
      return <div>
        <div>
          {requestedChange.description} <span style={{ color: 'gray' }}>({requestedChange.internalName})</span>
        </div>
        <div className={classes.previewWrapper}>
          <PortraitTilePreview
            tileImage={images?.['portraitTileImage'] || imagesBefore?.['portraitTileImage']}
            logoImage={images?.['logoImage'] || imagesBefore?.['logoImage']} />
          <LegacyTilePreview
            tileImage={images?.['legacyTileImage'] || imagesBefore?.['legacyTileImage']}
            logoImage={images?.['legacyLogoImage'] || imagesBefore?.['legacyLogoImage']} />
          <div>
            <RadioGroup value={selections[id]?.status || 'unknown'} onChange={({ target: { value } }) => onSelectionsChange({ ...selections, [id]: { ...selections[id], status: value } })}>
              <FormControlLabel value="approved" control={<Radio className={classes.approveRadio} />} label="Approve" />
              <FormControlLabel value="rejected" control={<Radio className={classes.rejectRadio} />} label="Reject" />
            </RadioGroup>
            <Button style={{ marginLeft: 18, display: 'block', textTransform: 'initial' }} onClick={() => {
              const updatedSelections = { ...selections }
              delete updatedSelections[`${id}`]
              onSelectionsChange(updatedSelections)
            }}>
              <Typography variant="body1">Clear</Typography>
            </Button>
            <Button onClick={showDiff(requestedChange)} style={{ marginTop: 64, display: 'block' }}>
              Show Changes
            </Button>
          </div>
          <div>
            {selections[id]?.status === 'rejected' &&
              <TextField label="Comment" multiline fullWidth variant="outlined" style={{ width: 260 }} minRows={9} inputProps={{ ref: cr => commentRef.current[id] = cr }} helperText={rowErrors[id]?.comment} error={!!rowErrors[id]?.comment} />}
          </div>
        </div>
      </div>
    }
  }

  const renderRequestedChangeListItem = (requestedChange) => (
    <ListItem key={requestedChange.id}>
      {renderChangeDescription(requestedChange)}
    </ListItem>
  )

  return loading ? <CircularProgress /> : requestedChanges.length > 0 ? <MuiList dense>
    {requestedChanges.map(renderRequestedChangeListItem)}
  </MuiList> : <div>
    <Typography variant="subtitle1">There are no requested changes pending</Typography>
  </div>
})

const styles = theme => ({
  changes: {
    overflow: 'auto',
  },
  dialogButton: {
    width: '100%',
    height: '45px'
  },
  previewWrapper: {
    display: 'flex',
    '&>div': {
      marginRight: 6,
    }
  },
  approveRadio: {
    '&.Mui-checked': {
      color: '#4eb5ab'
    },
  },
  rejectRadio: {
    '&.Mui-checked': {
      color: '#ff6e6e'
    },
  },
  ignoreRadio: {
    '&.Mui-checked': {
      color: 'silver'
    },
  },
})
const useStylesHook = makeStyles(styles)

export default compose(
  Dependent,
  withConfirm,
  withStyles(styles),
  connectQueryString('requestedChanges'),
  provide(RequestedChangesContext),
  consume(SnackbarContext),
)(List)