import React               from 'react'
import { ErrorBanner }     from 'components'
import { errorStringsFromError, Authorization, deepSet } from 'utils'

const InstanceFormMixin = (superclass, resourceName) => class extends superclass{

  static fetchDependencies({[resourceName]: resourceContext, match: { path, params: {id}}}){
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if(editMode){
      return resourceContext.actions.show(id)
    }
  }

  constructor(props){
    super(props)
    this.resourceName = resourceName
    this.handleFormObjectChange = this.handleFormObjectChange.bind(this)
    this.state = {
      formAttributes: {}
    }
  }

  get formObject(){
    return {...this.props[this.resourceName].selected, ...this.state.formAttributes}
  }

  dependenciesMet(){
    return (this.createMode || this.formObject.id)
  }

  get editMode(){
    return !this.createMode
  }

  get createMode(){
    return this.props.match.path.match(/\/new(?:\/:[^/]+)?$/)
  }

  get objectId(){
    return this.props.match.params.id
  }

  get attributesToSave(){
    const attributesToSave = {...this.formObject, relationships: {}}
    ;(this.relationshipAttributes || []).forEach(attribute => {
        const relationship = attributesToSave[attribute]
        delete attributesToSave[attribute]
        if(relationship !== undefined)
          attributesToSave.relationships[attribute] = {data: relationship}
      }
    )
    return attributesToSave
  }

  get saveEndpointOptions(){
    return {}
  }

  handleFormObjectChange(formAttributes, callback){
    this.setState({formAttributes}, typeof callback === 'function' ? callback : undefined)
  }

  reduceNameIdPairs = collection =>
    collection.reduce((agg, entity) => ({...agg, [entity.id]: entity.name}),{})

  errorFor = (name) => {
    try{
      return this.errorContext[name]
    }catch(err){
      return ''
    }
  }

  get error(){
    return this.errors[this.editMode ? 'update' : 'create']
  }

  get errors(){
    return this.props[this.resourceName].errors
  }

  get actions(){
    return this.props[this.resourceName].actions
  }

  get errorContext(){
    const {meta} = this.error || {}
    if(meta){
      return Object.entries(meta).reduce((acc, [name, value]) => {
        return deepSet(value.join(', '), name, acc)
      }, {})
    }
    return null
  }

  renderErrorMessages = () => this.error ?
    <ErrorBanner>
      {errorStringsFromError(this.error)}
    </ErrorBanner> :
    false

  hasError = errorName =>
    Object.entries((this.error || {}).meta || {}).find(([attribute, value]) => attribute === errorName)

  save = async (saveAction, options = {afterSave: this.afterSave, onSaveError: this.onSaveError, onSaveRedirect: this.onSaveRedirect}) => {
    const { afterSave, onSaveError, onSaveRedirect } = options

    try{
      if(!(saveAction && saveAction instanceof Promise)){
        saveAction = this.editMode ?
          this.actions.update(this.attributesToSave, this.saveEndpointOptions) :
          this.actions.create(this.attributesToSave, this.saveEndpointOptions)
      }
      const result = await saveAction

      if(typeof afterSave === 'function') {
        afterSave(result)
      }

      let redirectTo = null
      if(onSaveRedirect) {
        redirectTo = (typeof onSaveRedirect === 'function') ? onSaveRedirect(result) : redirectTo = onSaveRedirect
      }

      if(redirectTo) {
        this.props.history.push(redirectTo)
      } else {
        if(window.location.pathname !== Authorization.store.savedLocation){
          this.props.history.goBack()
        }else{
          this.props.history.push('/')
          this.actions.tokens.clearSavedWindowLocation()
        }
      }

      this.props.snackbar && this.props.snackbar.actions.show(`Saved ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)

      return result
    }catch(err){
      if(typeof onSaveError === 'function') {
        onSaveError(err)
      } else {
        this.props.snackbar && this.props.snackbar.actions.show(`Error saving: ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)
      }
      console.log(err)
      return err
    }
  }
}

export default InstanceFormMixin