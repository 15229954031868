import APIContext from 'contexts/base/APIContext'

export default class CategoriesContext extends APIContext {
  static contextName = 'categories'

  updateOrder = async(item, options={}) => {
    const { data: selected } = await this.performRequest('updateOrder')({...item, options})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  updateWithJson = async(item, options={}) => {
    const { data: selected } = await this.performRequest('updateWithJson')({...item, options})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  indexAllWithCountryCategoryHash = async (options) => {
    const categories = await this.indexAll(options)
    const countryForCategory = (category) => {
      return category?.parentId ? countryForCategory(category.parent) : category?.name
    }
    const categoryCountries = categories.reduce((hash, category) => (
      { ...hash, [category.id]: countryForCategory(category) }
    ), {})
    this.setState({categoryCountries})
    return categories
  }
}
