export function constrainDimensions(width, height, maxWidth, maxHeight){
  width = width || 160
  height = height || 120
  if (height > maxHeight || width > maxWidth) {
    const factor = Math.min(maxHeight / height, maxWidth / width)
    height = height * factor
    width = width * factor
  }
  return { width, height }
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

// From https://github.com/DominicTobias/react-image-crop
export const getCroppedImg = async (image, crop, fileName, type = 'image/png', maxWidth = 2160.0, targetWidth = undefined, targetHeight = undefined) => {
  const dimTolerance = parseFloat(process.env.REACT_APP_CROP_TARGET_DIMENSION_TOLERANCE || 5)

  if (typeof image === 'string') {
    image = await createImage(image)
  }

  // Enforce a maximum size
  const downscale = (maxWidth && crop.width > maxWidth) ? (crop.width / maxWidth) : 1
  let finalWidth = Math.round(crop.width / downscale)
  let finalHeight = Math.round(crop.height / downscale)

  // If we're within tolerances of our target width and height let's use that instead of the on calculated from the ratio
  if (targetWidth && targetHeight && Math.abs(finalWidth - targetWidth) <= dimTolerance && Math.abs(finalHeight - targetHeight) <= dimTolerance) {
    finalWidth = targetWidth
    finalHeight = targetHeight
  }

  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = finalWidth;
  canvas.height = finalHeight;
  const ctx = canvas.getContext('2d');

  // Chrome/Edge/Safari should give better quality with this enabled (Firefox doesn't support it)
  ctx.imageSmoothingEnabled = true
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    finalWidth,
    finalHeight,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/png');

  // As a blob/file
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      const file = new File([blob], fileName, { type: blob.type })
      resolve(file);
    }, type, 1);
  });
}

export const whitenImageFile = async (imageFile, fileName, type = 'image/png') => {
  if (type === "image/svg+xml") {
    let svgText = await imageFile.text();
    svgText = svgText.replaceAll(/(?<=[ ])fill[ ]*=[ ]*"(?!(none|url\([^\)]*\)|transparent)).*?"/gi, 'fill="white"')
    svgText = svgText.replaceAll(/(?<=[ ])stroke[ ]*=[ ]*"(?!(none|url\([^\)]*\)|transparent)).*?"/gi, 'stroke="white"')
    svgText = svgText.replaceAll(/(?<=[\n\t{ ;])fill:[ ]*(?!(none|url\([^\)]*\)|transparent)).*?[ ]*(?=[; }])/gi, 'fill:white')
    svgText = svgText.replaceAll(/(?<=[\n\t{ ;])stroke:[ ]*(?!(none|url\([^\)]*\)|transparent)).*?[ ]*(?=[; }])/gi, 'stroke:white')
    return new File([svgText], "Logo.svg", { type: "image/svg+xml" })
  }

  const imageUrl = URL.createObjectURL(imageFile)
  const image = await createImage(imageUrl)
  URL.revokeObjectURL(imageUrl)

  const canvas = document.createElement('canvas');
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0)

  const imageData = ctx.getImageData(0, 0, image.naturalWidth, image.naturalHeight)
  let white = true;
  const bytes = imageData.data
  for (let i = 0; i < bytes.length; i += 4) { // Each pixel is 5 bytes, RGBA
    if (bytes[i + 3] > 0) {
      if (white && (bytes[i] != 255 || bytes[i + 1] != 255 || bytes[i + 2] != 255)) {
        white = false;
      }
      bytes[i] = bytes[i + 1] = bytes[i + 2] = 255;
    }
  }
  ctx.putImageData(imageData, 0, 0);

  // Return the unaltered file if it is already white
  if (white) {
    return imageFile;
  }

  // As a blob/file
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      const file = new File([blob], fileName, { type: blob.type })
      resolve(file);
    }, type, 1);
  });
}