import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const OrderTypes = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/order_types'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/order_types'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/order_types/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
    syncAutoSort: endpoint => endpoint.path(() => {
      return '/sync_auto_sort'
    }),
    updateCategoryOrder: endpoint => endpoint.method('put').path(({id, categoryId}) => {
      if (id && categoryId) {
        return `/order_types/${id}/update_category_order/${categoryId}`
      }
      throw new ApiPathError('update_category_order', {id, categoryId})
    }),
  }
)
