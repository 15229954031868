import React, { Component } from 'react'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { provide, consume, CategoriesContext, SnackbarContext } from 'contexts'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compose } from 'utils'

export class Order extends Component {

  static fetchDependencies({ categories, match: { params: { id } } }) {
    return categories.actions.show(id, {
      include: 'allChildren,allChildren.allChildren'
    })
  }

  get category() {
    return this.props.categories.selected
  }

  getItemStyle = (isDragging, draggableStyle, direction, featured) => ({
    userSelect: 'none',
    margin: direction === "vertical" ? '5px 0px 5px 0px' : `0px 5px 0px 5px`,
    color: 'gray',
    fontWeight: 'bold',
    padding: '10px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    background: featured ? '#c1bdff' : direction === "vertical" ? "white" : "lightgrey",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver, direction) => ({
    display: 'flex',
    flexDirection: direction === "vertical" ? "column" : "row",
    padding: '5px',
    overflow: 'auto',
  });

  get children() {
    return this?.category?.allChildren || []
  }

  componentDidUpdate = (prevProps, prevState) => {
    if ((!this.state.items || this.state.items.length === 0) && this.children.length > 0) {
      this.setState({ items: this.orderTree(this.children) })
    }
  }

  orderTree = (children) => {
    return (children || []).map(c => ({ ...c, children: this.orderTree(c.allChildren) })).sort((a, b) => (a.position < b.position ? -1 : 1))
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  state = { items: [] }

  onDragEnd = (parentId) => (result) => {
    // dropped outside the list
    if (!result.destination) { return }

    let items = this.state.items
    const srcIdx = result.source.index
    const dstIdx = result.destination.index
    if (parentId) {
      const parent = items.find(item => item.id === parentId)
      const updatedParent = { ...parent, children: this.reorder(parent.children, srcIdx, dstIdx) }
      items = Array.from(items)
      items[items.indexOf(parent)] = updatedParent
    } else {
      items = this.reorder(items, srcIdx, dstIdx)
    }
    this.setState({ items });
  }

  renderDnD = (children, direction, parentId) =>
    <DragDropContext onDragEnd={this.onDragEnd(parentId)}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver, direction)} {...provided.droppableProps}>
            {children.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                    style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style, direction, item.featured)}>
                    <div style={{ textAlign: 'center' }}>{item.name}</div>
                    <div>
                      {item.children && this.renderDnD(item.children, "vertical", item.id)}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>

  save = async () => {
    const updatedCategory = { id: this.category.id, childrenForOrder: this.state.items }
    let success = false
    try {
      success = !!await this.props.categories.actions.updateOrder(updatedCategory)
    } catch (error) { console.log(error) }
    const message = success ? "Saved Successfully" : "Error Saving"
    this.props.snackbar.actions.show(message)
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Category Ordering - {this.category.name}</Typography>
        <br />
        {this.renderDnD(this.state.items, "horizontal")}
        <Button color='secondary' fullWidth variant='contained' onClick={this.save} style={{ marginTop: 16 }}>Save</Button>
      </CardContent>
    </Card>
}

export default compose(
  Dependent,
  provide(CategoriesContext),
  consume(SnackbarContext),
)(Order)