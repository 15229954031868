import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const ExploreFeeds = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/explore_feeds'
    }),
    create: endpoint => endpoint.method('post').path(({options}) => {
      return `/explore_feeds?publish=${!!options?.publish}`
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/explore_feeds/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id, options}) => {
      if (id) {
        return `/explore_feeds/${id}?publish=${!!options?.publish}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/explore_feeds/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)