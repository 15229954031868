import NavAuthenticated              from './authenticated/Nav'
import NavAuthenticatedMerchant      from './authenticated_merchant/Nav'
import NavUnauthenticated            from './unauthenticated/Nav'
import NavUnknown                    from './unknown/Nav'

import RoutesAuthenticated           from './authenticated/Routes'
import RoutesAuthenticatedMerchant   from './authenticated_merchant/Routes'
import RoutesUnauthenticated         from './unauthenticated/Routes'
import RoutesUnknown                 from './unknown/Routes'

export { default as App } from './App'

export const Routes = { Authenticated: RoutesAuthenticated, AuthenticatedMerchant: RoutesAuthenticatedMerchant, Unknown: RoutesUnknown, Unauthenticated: RoutesUnauthenticated}
export const Nav    = { Authenticated: NavAuthenticated,    AuthenticatedMerchant: NavAuthenticatedMerchant,    Unknown: NavUnknown,    Unauthenticated: NavUnauthenticated}