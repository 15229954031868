import React, { Component } from 'react'
import { FormContext, DateTimePicker, ConfirmationDialog, LabeledSelect } from 'components'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { userFriendlyDate } from 'utils'
import MuiList from '@material-ui/core/List'
import { ListItem, ListItemText, ListItemSecondaryAction, Fab, InputLabel, ListItemIcon } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ScheduleIcon from '@material-ui/icons/Schedule'
import IconButton from '@material-ui/core/IconButton'

export class ScheduledDefaultCategoryList extends Component {
  state = {}

  addItem = () => {
    let newItem = { internalName: '', activeStartsAt: '', activeEndsAt: '' }
    this.setState({ editItem: newItem, editItemIndex: -1 })
  }

  handleChange = (newValue) => {
    this.props.onChange({ target: { value: newValue } })
  }

  handleEditConfirm = () => {
    const existing = (this.props.value || [])
    const editIndex = this.state.editItemIndex
    if (editIndex === -1) {
      this.handleChange([...existing, this.state.editItem])
    } else {
      this.handleChange(Object.assign(existing.slice(), { [editIndex]: this.state.editItem }))
    }
    this.setState({ editItem: undefined, editItemIndex: undefined })
  }

  handleDeleteItem = i => () => {
    const existing = (this.props.value || [])
    this.handleChange(Object.assign(existing.slice(), { [i]: { ...existing[i], _destroy: true } }))
  }

  handleEditCancel = () => {
    this.setState({ editItem: undefined, editItemIndex: undefined })
  }

  renderItemEdit = () =>
    <ConfirmationDialog
      title={"Edit Scheduled Default Category"}
      open={!!this.state.editItem}
      onConfirm={this.handleEditConfirm}
      onCancel={this.handleEditCancel}
    >
      <FormContext onChange={state => this.setState({ editItem: { ...this.state.editItem, ...state } })} context={this.state.editItem}>
        <TextField fullWidth member='internalName' />
        <LabeledSelect fullWidth member='defaultCategoryId' label="Default Category" keyField="id" textField="label" options={this.props.categoryList} />
        <DateTimePicker fullWidth member='activeStartsAt' />
        <DateTimePicker fullWidth member='activeEndsAt' />
      </FormContext>
    </ConfirmationDialog>

  handleItemClick = i => () => {
    this.setState({ editItem: this.props.value[i], editItemIndex: i })
  }

  renderItemListItem = ({ internalName, defaultCategoryId, activeStartsAt, activeEndsAt, _destroy }, i) => {
    const defaultCategory = this.props.categoryList.find(c => `${c.id}` === `${defaultCategoryId}`)
    return (_destroy ? false : <ListItem button key={i} onClick={this.handleItemClick(i)}>
      <ListItemIcon><ScheduleIcon /></ListItemIcon>
      <ListItemText primary={<span><b>{defaultCategory && defaultCategory.label}</b> - {internalName}</span>} secondary={<span>{userFriendlyDate(activeStartsAt)}–{userFriendlyDate(activeEndsAt)}</span>} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.handleItemClick(i)}><EditIcon /></IconButton>
        <IconButton onClick={this.handleDeleteItem(i)}><DeleteIcon /></IconButton>
      </ListItemSecondaryAction>
    </ListItem>)
  }

  render = () =>
    <div style={{ marginBottom: 16 }}>
      {this.renderItemEdit()}
      <InputLabel style={{ margin: '8px 0px 8px 0px' }}>Scheduled Default Categories</InputLabel>
      <Card>
        <CardContent>
          <MuiList dense>
            {(this.props.value || []).map(this.renderItemListItem)}
          </MuiList>
          <Fab color='secondary' onClick={this.addItem}>
            <AddIcon />
          </Fab>
        </CardContent>
      </Card>
    </div>
}

export default ScheduledDefaultCategoryList