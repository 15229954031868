import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { TokensContext, SnackbarContext, consume } from 'contexts'
import { LaybuyLogo } from 'components'

export class SignIn extends Component{

  signIn = async () => {
    try{
      await this.props.tokens.actions.create(this.state)
      const location = (this.props.savedLocation && !this.props.savedLocation.match(/^\/?tokens\//)) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
      this.props.snackbar.actions.show('Signed in succesfully')
    }catch(err){
      this.props.snackbar.actions.show('Signed in failed')
    }
  }

   errorFor = key => {
    try{
      return this.props.tokens.errors.create.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext
        context={this.state} errorContext={{email: this.errorFor('email'), password: this.errorFor('password')}} onChange={state => this.setState(state)} onSubmit={this.signIn}>
        <CardContent>
          <LaybuyLogo/>
          {this.props.tokens.errors.create && <ErrorBanner>{this.props.tokens.errors.create.title}</ErrorBanner>}
          <FormGroup>
            <TextField member='email'   type='text'/>
            <TextField member='password'type='password'/>
              <FormControlLabel
                control={
                  <FormContext context={this.state} onChange={state => this.setState(state)}>
                  <Checkbox member="rememberMe" value="checkedA"/>
                  </FormContext>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' color='primary' type='submit'>Submit</Button>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </FormContext>
    </CenteredCard>
}

export default consume(TokensContext, SnackbarContext)(SignIn)