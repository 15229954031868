import React, { Component } from 'react'
import { Pagination, ErrorBanner, SearchCombo, ConfirmationDialog } from 'components'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TagIcon from '@material-ui/icons/Label'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, TagsContext } from 'contexts'
import withStyles from 'styles'
import { Typography } from '@material-ui/core'
import { get } from 'lodash'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ tags, page, filter }) {
    return tags.actions.index({
      page: page, pageSize: 12, filter
    })
  }

  showTag = id => () => {
    this.props.history.push(`/tags/${id}`)
  }

  deleteTag = tag => event => {
    this.setState({ tagToDelete: tag })
    event.stopPropagation()
  }

  handleDeleteConfirm = () => {
    this.props.tags.actions.destroy({ id: this.state.tagToDelete.id })
      .then(this.props.onDependencyUpdate)
      .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    this.setState({ tagToDelete: undefined })
  }

  get tags() {
    return this.props.tags.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.tags.errors.index) {
      errors = errors.concat(this.props.tags.errors.index)
    }
    if (this.props.tags.errors.destroy) {
      errors = errors.concat(this.props.tags.errors.destroy)
    }
    return errors
  }

  handleFilterChange = (filter) => {
    filter = Object.keys(filter).filter(key => !!filter[key]).reduce((obj, key) => ({ ...obj, [key]: filter[key] }), {})
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  renderTagListItem = (tag) =>
    <ListItem button onClick={this.showTag(tag.id)} key={tag.id}>
      <ListItemIcon>
        <TagIcon />
      </ListItemIcon>
      <ListItemText style={{ padding: 0, margin: 0 }}
        primary={tag.text}
        secondary={<span className={this.props.classes.note}>{tag.activeTileCount} active tiles</span>}
        primaryTypographyProps={{ style: { textTransform: 'uppercase' } }} />
      <ListItemSecondaryAction>
        {Authorization.superAdmin && <IconButton onClick={this.deleteTag(tag)}><DeleteIcon /></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter} searchTextMember="text" />

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>Tags</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.tags.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={this.renderSearchCombo()} />
        <MuiList dense>
          {this.tags.map(this.renderTagListItem)}
        </MuiList>
        <Pagination totalPages={this.props.tags.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <ConfirmationDialog title={`Confirm Deletion: ${(get(this.state, 'tagToDelete.text') || '').toUpperCase()}`}
          open={!!this.state.tagToDelete}
          onConfirm={this.handleDeleteConfirm}
          onCancel={() => this.setState({ tagToDelete: undefined })}
          confirmLabel="Confirm"
          cancelLabel="Cancel">
        </ConfirmationDialog>
      </CardContent>
    </Card>
}

const styles = theme => ({
  note: {
    background: "#786dff",
    color: "#FFF",
    padding: '0px 4px 0px 4px',
    marginRight: 4,
    borderRadius: 4,
    fontSize: "0.8em",
    fontWeight: 'bold',
    textTransform: 'uppercase',
  }
})

export default compose(
  Dependent,
  withStyles(styles),
  connectQueryString('tags'),
  provide(TagsContext),
  consume(SnackbarContext),
)(List)