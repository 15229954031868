import React, { Component } from 'react'
import { LabeledSelect } from 'components';
import { MenuItem, Checkbox, ListItemText, IconButton } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const menuBackground = { 0: 'ivory', 1: 'honeydew', 2: 'aliceblue' }
export class CategorySelector extends Component {
  state = { topLevelId: "1" }

  handleExpandClick = (id) => (e) => {
    e.stopPropagation()
    this.setState({ topLevelId: this.state.topLevelId === id ? null : id })
  }

  mapMenuItems = ({ category, level, topLevelId }) => {
    if (topLevelId && topLevelId !== this.state.topLevelId) return false
    return (
      <MenuItem key={category.id} value={category.id} style={{ backgroundColor: menuBackground[level] }}>
        <Checkbox checked={!!this.value.find(f => f === category.id)} style={{ marginLeft: (10 * level) }} />
        <ListItemText primary={category.name} />
        {level === 0 && (
          <IconButton onClick={this.handleExpandClick(category.id)}>
            {(category.id === this.state.topLevelId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </MenuItem>
    )
  }

  handleMultiSelectChange = ({ target: { value } }) => {
   this.handleValueChange(value)
  }

  handleValueChange = (value, old = this.value) => {
    const newValues = value.filter(v => !old.some(o => o === v))
    const parents = this.props.exactCategories ? [] : Array.from(new Set(newValues.flatMap(id => {
      const category = this.props.categories.find(c => c.id === id)
      return this.extractParentIds([], category)
    })))

    const removed = old.filter(v => !value.some(o => o === v))
    const ignore = this.props.exactCategories ? [] : [...removed.flatMap(id => {
      const category = this.props.categories.find(c => c.id === id)
      return this.extractChildIds([], category)
    }), ...parents]

    value = [...value.filter(id => !ignore.some(cid => cid === id)), ...parents]
    this.props.onChange({ target: { value: value.map(id => ({ id, type: "categories" })) } })
  }

  extractChildIds = (list, category) => {
    const children = (category.allChildren || [])
    if (children) {
      children.forEach(subCat => {
        list.push(subCat.id)
        this.extractChildIds(list, subCat)
      })
    }
    return list
  }

  extractParentIds = (list, category) => {
    if (category.parent) {
      list.push(category.parent.id)
      this.extractParentIds(list, category.parent)
    }
    return list
  }

  componentDidMount = () => {
    this.setState({ list: this.unfold([], this.topLevelCategories) })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.categories !== prevProps.categories) {
      this.setState({ list: this.unfold([], this.topLevelCategories) })
    } else if (!!prevProps.exactCategories && !this.props.exactCategories) {
      this.handleValueChange(this.value, [])
    }
  }

  get topLevelCategories() {
    return (this.props.categories || []).filter(c => !c.parentId)
  }

  unfold = (list, categories, level = 0, topLevelId = null) => {
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        list.push({ level, category, topLevelId })
        this.unfold(list, category.allChildren, level + 1, (topLevelId || category.id))
      })
    }
    return list
  }

  get value() {
    return (this.props.value || []).map(v => v.id)
  }

  render = () => {
    const { categories, exactCategories, ...props } = this.props
    return <LabeledSelect multiple fullWidth {...props}
      value={this.value}
      onChange={this.handleMultiSelectChange}
      renderValue={selected => categories.length > 0 ? selected.map(id => categories.find(f => f.id === id).name).join(', ') : ''}
    >
      {(this.state.list || []).map(this.mapMenuItems)}
    </LabeledSelect>
  }
}

export default CategorySelector