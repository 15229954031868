import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Tiles = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/tiles'
    }),
    create: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/tiles'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.useFormData(true).method('put').path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    requestChange: endpoint => endpoint.useFormData(true).method('put').path(({id}) => {
      if (id) {
        return `/tiles/${id}/request_change`
      }
      throw new ApiPathError('request_change', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/tiles/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
    xlsxGenerate: endpoint => endpoint.method('post').path(() => {
      return '/tiles/xlsx_generate'
    }),
    xlsxStatus: endpoint => endpoint.path(({fileDownloadId}) => {
      if (fileDownloadId) {
        return `/tiles/xlsx_status/${fileDownloadId}`
      }
      throw new ApiPathError('xlsx_status', {fileDownloadId})
    }),
  }
)
