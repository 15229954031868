import React, { useState } from 'react'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { provide, CategoriesContext } from 'contexts'
import { compose } from 'utils'

const TileList = ({ value: tileStores, categories, merchantTiles, onChange, classes, editable }) => {
  const [showOtherTiles, setShowOtherTiles] = useState()

  const handleAddTileClick = (tile) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    const primary = (!tileStores || tileStores.length === 0)
    const wasDestroyed = ((tileStores || []).some(ts => ts.tile.id === tile.id && ts._destroy))
    const value = wasDestroyed ?
      tileStores.map(ts => (ts.tile.id === tile.id ? { ...ts, primary, _destroy: undefined } : ts)) :
      [...(tileStores || []), { tile, primary }];
    onChange({ target: { value } })
  }

  const handleRemoveTileClick = (tile) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    const value = tileStores.map(ts => (ts.tile.id === tile.id ? { ...ts, _destroy: true } : ts)).filter(ts => (!ts._destroy || ts.id))
    onChange({ target: { value } })
  }

  const handleMakePrimary = (tile) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    const value = tileStores.map(ts => ({ ...ts, primary: ts.tile.id === tile.id }))
    onChange({ target: { value } })
  }

  const renderTileImages = ({ currentTileUrl, currentLogoUrl, name } = {}) =>
    <div className={classes.tileImages} draggable="false">
      <div draggable="false">
        <img src={currentTileUrl} alt={name} draggable="false" />
      </div>
      {
        (currentLogoUrl) ?
          <div draggable="false">
            <img src={currentLogoUrl} alt={name} draggable="false" />
          </div> :
          false
      }
    </div>

  const renderTileListItem = (linked) => (tileStore) => {
    const tile = tileStore.tile
    const { tag, promoTag, countryTag, thumb, indicator } = classes
    const { id, name, internalName, categories: tileCategories, instore, online, global, comingSoon, status, activePromotion } = tile
    const countries = (categories?.categoryCountries && tileCategories) ? Array.from(new Set(tileCategories.map(c => categories?.categoryCountries[c.id]))) : []
    const statusColor = status === "Active" ? "green" : status === "Inactive" ? "red" : "#888"
    return (
      <a target="_blank" href={`/tiles/${id}/edit`} key={id}>
        <ListItem button style={{ marginLeft: -16 }}>
          <ListItemIcon>
            <div className={thumb}>
              {renderTileImages(tile)}
            </div>
          </ListItemIcon>
          <div>
            <ListItemText primary={
              <span>
                <Tooltip title={status}>
                  <div className={indicator} style={{ background: statusColor }} />
                </Tooltip>
                {name}
              </span>
            } secondary={internalName} />
            {countries.map(country =>
              <span key={country} className={`${tag} ${countryTag}`}>{country}</span>
            )}
            {instore && <span className={tag}>INSTORE</span>}
            {online && <span className={tag}>ONLINE</span>}
            {global && <span className={tag}>GLOBAL</span>}
            {comingSoon && <span className={tag}>COMING SOON</span>}
            {activePromotion && <span className={`${tag} ${promoTag}`}>{activePromotion.caption}</span>}
          </div>
          <ListItemSecondaryAction>
            <div>
              {!linked && editable && <IconButton onClick={handleAddTileClick(tile)}>
                <AddIcon />
              </IconButton>}
              {linked && (editable || tileStore.primary) && <Button variant="contained" disabled={tileStore.primary} onClick={handleMakePrimary(tile)}>
                {tileStore.primary ? 'Primary' : 'Make Primary'}
              </Button>}
              {linked && editable && <IconButton onClick={handleRemoveTileClick(tile)}>
                <RemoveIcon />
              </IconButton>}
            </div>
          </ListItemSecondaryAction>
        </ListItem>
      </a>
    )
  }

  const currentTileIds = (tileStores || []).filter(ts => !ts._destroy).map(ts => ts.tile.id)
  const otherMerchantTiles = (merchantTiles || []).filter(t => !currentTileIds.some(tid => t.id == tid)).map((tile) => ({ primary: false, tile }))
  return (
    <Card>
      <CardContent>
        <MuiList dense>
          {(tileStores || []).filter(ts => !ts._destroy).map(renderTileListItem(true))}
        </MuiList>
        {!showOtherTiles && otherMerchantTiles.length > 0 &&
          <Button variant="outlined" onClick={() => setShowOtherTiles(true)}>Show Available Tiles</Button>
        }
        {showOtherTiles && <MuiList>
          {otherMerchantTiles.map(renderTileListItem(false))}
        </MuiList>}
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  tileImages: {
    position: "relative",
    marginTop: "5px",
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    top: "5px",
    left: "5px",
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        width: 'auto',
        height: 'auto'
      }
    }
  },
  tag: {
    background: "#CCC",
    padding: '0px 4px 0px 4px',
    marginRight: 4,
    borderRadius: 4,
    fontSize: "0.8em",
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  countryTag: {
    background: "#533187",
    color: "#FFF",
  },
  promoTag: {
    background: "#786dff",
    color: "#FFF"
  },
  thumb: {
    width: 100,
    height: 80,
    marginRight: 4
  },
  indicator: {
    width: 10,
    height: 10,
    display: 'inline-block',
    marginRight: 5
  },
})

TileList.fetchDependencies = ({ categories }) => {
  return categories.actions.indexAllWithCountryCategoryHash({
    include: 'parent,allChildren',
    fields: {
      categories: 'name,label,parentId,parent,allChildren',
      allChildren: 'name,parentId,parent,allChildren',
      parent: 'name,parentId,parent,allChildren',
    }
  })
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(CategoriesContext),
)(TileList)