import APIContext from 'contexts/base/APIContext'

export default class RequestedChangesContext extends APIContext {
  static contextName = 'requestedChanges'

  approve = async(id, options={}) => {
    const { data: requestedChange } = await this.performRequest('approve')({id, options})
    return requestedChange
  }

  reject = async(id, options={}) => {
    const { data: requestedChange } = await this.performRequest('reject')({id, options})
    return requestedChange
  }

  bulkReview = async(item, options={}) => {
    const { data } = await this.performRequest('bulkReview')({...item, options})
    return data
  }
}
