import BaseContext, { ContextsByType } from './BaseContext'
import * as API from 'api'
import { capitalize } from 'utils'
import pluralize from 'pluralize'
import { TokenStore } from 'services'
import { uuid } from 'utils'

const MAX_PAGE_SIZE = 10000

export default class APIContext extends BaseContext {

  static initialState = {
    ...this.initialState,
    requests: {},
    errors: {},
    selected:   {},
    list:       [],
    page:       1,
    totalPages: 1,
    count:      3
  }

  constructor(props){
    super(props)
    this.APIName = capitalize(pluralize(this.constructor.contextName))
    this.apiResource = API[this.APIName]
    if(!this.apiResource)
      throw new Error(`Required API Resource not found for ${this.constructor.contextName}. Looking for API.${this.APIName}`)
  }

  index = async({page = this.state.page, params={}, fields=null, include=null, filter=null, sort=null, pageSize=undefined, ...opts }={}) => {
    const {data: list, meta: { totalPages, count, ...meta }} = await this.performRequest('index')(
      {
        ...params,
        options: {
          page: { number: page, size: pageSize },
          ...sort && {sort},
          ...filter && {filter},
          ...fields && {fields},
          ...include && {include},
          ...opts
        }
      }
    )

    this.setState({list, page, totalPages, count, meta})
    return list
  }

  // Alias so we don't have to keep hard coding page sizes
  indexAll = async(opt={}) => {
    return await this.index({ ...opt, page: 0, pageSize: MAX_PAGE_SIZE })
  }

  create = async(item, {...options}={}) => {
    const { data:selected } = await this.performRequest('create')({...item, options})
    this.setState({selected, list: [...this.state.list, selected]})
    return selected
  }

  update = async(item, options={}) => {
    const { data: selected } = await this.performRequest('update')({...item, options})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  destroy = async(record) => {
    await this.performRequest('destroy')(record)
    this.setState({list: this.remove(this.state.list, record)})
  }

  show = async(id, {fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('show')({id, options: { ...fields && {fields}, ...include && {include} }})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  remove = (collection, item) => {
    return collection.filter(i => i.id !== item.id)
  }

  replace = (collection, item) => {
    return collection.map(i => i.id === item.id ? {...i, ...item} : i)
  }

  requestWithRetry = async(name, args) => {
    if(!this.apiResource[name]){
      throw new Error(`Endpoint "${name}" not defined on API.${this.APIName})}`)
    }
    try{
      return await this.apiResource[name](...args)
    }
    catch(err){
      if(err.status !== 401 || !TokenStore.auth){
        throw err
      }
      try{
        await ContextsByType.tokens[0].verify()
      }
      catch(err){
        ContextsByType.tokens[0].destroy()
        throw err
      }
      return await this.apiResource[name](...args)
    }
  }

  printMetaWarnings = (name, meta) => {
    try{
      if(meta.warnings.length){
        console.warn(`Warning on fetching ${this.contextName}:${name}`)
        meta.warnings.forEach(({message}) =>  console.warn(message))
      }
    }catch(err){}
  }

  performRequest = name => async (...args) => {
    const requestProxy = { id: uuid() }
    const updatedState = {
      requests: {...this.state.requests, [name]: [...(this.state.requests[name] || []), requestProxy]},
      errors: {...this.state.errors}
    }
    this.setState(updatedState)
    try {
      const {data, meta} = await this.requestWithRetry(name, args)
      updatedState.errors[name] = null
      this.printMetaWarnings(name, meta)
      return {data, meta}
    } catch (err) {
      updatedState.errors[name] = err
      throw err
    } finally {
      if(updatedState.requests[name])
        updatedState.requests[name] = updatedState.requests[name].filter(request => request !== requestProxy)
      if(!updatedState.requests[name].length)
        updatedState.requests[name] = null
      this.setState({errors: {...updatedState.errors}, requests: {...updatedState.requests}})
    }
  }
}
