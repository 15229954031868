import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, CategoriesContext } from 'contexts'

export class Show extends Component{


  static fetchDependencies({categories,  match: { params: { id }}}){
    return categories.actions.show(id, {
      include: ''
    })
  }

  get category(){
    return this.props.categories.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Category - {this.category.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/categories/${this.category.id}/edit`}>
                  <IconButton><EditIcon/></IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Name</TableCell>
                <TableCell>{String(this.category.name || '')}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default provide(CategoriesContext)(Dependent(Show))