import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import ApproveIcon from '@material-ui/icons/Done'
import RejectIcon from '@material-ui/icons/Close'
import StoreIcon from '@material-ui/icons/LocalMall'
import TileIcon from '@material-ui/icons/Apps'
import PromoIcon from '@material-ui/icons/MonetizationOn'
import { compose, humanize, userFriendlyDate, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, RequestedChangesContext } from 'contexts'
import withStyles from 'styles'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ requestedChanges, page }) {
    return requestedChanges.actions.index({
      page: page,
      include: 'merchant',
      filter: { changeableType: { not: 'Tile' } },
    })
  }

  get requestedChanges() {
    return this.props.requestedChanges.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.requestedChanges.errors.index) {
      errors = errors.concat(this.props.requestedChanges.errors.index)
    }
    if (this.props.requestedChanges.errors.destroy) {
      errors = errors.concat(this.props.requestedChanges.errors.destroy)
    }
    return errors
  }

  typeIcons = {
    Store: <StoreIcon />,
    Tile: <TileIcon />,
    Promotion: <PromoIcon />
  }

  renderChangeDescription = (requestedChange) => {
    if (requestedChange) {
      const { changeableId, changeableType, description, merchant } = requestedChange || {}
      return <div style={{ display: 'flex' }}>
        <ListItemIcon>
          <div className={this.props.classes.badge}>
            {this.typeIcons[changeableType]}
            <span style={{ paddingLeft: 8, paddingTop: 2 }}>{changeableType}</span>
          </div>
        </ListItemIcon>
        <div style={{ marginTop: 2 }}>
          <div>{changeableType}: {description}</div>
          <div className={this.props.classes.subtext}>Merchant: {merchant?.name}</div>
          <div className={this.props.classes.subtext}>Action: {changeableId ? 'Edit' : 'New'}</div>
        </div>
      </div>
    }
  }

  renderRequestedChangeListItem = (requestedChange) =>
    <ListItem button onClick={this.showDiff(requestedChange)} key={requestedChange.id}>
      {this.renderChangeDescription(requestedChange)}
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  showDiff = (activeChange) => () => {
    this.setState({ activeChange })
  }

  humanizeValue(value) {
    if (value && isNaN(value) && moment(value).isValid()) {
      return userFriendlyDate(value)
    }
    else if (typeof value == 'boolean') {
      return value ? 'TRUE' : 'FALSE'
    } else {
      return value
    }
  }

  handleApprove = (change) => change ? async (event) => {
    try {
      await this.props.requestedChanges.actions.approve(change.id)
      this.setState({ activeChange: undefined })
      await this.props.snackbar.actions.show("Change applied successfully")
      await this.props.onDependencyUpdate()
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  } : undefined

  handleReject = (change) => change ? async (event) => {
    try {
      await this.props.requestedChanges.actions.reject(change.id)
      this.setState({ activeChange: undefined })
      await this.props.snackbar.actions.show("Change rejected")
      await this.props.onDependencyUpdate()
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  } : undefined

  renderDiff = () => {
    const classes = this.props.classes
    const activeChange = this.state.activeChange
    const diff = activeChange?.diff || []

    return <Dialog open={!!activeChange} onClose={this.showDiff(undefined)}>
      <DialogContent>
        <div className={classes.changes}>
          <div style={{ marginBottom: '1em', marginTop: '1em' }}>
            {this.renderChangeDescription(activeChange)}
          </div>
          <div style={{ borderBottom: 'solid 1px lightgray' }}></div>
          <Table breakpoint={400} className={classes.changes}>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Old Value</TableCell>
                <TableCell>New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diff.map(change =>
                <TableRow key={change.field}>
                  <TableCell>{humanize(change.field)}</TableCell>
                  <TableCell>{this.humanizeValue(change.from)}</TableCell>
                  <TableCell>{this.humanizeValue(change.to)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={classes.dialogButton} color='default' variant='contained' onClick={this.showDiff(undefined)}>Cancel</Button>
        <Button className={classes.dialogButton} color='secondary' variant='contained' onClick={this.handleReject(activeChange)}>Reject</Button>
        <Button className={classes.dialogButton} color='primary' variant='contained' onClick={this.handleApprove(activeChange)}>Approve</Button>
      </DialogActions>
    </Dialog>
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' paragraph>Requested Changes</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.requestedChanges.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <MuiList dense>
          {this.requestedChanges.map(this.renderRequestedChangeListItem)}
        </MuiList>
        {this.renderDiff()}
        <Pagination totalPages={this.props.requestedChanges.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <FABFixed color='secondary' onClick={() => this.props.history.push('/requested_changes/new')}>
          <AddIcon />
        </FABFixed>
      </CardContent>
    </Card>
}

const styles = theme => ({
  badge: {
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#999',
    color: '#FFF',
    padding: 8,
    marginRight: 16,
    minWidth: '8em',
    minHeight: '5em',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  changes: {
    overflow: 'auto',
  },
  subtext: {
    color: 'gray',
  },
  dialogButton: {
    width: '100%',
    height: '45px'
  }
})

export default compose(
  Dependent,
  withStyles(styles),
  connectQueryString('requestedChanges'),
  provide(RequestedChangesContext),
  consume(SnackbarContext),
)(List)