import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, UsersContext } from 'contexts'

export class Show extends Component{

  static fetchDependencies({users, match: { path, params: {id}}}){
    return users.actions.show(id, {include: 'author'})
  }

  get id(){
    return this.props.match.params.id
  }

  get user(){
    return this.props.users.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>User - {this.user.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/users/${this.user.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
}


export default provide(UsersContext)(Dependent(Show))