import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, TagsContext, TilesContext } from 'contexts'
import { compose } from 'utils'
import { TableHead } from '@material-ui/core'
import { connectQueryString } from 'containers/shared'
import withStyles from 'styles'
import { Pagination } from 'components'

export class Show extends Component {
  static fetchDependencies({ tags, tiles, page, filter, match: { params: { id } } }) {
    return Promise.all([
      tiles.actions.index({
        page: page,
        filter: { tagId: id },
        fields: { tiles: 'name,internalName,tagWeight' },
        sort: '-tagWeight',
      }),
      tags.actions.show(id, { include: '' })
    ])
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  get tag() {
    return this.props.tags.selected
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Tagged Tiles - <span style={{ textTransform: 'uppercase' }}>{this.tag.text}</span></Typography>
        <div style={{ marginTop: 24 }}>Total Tiles: {this.props.tiles.count}</div>
        <Pagination totalPages={this.props.tiles.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}></Pagination>
        <Table className={this.props.classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Internal Name</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tag Weight</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(this.props.tiles.list || []).map(tile =>
              <TableRow>
                <TableCell>{tile.internalName}</TableCell>
                <TableCell>{tile.name}</TableCell>
                <TableCell>{(tile.tagWeight / 100).toFixed(2)}%</TableCell>
                <TableCell>
                  <Link to={`/tiles/${tile.id}/edit`}>
                    <IconButton><EditIcon /></IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination totalPages={this.props.tiles.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <br />
      </CardContent>
    </Card>
}

const styles = theme => ({
  table: {
    '& td': {
      padding: 0,
    },
    '& th': {
      padding: '9px 0px 9px 0px',
    },
  },
})

export default compose(
  Dependent,
  withStyles(styles),
  provide(TagsContext),
  provide(TilesContext),
  connectQueryString('tags'),
)(Show)