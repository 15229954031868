import { ConfirmationDialog } from "components";
import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "utils";

const ImageCropDialog = (props) => {
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [finalCrop, setFinalCrop] = useState({ x: 0, y: 0 });
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    setClosing(false)
  }, [props.open])

  useEffect(() => {
    setClosing(false)
    setImage(new Image())

    let src = undefined;
    let objectUrl = undefined
    if (props?.value instanceof File || props?.value instanceof Blob) {
      src = objectUrl = URL.createObjectURL(props?.value)
    } else if (props?.value?.url) {
      src = props?.value?.url
    }

    if (typeof src !== "string") {
      return;
    }

    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImage(img);
    };
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setFinalCrop({ x: 0, y: 0 })

    if (objectUrl) {
      return () => {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [props.value]);

  const handleConfirm = () => {
    setClosing(true)
    const finalizeCrop = async () => {
      if (image.naturalWidth === finalCrop.width && image.naturalHeight === finalCrop.height && finalCrop.x === 0 && finalCrop.y === 0 && (!props.maxWidth || finalCrop.width <= props.maxWidth)) {
        props.onChange({ target: { value: props.value } })
      } else {
        const cropped = await getCroppedImg(image, finalCrop, props.fileName || 'Image.png', 'image/png', props.maxWidth, props.targetWidth, props.targetHeight)
        props.onChange({ target: { value: cropped } })
      }
      props.onClose()
    }
    finalizeCrop()
  }

  return (
    <ConfirmationDialog open={props.open} maxWidth={'md'} onConfirm={handleConfirm} onCancel={() => props.onClose({ cancelled: true })} title="" canCancel={!closing} canConfirm={!closing && !!image?.src}>
      <div style={styles.wrapper}>
        <div style={styles.cropperDiv}>
          {closing && <div style={styles.message}>
            Processing...
          </div>}
          {!image?.src && <div style={styles.message}>
            Loading...
          </div>}
          {image && (
            <div>
              <Cropper
                image={image.src}
                aspect={props.aspect}
                crop={crop}
                zoom={zoom}
                restrictPosition={props.restrictPosition}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(_cropArea, cropAreaPixels) => {
                  if (props.onChange) {
                    setFinalCrop(cropAreaPixels);
                  }
                }}
              />
            </div>
          )}
        </div>
        <div>
          <input
            type="range"
            min={props.minZoom || 1}
            max={props.maxZoom || 3}
            step={0.05}
            value={zoom}
            onChange={(e) => setZoom(Number(e.target.value))}
          />
        </div>
      </div>
    </ConfirmationDialog>
  );
}

const styles = {
  wrapper: {
    position: 'relative',
  },
  cropperDiv: {
    position: 'relative',
    objectFit: 'cover',
    height: '50vh',
    background: 'lightgray',
  },
  message: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '32px',
    color: 'white',
    zIndex: 10000,
  }
}

export default ImageCropDialog;