import React, { useState, useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import FormHelperText from "@material-ui/core/FormHelperText"
import CardContent from "@material-ui/core/CardContent"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Close"
import { AutocompleteSearch, FileDropZone, LabeledSelect, DraggableList } from "components"
import { constrainDimensions, deepGet, deepSet, humanize, nextKey } from "utils"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { provide, consume, MediaItemsContext, TilesContext, SnackbarContext } from 'contexts'

const ModuleContentEditor = ({ module, moduleTypes, onChange, mediaItems, tiles, snackbar, errors, moduleIndex }) => {
  const contexts = { mediaItems, tiles, snackbar }
  const moduleContent = module.moduleContent
  const moduleType = moduleTypes.find(mt => mt.name === module.moduleType)

  const handleContentChange = (value) => onChange({ target: { value } })

  const defaults = {
    text: { value: '' },
    tile: { value: undefined },
    image: { value: undefined },
  }

  const addSectionItem = (sectionType) => () => {
    const path = sectionType.name
    const items = deepGet(path, moduleContent)
    const newItem = sectionType.attributes.reduce((p, c) => (
      { ...p, [c.name]: { ...defaults[c.type], ...(c.defaultValue !== undefined && { value: c.defaultValue }) } }
    ), { key: nextKey(items) })
    handleContentChange(deepSet([...items || [], newItem], path, moduleContent))
  }

  const removeSectionItem = (path, index) => () => {
    const items = deepGet(path, moduleContent)
    const newItems = [...items]
    newItems.splice(index, 1)
    handleContentChange(deepSet(newItems, path, moduleContent))
  }

  const handleChange = (path, value) => {
    handleContentChange(deepSet(value, path, moduleContent))
  }

  const getValue = (path, { getErrors } = {}) => {
    if (getErrors) {
      return (deepGet('meta', errors)?.[`content.${moduleIndex}.${path}`] || []).join('\n')
    } else {
      return deepGet(path, moduleContent)
    }
  }

  const onDragEnd = (path) => ({ source, destination }) => {
    if (source && destination) {
      const newList = [...deepGet(path, moduleContent)]
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      handleChange(path, newList)
    }
  }

  const [currentTile, setCurrentTile] = useState()
  if (moduleType && moduleType?.name === 'Tiles' && moduleType?.definition) {
    const renderTile = (side) => (tile, i, draggableProps) =>
      <div {...draggableProps} style={{ borderRadius: 8, marginBottom: 16, overflow: 'hidden', position: 'relative', userSelect: 'none', ...draggableProps?.style }} onClick={() => setCurrentTile({ side, i })}>
        {<img src={tile?.tileImage?.value || '/blank_tile.svg'} style={{ width: '100%', height: 'auto', display: 'block' }} />}
        {tile?.logoImage?.value && <div style={{ position: 'absolute', height: '26%', bottom: 0, left: 0, right: 0, zIndex: 1, opacity: 0.55, background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%)' }}></div>}
        {tile?.logoImage?.value && <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 100, padding: '10%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: '1 1 auto' }}></div>
          <img src={tile?.logoImage?.value} style={{ width: '100%', height: 'auto', display: 'block' }} />
        </div>}
      </div>
    const sectionType = moduleType.definition.find(s => s.name === 'main')
    const tilesType = currentTile ? moduleType.definition.find(s => s.name === currentTile.side) : undefined
    return <div style={{}}>
      <SectionEditor sectionType={sectionType} path={`${sectionType.name}`} onChange={handleChange} getValue={getValue} maxWidth={900} maxHeight={300} contexts={contexts} />
      <div style={{ background: '#2C2C2C', padding: 32, display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: '1 1 auto' }}>
          <div style={{ width: 375, paddingLeft: 16, paddingRight: 16, margin: '0px auto', background: 'white' }}>
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '40px' }}>{moduleContent?.main?.title?.value}</span>
            </div>
            <div style={{ display: 'flex', marginTop: 16 }}>
              <DraggableList.Context value={moduleContent} onChange={handleContentChange} canChange={{ left: true, right: true }} keyed={true}>
                <DraggableList value={(moduleContent?.left || [])} keyed={true} keyField="key" listId="left" render={renderTile('left')}
                  onChange={(value) => handleChange('left', value)} buildItem={undefined} style={{ flex: '0 1 50%' }} multi />
                <div style={{ flex: '0 0 16px' }}></div>
                <DraggableList value={(moduleContent?.right || [])} keyed={true} keyField="key" listId="right" render={renderTile('right')}
                  onChange={(value) => handleChange('right', value)} buildItem={undefined} style={{ flex: '0 1 50%' }} multi />
              </DraggableList.Context>
            </div>
          </div>
        </div>
        {currentTile && <div style={{ flex: '1 1 auto' }}>
          {currentTile && <SectionEditor sectionType={tilesType} path={`${currentTile?.side}.${currentTile?.i}`} onChange={handleChange} getValue={getValue}
            maxWidth={300} maxHeight={300} contexts={contexts} style={{ margin: 0 }} onRemove={() => setCurrentTile(null)}
          />}
        </div>}
      </div>
    </div>
  }

  return !moduleType?.definition ? false : <div>
    {moduleType.definition.map((sectionType, i) => {
      return <div key={i} style={{ marginLeft: 6, marginTop: 8, borderRadius: 5 }}>
        <Typography variant="subtitle1" style={{ marginLeft: 4, marginTop: 8 }}>
          {humanize(sectionType.name)}
        </Typography>
        {sectionType.repeatable ?
          <DragDropContext onDragEnd={onDragEnd(sectionType.name)}>
            <Droppable droppableId="droppable" direction={'horizontal'}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ display: 'flex', overflowX: 'scroll' }}>
                  {(moduleContent[sectionType.name] || []).map((item, i) =>
                    <Draggable key={item.key} draggableId={item.key} index={i}>
                      {(provided) => (
                        <SectionEditor sectionType={sectionType} path={`${sectionType.name}.${i}`} onChange={handleChange} getValue={getValue}
                          onRemove={removeSectionItem(sectionType.name, i)} maxWidth={300} maxHeight={300}
                          ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} contexts={contexts}
                          style={{ ...provided?.draggableProps?.style, flex: '1 1 100%' }}
                        />
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <IconButton onClick={addSectionItem(sectionType)}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext> :
          <SectionEditor sectionType={sectionType} path={`${sectionType.name}`} onChange={handleChange} getValue={getValue} maxWidth={900} maxHeight={300} contexts={contexts} />}
      </div>
    })}
  </div>
}

const SectionEditor = React.forwardRef(({ sectionType, path, onChange, getValue, onRemove, maxWidth, maxHeight, style, contexts, ...props }, ref) => {
  return (
    <Card style={{ position: 'relative', margin: 6, minWidth: 320, ...style }} ref={ref} {...props}>
      <CardContent style={{ maxWidth: 'initial' }}>
        {onRemove && <div style={{ height: 24 }}>
          <IconButton onClick={onRemove} style={{ position: 'absolute', top: 0, right: 0, zIndex: 100 }}>
            <RemoveIcon />
          </IconButton>
        </div>}
        {sectionType.attributes.map((attr, i) => <div key={i}>
          {attributeEditors[attr.type]({ attr, path: `${path}.${attr.name}`, onChange, getValue, maxWidth, maxHeight, contexts })}
        </div>)}
      </CardContent>
    </Card>
  )
})

const TextAttributeEditor = ({ attr, path, onChange, getValue }) => {
  const errorMessage = getValue(path, { getErrors: true })
  if (Array.isArray(attr.permittedValues) && attr.permittedValues.length > 0) {
    return <LabeledSelect label={humanize(attr.name)} value={getValue(`${path}.value`) || ''} fullWidth
      onChange={({ target: { value } }) => onChange(`${path}.value`, value)} options={attr.permittedValues} />
  } else {
    return <TextField label={humanize(attr.name)} value={getValue(`${path}.value`) || ''} fullWidth
      onChange={({ target: { value } }) => onChange(`${path}.value`, value)} multiline error={!!errorMessage} helperText={errorMessage} />
  }
}

const ImageAttributeEditor = ({ attr, path, onChange, getValue, maxWidth, maxHeight, contexts }) => {
  const { width, height } = constrainDimensions(attr?.dimensions?.width || 160, attr?.dimensions?.height || 120, maxWidth, maxHeight)
  const errorMessage = getValue(path, { getErrors: true })

  const handleChange = async ({ target: { value } }) => {
    // Upload a MediaItem, then set the object
    if (value === null) {
      onChange(`${path}`, { mediaItemId: null, value: null })
    } else {
      try {
        const { id, url } = await contexts.mediaItems.actions.create({ file: value, definition: attr?.dimensions })
        onChange(`${path}`, { mediaItemId: id, value: url })
      } catch (error) {
        onChange(`${path}`, { mediaItemId: null, value: null })
        contexts.snackbar.actions.show(`${humanize(attr.name)}: ${(error?.meta?.file?.[0] || "is invalid or failed to upload")}`, 30000)
        console.warn(error)
      }
    }
  }

  return <FileDropZone label={humanize(attr.name)} type={'image'} value={getValue(`${path}.value`)} width={width} height={height}
    onChange={handleChange} noDropLabel immediateUpload clearable error={!!errorMessage} helperText={errorMessage} />
}

const TileAttributeEditor = ({ attr, path, onChange, getValue, contexts }) => {
  const errorMessage = getValue(path, { getErrors: true })
  const tileId = getValue(`${path}.value`)
  const [tile, setTile] = useState(null)
  const handleChange = ({ target: { value } }) => {
    setTile(value)
    onChange(`${path}`, { value: value?.id })
  }
  useEffect(() => {
    if (!tileId) {
      setTile(null)
    } else if (tile?.id !== tileId) {
      (async () => {
        try {
          const tile = await contexts.tiles.actions.show(tileId)
          setTile(tile)
        } catch {
          setTile({ id: tileId, name: `${tileId}`, internalName: `${tileId}` })
        }
      })()
    }
  }, [tileId, tile, setTile])

  const displayTile = (tileId && tile?.id !== tileId) ? { id: tileId, name: 'Loading...', internalName: '...' } : tile
  return <>
    <AutocompleteSearch fullWidth name='tile' filterName='nameAndMerchant'
      value={displayTile} label={`${humanize(attr.name)}`} fields={{ tiles: 'id,name,internalName' }}
      onChange={handleChange} getOptionSelected={(o, v) => `${o.id}` === `${v}`} getOptionLabel={o => `${o?.name} (${o?.internalName})`}
      error={!!errorMessage} helperText={errorMessage} />
    <FormHelperText error={!!errorMessage}>{errorMessage}</FormHelperText>
  </>
}

const attributeEditors = {
  text: TextAttributeEditor,
  image: ImageAttributeEditor,
  tile: TileAttributeEditor,
}

export default consume(SnackbarContext)(provide(MediaItemsContext, TilesContext)(ModuleContentEditor))