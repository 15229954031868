import React, { Component } from 'react'
import { consume, SnackbarContext } from 'contexts'
import Button from '@material-ui/core/Button'
import MuiSnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export class Snackbar extends Component{

  state = {
    visible: false
  }

  render = () =>
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!this.props.snackbar.message}
      autoHideDuration={this.props.snackbar.duration || 3000}
      onClose={this.props.snackbar.actions.clear}
      message={<span id="message-id">{this.props.snackbar.message}</span>}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={this.props.snackbar.actions.clear}>
          CLOSE
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.props.snackbar.actions.clear}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
}

export default consume(SnackbarContext)(Snackbar)