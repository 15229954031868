import APIContext from 'contexts/base/APIContext'

export default class FileUploadsContext extends APIContext {
  static contextName = 'fileUploads'

  undo = async(id, options={}) => {
    const { data: result } = await this.performRequest('undo')({ id, options})
    return result
  }
}
