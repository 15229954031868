import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, DateTimePicker, FileDropZone, LabeledCheckbox } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, CategoriesContext, consume, SnackbarContext } from 'contexts'
import LabeledSelect from 'components/LabeledSelect'
import { InputAdornment } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import ScheduledBannerList from './ScheduledBannerList'
import ScheduledDefaultCategoryList from './ScheduledDefaultCategoryList'

export class Form extends InstanceFormMixin(Component, 'categories') {

  static fetchDependencies({ categories, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    const actions = []
    if (editMode) {
      actions.push(categories.actions.show(id, { include: 'scheduledBanners,scheduledDefaultCategories' }))
    }
    actions.push(categories.actions.indexAll({ fields: { categories: 'label,parentId,parent', parent: 'parentId' }, include: 'parent', sort: 'parentId,appOnly,position,name' }))
    return Promise.all(actions)
  }

  handleFormObjectChange(formAttributes, callback) {
    formAttributes = this.ensureSlug(formAttributes)
    this.setState({ formAttributes }, typeof callback === 'function' ? callback : undefined)
  }

  ensureSlug = (formAttributes) => {
    const { name } = this.state.formAttributes
    if (name !== formAttributes.name) {
      if (!formAttributes.slug || this.convertToSlug(name) === formAttributes.slug) {
        return { ...formAttributes, slug: this.convertToSlug(formAttributes.name) }
      }
    }
    return formAttributes
  }

  convertToSlug = (name) => {
    return (name || '').toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/[ ]+/g, "-")
  }

  handleColorChange = ({ target: { value } }) => {
    this.handleFormObjectChange({ primaryColor: value })
  }

  onSaveError = () => {
    this.props.snackbar.actions.show('Error saving')
    window.scrollTo(0, 0)
  }

  onDateChange = (key) => ({ target: { value } }) => {
    this.handleFormObjectChange({ ...this.formObject, [key]: value ? value : '' })
  }

  render = () => {
    return <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Category - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name' />
          <TextField fullWidth member='slug' />
          <TextField fullWidth member='description' />
          <DateTimePicker fullWidth member='activeStartsAt' clearable onChange={this.onDateChange('activeStartsAt')} />
          <DateTimePicker fullWidth member='activeEndsAt' clearable onChange={this.onDateChange('activeEndsAt')} />
          <LabeledSelect fullWidth member='parentId' label="Parent Category" keyField="id" textField="label" options={this.props.categories.list} />
          <TextField fullWidth member='primaryColor' InputProps={{
            endAdornment:
              <InputAdornment position="start">
                <input type="color" value={this.formObject.primaryColor || '#FFFFFF'} onChange={this.handleColorChange} />
              </InputAdornment>,
          }} />
          <LabeledSelect fullWidth member='initialPages' options={[1, 2, 3, 4, 5, 6, 7, 8]} />
          <TextField fullWidth member='externalUrl' />
          <div className={this.props.classes.adMarketplace}>
            <TextField member="admarketplaceTileCount" label="adMarketplace Tile Count" type="number" increment="1" style={{ flex: '1 1 25%' }} />
            <TextField member="admarketplaceSub1" label="adMarketplace Sub 1" style={{ flex: '1 1 25%' }} />
            <TextField member="admarketplaceSub2" label="adMarketplace Sub 2" style={{ flex: '1 1 25%' }} />
            <TextField member="admarketplaceInstantSuggestSub1" label="InstantSuggest Sub 1" style={{ flex: '1 1 25%' }} />
          </div>
          <FileDropZone member="categoryImage" width={168} height={72} type="image" noDropLabel clearable clearValue={"_delete"} />
          <FileDropZone member="heroBanner" width={"100%"} height={217} type="image" label="Default Hero Banner" clearable clearValue={"_delete"} />
          <ScheduledBannerList member='scheduledBanners' />
          {this.formObject.id && !this.formObject.parentId && <>
            <ScheduledDefaultCategoryList member='scheduledDefaultCategories' categoryList={
              this.props.categories.list.filter(c => (`${c.parentId}` === `${this.formObject.id}` || (c.parent && `${c.parent.parentId}` === `${this.formObject.id}`)))
            } />
          </>}
          <LabeledCheckbox member='featured' type="checkbox" label="Featured" />
          {this.formObject.featured && <>
            <LabeledSelect member="mobileLayout" fullWidth options={{ "GRID": "Grid", "CAROUSEL_2_ROWS": "Carousel, Two Rows", "CAROUSEL_2_COLUMNS": "Carousel, 2 Columns" }} />
          </>}
          <LabeledCheckbox member='appOnly' type="checkbox" label="App Only" />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
  }
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  adMarketplace: {
    display: 'flex',
    padding: '16px',
    margin: '16px 0px',
    borderRadius: 16,
    border: 'solid 1px silver',
  },
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(CategoriesContext),
  consume(SnackbarContext)
)(Form)