import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, DateTimePicker, FileDropZone } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, PromotionsContext } from 'contexts'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

export class Form extends InstanceFormMixin(Component, 'promotions') {

  static fetchDependencies({ promotions, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if (editMode) {
      return promotions.actions.show(id)
    }
  }

  get tileId() {
    return (this.props.promotions.selected.id || this.props.match.params.id)
  }

  get formObject() {
    return { tileId: this.tileId, ...super.formObject }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Promotion - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='internalName' />
          <TextField fullWidth member='title' />
          <TextField fullWidth member='caption' />
          <TextField fullWidth member='code' />
          <TextField fullWidth multiline variant='filled' rows={3} member='body' />
          <TextField fullWidth multiline variant='filled' rows={3} member='footer' />
          <TextField fullWidth member='url' />
          <DateTimePicker fullWidth member='activeStartsAt' />
          <DateTimePicker fullWidth member='activeEndsAt' />
          <TextField fullWidth member='utmSource' />
          <TextField fullWidth member='utmMedium' />
          <TextField fullWidth member='utmCampaign' />
          <TextField fullWidth member='utmTerm' />
          <TextField fullWidth member='utmContent' />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& div': {
      marginRight: 10,
    }
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(PromotionsContext),
)(Form)