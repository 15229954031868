import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { UsersContext, SnackbarContext, provide, consume } from 'contexts'
import Typography from '@material-ui/core/Typography'

export class List extends Component{


  static fetchDependencies({users, page}){
    return users.actions.index({
      page: page,
      fields: {users: 'name,email'}
    })
  }

  handlePageSelected = page =>{
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showUser = id => () => {
    this.props.history.push(`/users/${id}`)
  }

  editUser = id => event => {
    this.props.history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  deleteUser = (id, name, email) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm User Deletion', details: { name, email } })) {
      this.props.users.actions.destroy({id})
                  .then(this.props.onDependencyUpdate)
                  .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get users(){
    return this.props.users.list || []
  }

  get errors(){
    let errors = []
    if(this.props.users.errors.index){
      errors = errors.concat(this.props.users.errors.index)
    }
    if(this.props.users.errors.destroy){
      errors = errors.concat(this.props.users.errors.destroy)
    }
    return errors
  }

  renderUserListItem = ({id, name, email}) =>
    <ListItem button onClick={this.showUser(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name} secondary={email}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editUser(id)}><EditIcon/></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deleteUser(id, name, email)}><DeleteIcon/></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

    renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>


  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' paragraph>Users</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.users.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.users.map(this.renderUserListItem)}
        </MuiList>
        <Pagination totalPages={this.props.users.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <FABFixed color='secondary' onClick={() => this.props.history.push('/users/new')}>
          <AddIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}


export default compose(
  Dependent,
  withConfirm,
  connectQueryString('users'),
  provide(UsersContext),
  consume(SnackbarContext)
)(List)