import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, AutoSuggest } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, debounce } from 'utils'
import withStyles from 'styles'
import { provide, StoresContext, MerchantsContext } from 'contexts'
import TileList from './TileList'

export class Form extends InstanceFormMixin(Component, 'stores') {

  static fetchDependencies({ stores, categories, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if (editMode) {
      return stores.actions.show(id, { include: 'merchant,merchant.tiles,merchant.tiles.categories,tileStores,tileStores.tile,tileStores.tile.categories' })
    }
  }

  fetchMerchants = debounce(async (text, callback) => {
    const merchants = await this.props.merchants.actions.index({ pageSize: 5, filter: { name: text } })
    callback(merchants)
  }, 100)

  lastMerchantValue = null
  handleMerchantChange = async ({ target: { value } }) => {
    if (value && value.id && this.lastMerchantValue != value) {
      this.lastMerchantValue = value
      this.handleFormObjectChange({ ...this.state.formAttributes, merchant: value })
      const merchant = await this.props.merchants.actions.show(value.id, { include: 'tiles,tiles.categories' })
      this.handleFormObjectChange({ ...this.state.formAttributes, merchant })
    } else {
      this.lastMerchantValue = value
    }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Store - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <AutoSuggest fullWidth member="merchant" labelProvider={item => item.name} onChange={this.handleMerchantChange} onSuggestionsFetchRequested={this.fetchMerchants} />
          <TextField fullWidth member='name' />
          <TextField fullWidth member='internalName' />
          <TextField fullWidth member='latitude' />
          <TextField fullWidth member='longitude' />
          <TextField fullWidth member='address' />
          <TextField fullWidth member='suburb' />
          <TextField fullWidth member='city' />
          <TextField fullWidth member='postcode' />
          <TextField fullWidth member='state' />
          <TextField fullWidth member='countryCode' />
          <div style={{ marginTop: 16 }}>
            <Typography variant='h6'>Tiles</Typography>
            <TileList editable member='tileStores' merchantTiles={this.formObject?.merchant?.tiles} />
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
})

export default compose(
  Dependent,
  withStyles(styles),
  provide(StoresContext),
  provide(MerchantsContext),
)(Form)