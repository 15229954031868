export function loadAsyncCSS(href){
  const stylesheet = global.document.createElement('link')
  stylesheet.href   = href
  stylesheet.rel    = 'stylesheet'
  stylesheet.type   = 'text/css'
  stylesheet.media  = 'none'
  stylesheet.onload = () => stylesheet.media = 'all'
  global.document.getElementsByTagName('head')[0].appendChild(stylesheet)
}

export function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}