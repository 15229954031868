import {Tokens as TokensBase} from './generated'

export const Tokens = TokensBase.extend(
  '',
  {
    refresh: endpoint => endpoint.method('put').path('/tokens').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
  }
)

export * from './generated'
export { Tiles } from './Tiles'
export { Categories } from './Categories'
export { Promotions } from './Promotions'
export { OrderTypes } from './OrderTypes'
export { FileUploads } from './FileUploads'
export { MediaItems } from './MediaItems'
export { ExploreFeeds } from './ExploreFeeds'