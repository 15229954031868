import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, FileDropZone, ImageCropDialog, FABFixed, ErrorBanner } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import { compose, constrainDimensions, errorStringsFromError, humanize, whitenImageFile } from 'utils'
import { provide, TilesContext, MerchantsContext, VariantTypesContext, MediaItemsContext, SnackbarContext, consume } from 'contexts'
import styles from 'css/merchant_portal/tiles/form.module.css'
import { InputLabel, TextField } from '@material-ui/core'
import { PortraitTilePreview, LegacyTilePreview } from '.'

export class Form extends InstanceFormMixin(Component, 'tiles') {

  state = { imageErrors: {} }

  static fetchDependencies({ tiles, variantTypes, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    const actions = []
    if (editMode) {
      actions.push(tiles.actions.show(id, { include: 'merchant,requestedChange' }))
    }
    actions.push(variantTypes.actions.indexAll())
    return Promise.all(actions)
  }

  componentDidUpdate = (prevProps) => {
    const selected = this.props?.tiles?.selected
    if (prevProps?.tiles?.selected !== selected) {
      this.handleFormObjectChange({
        ...this.state.formAttributes,
        email: this.formObject.email || selected?.requestedChange?.email || selected?.merchant?.email || '',
        images: { ...this.formObject.images, ...selected?.requestedChange?.details?.images }
      })
    }
  }

  get formObject() {
    return { ...this.props[this.resourceName].selected, ...this.state.formAttributes }
  }

  handleImageChange = (field) => ({ target: { value } }) => {
    if (value === "_delete") {
      this.handleFormObjectChange({ ...this.state.formAttributes, images: { ...this.formObject.images, [field]: value } })
    } else {
      this.setState({
        beforeImages: { ...this.state.beforeImages, [field]: this.state.rawImages?.[field] },
        rawImages: { ...this.state.rawImages, [field]: value },
        cropping: field
      })
    }
  }

  handleTileCropClose = (field) => ({ cancelled } = { cancelled: false }) => {
    // Close the cropper & restore the previous raw image if we cancelled
    this.setState({
      rawImages: cancelled ? { ...this.state.rawImages, [field]: this.state.beforeImages?.[field] } : this.state.rawImages,
      cropping: undefined,
    })
  }

  onSaveError = () => {
    this.props.snackbar.actions.show('Error saving')
    window.scrollTo(0, 0)
  }

  handleSave = async () => {
    this.setState({ saving: true, errorMessage: undefined, imageErrors: {} })
    if (this.formObject.images) {
      const variantsByCode = this.props.variantTypes.list.reduce((p, v) => ({ ...p, [v.typeCode]: v }), {})
      const images = {}
      const errors = []
      const imageErrors = {}
      for (const key in this.formObject.images) {
        const image = this.formObject.images[key]
        if ((image && image.constructor) === File || (image && image.constructor) === Blob) {
          try {
            const mediaItem = await this.props.mediaItems.actions.create({ file: image, definition: variantsByCode[key] })
            images[key] = mediaItem
          } catch (error) {
            imageErrors[key] = true
            const errorMessage = error?.meta?.file ? `${humanize(key)} does not meet the image requirements` : 'Unexpected error, please try again'
            errors.push(errorMessage)
            console.warn(error)
          }
        } else {
          images[key] = image
        }
      }
      console.log(errors)
      if (errors.length > 0) {
        this.handleFormObjectChange({ ...this.state.formAttributes, images: { ...this.formObject.images, ...images } }) // Keep the successful ones so they don't get uploaded again
        this.setState({ saving: false, errorMessage: ['Please correct the following and try again:', errors], imageErrors })
        window.scrollTo(0, 0)
      } else {
        this.handleFormObjectChange({ ...this.state.formAttributes, images }, async () => {
          try {
            await this.save(this.actions.requestChange(this.attributesToSave))
          } finally {
            this.setState({ saving: false, errorMessage: undefined, imageErrors: {} })
          }
        })
      }
    }
  }

  whitenLogo = async (file) => {
    const outputType = file.type === "image/svg+xml" ? "image/svg+xml" : 'image/png'
    const fileName = outputType === "image/svg+xml" ? "Logo.svg" : "Logo.png"
    return await whitenImageFile(file, fileName, outputType)
  }

  get error() {
    return this.errors.requestChange
  }

  imageRows = [
    // {
    //   key: 'portrait',
    //   editableImages: [
    //     { typeCode: 'portraitTileImage', crop: true, transform: undefined, isLogo: false, helperText: 'Image must be a PNG or JPG, 856 pixels wide by 1250 pixels high' },
    //     { typeCode: 'logoImage', crop: false, transform: this.whitenLogo, isLogo: true, helperText: 'Image must be a SVG or PNG, at least 680 pixels wide and 193 pixels high, background must be transparent.' },
    //   ],
    //   renderPreview: () => <div style={{ marginBottom: 16 }}>
    //     <InputLabel style={{ padding: '10px 0px 5px' }}>{"Portrait Tile Preview"}</InputLabel>
    //     <PortraitTilePreview tileImage={this.formObject?.images?.portraitTileImage} logoImage={this.formObject?.images?.logoImage} />
    //   </div>
    // },
    {
      key: 'legacy',
      editableImages: [
        { typeCode: 'legacyTileImage', crop: true, transform: undefined, isLogo: false, helperText: 'Image must be a PNG or JPG, 1080 pixels wide by 774 pixels high' },
        { typeCode: 'legacyLogoImage', crop: false, transform: this.whitenLogo, isLogo: true, helperText: 'Image must be a SVG or PNG, at least 680 pixels wide and 193 pixels high, background must be transparent.' },
      ],
      renderPreview: () => <div style={{ marginBottom: 16 }}>
        <InputLabel style={{ padding: '10px 0px 5px' }}>{"Legacy Tile Preview"}</InputLabel>
        <LegacyTilePreview tileImage={this.formObject?.images?.legacyTileImage} logoImage={this.formObject?.images?.legacyLogoImage} />
      </div>
    }
  ]

  handleCancel = () => {
    this.props.history.push(`/tiles`)
  }

  renderErrorMessages = () => (this.state.errorMessage || this.error) ?
    <ErrorBanner>
      {this.state.errorMessage || errorStringsFromError(this.error)}
    </ErrorBanner> :
    false

  render = () => {
    const variantsByCode = this.props.variantTypes.list.reduce((p, v) => ({ ...p, [v.typeCode]: v }), {})
    return !this.props.loading && (<Card className={styles.card}>
      <Typography variant='h5'>Edit Tile - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange}>
        <CardContent style={{ maxWidth: 'initial' }}>
          <div className={styles.errorBanner}>
            {this.renderErrorMessages()}
          </div>
          <div style={{ maxWidth: 1000, marginLeft: 'auto', marginRight: 'auto', marginBottom: 16 }}>
            <TextField member="email" fullWidth helperText="Please enter an email address so we can notify you once changes are reviewed" />
          </div>
          <div className={styles.grid}>
            {this.imageRows.map(({ key, editableImages, renderPreview }) => <React.Fragment key={key}>
              <div className={styles.imageRow}>
                {editableImages.map(({ typeCode, crop, transform, isLogo, helperText }) => {
                  const variantType = variantsByCode[typeCode]
                  const { width: pickerWidth, height: pickerHeight } = constrainDimensions(variantType?.width || 1, variantType?.height || 1, 300, 320)
                  return !!variantType && <div key={typeCode} style={{ marginBottom: 16 }}>
                    <div style={{ position: 'relative', marginRight: 32 }}>
                      <FileDropZone noDropLabel clearable clearValue="_delete" member={`images.${typeCode}`} width={pickerWidth} height={pickerHeight} type="image"
                        noImmediateDisplay={crop} onChange={crop ? this.handleImageChange(typeCode) : undefined} mutateFile={transform} padding={isLogo ? 8 : 0} helperText={helperText} error={this.state.imageErrors[typeCode]} />
                      {crop && <ImageCropDialog open={this.state.cropping === typeCode} onClose={this.handleTileCropClose(typeCode)}
                        member={`images.${typeCode}`} fileName={`${typeCode}.png`} value={this.state.rawImages?.[typeCode] || this.formObject.images?.[typeCode]}
                        maxWidth={variantType.enforcePixelSize ? variantType.width : 2160} aspect={variantType.width / variantType.height}
                        targetWidth={variantType.enforcePixelSize ? variantType.width : undefined} targetHeight={variantType.enforcePixelSize ? variantType.height : undefined}
                        restrictPosition={true} />}
                    </div>
                  </div>
                }
                )}
              </div>
              {renderPreview()}
            </React.Fragment>)}
          </div>
          {!this.state.saving && <FABFixed variant="extended" color="secondary" onClick={this.handleCancel} style={{ right: 200 }}>
            <CancelIcon style={{ marginRight: 8 }} />Cancel
          </FABFixed>}
          <FABFixed variant="extended" color="primary" onClick={this.handleSave} disabled={!!this.state.saving}>
            <SaveIcon style={{ marginRight: 8 }} />{!!this.state.saving ? 'Saving...' : 'Save Changes'}
          </FABFixed>
        </CardContent>
      </FormContext>
    </Card>)
  }
}

export default compose(
  Dependent,
  consume(SnackbarContext),
  provide(TilesContext),
  provide(VariantTypesContext),
  provide(MerchantsContext),
  provide(MediaItemsContext),
)(Form)