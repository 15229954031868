import React, { Component } from 'react'
import { Pagination, ErrorBanner, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FileUploadIcon from '@material-ui/icons/CloudUpload'
import UndoIcon from '@material-ui/icons/Undo'
import Typography from '@material-ui/core/Typography'
import { compose, errorStringsFromError, formatLocaleDateTime, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, FileUploadsContext } from 'contexts'
import { Button } from '@material-ui/core'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ fileUploads, page }) {
    return fileUploads.actions.index({
      page: page,
      pageSize: 8,
      include: 'user,changeset,undoChangeset,undoneBy',
      sort: '-created_at'
    })
  }

  undoFileUpload = (fileUpload) => async event => {
    if (await this.props.confirm({ title: `Confirm Undo of File Upload`, renderBody: () => this.renderFileDescription(fileUpload) })) {
      const result = await this.props.fileUploads.actions.undo(fileUpload.id)
      this.props.snackbar.actions.show({
        "Started": "Undo started, you will be emailed on completion",
        "Already Running": "An undo is already running, please wait for it to finish"
      }[result.status] || "Error Undoing")
    }
  }

  get fileUploads() {
    return this.props.fileUploads.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.fileUploads.errors.index) {
      errors = errors.concat(this.props.fileUploads.errors.index)
    }
    if (this.props.fileUploads.errors.undo) {
      errors = errors.concat(this.props.fileUploads.errors.undo)
    }
    return errors
  }

  statusColors = { "Success": "green", "Error": "red", "Undone": "#2E86C1" }

  renderFileDescription = ({ filename, status, user, createdAt, undoChangeset, undoneBy }) =>
    <table style={{color: '#555', textAlign: 'left'}}>
      <tr><th>File Name:</th><td colspan="3">{filename}</td></tr>
      <tr><th>Status:</th><td style={{color: this.statusColors[status]}}>{status}</td></tr>
      <tr>
        <th>Uploaded By:</th><td>{user?.name} at {formatLocaleDateTime(createdAt)}</td>
      </tr>
      {undoneBy && <tr>
        <th>Undone By:</th><td>{undoneBy?.name} at {formatLocaleDateTime(undoChangeset?.createdAt)}</td>
      </tr>}
    </table>

  renderFileUploadListItem = (fileUpload) => {
    const { id, fileUrl, changeset } = fileUpload
    return (
      <ListItem button key={id} style={{borderBottom: 'solid 1px #CCC'}}>
        <ListItemIcon>
          <div style={styles.badge}>
            <FileUploadIcon />
            <span style={{ paddingLeft: 8, paddingTop: 2 }}>{fileUpload.uploadType}</span>
          </div>
        </ListItemIcon>
        {this.renderFileDescription(fileUpload)}
        <ListItemSecondaryAction>
          <IconButton onClick={() => window.open(fileUrl, "_blank")}><DownloadIcon /></IconButton>
          {this.state.undoEnabled && <IconButton disabled={!changeset || fileUpload.uploadType !== 'Tile'} onClick={this.undoFileUpload(fileUpload)}><UndoIcon /></IconButton>}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  toggleUndoVisible = () => {
    this.setState({ undoEnabled: !this.state.undoEnabled })
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' style={{ display: 'flex', marginBottom: 16, zIndex: 0 }}>
          File Uploads
          <div style={{ flex: 1 }}></div>
          {Authorization.superAdmin && <Button onClick={this.toggleUndoVisible}>{this.state.undoEnabled ? 'Hide Undo' : 'Show Undo'}</Button>}
        </Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.fileUploads.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <MuiList dense>
          {this.fileUploads.map(this.renderFileUploadListItem)}
        </MuiList>
        <Pagination totalPages={this.props.fileUploads.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
      </CardContent>
    </Card>
}

const styles = {
  badge: {
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#999',
    color: '#FFF',
    padding: 8,
    marginRight: 16,
    minWidth: '8em',
    fontSize: '0.8em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  }
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('fileUploads'),
  provide(FileUploadsContext),
  consume(SnackbarContext),
)(List)