import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledSelect } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, debounce } from 'utils'
import withStyles from 'styles'
import { provide, StoresContext, CategoriesContext, RequestedChangesContext, MerchantsContext } from 'contexts'

export class Form extends InstanceFormMixin(Component, 'stores') {

  static fetchDependencies({ stores, categories, requestedChanges, match: { path, params: { id, rcid } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)

    const categoriesPromise = categories.actions.index({
      fields: { categories: 'name' },
      filter: { countries_only: true },
      sort: 'name'
    })

    if (rcid) {
      return Promise.all(requestedChanges.actions.show(rcid),categoriesPromise)
    } else if (editMode) {
      return Promise.all(stores.actions.show(id, { include: 'requestedChange' }), categoriesPromise)
    }
    return categoriesPromise
  }

  get requestedChangeId() {
    return this.props.match.params.rcid
  }

  handleSave = async () => {
    // Note that a pending record will be updated by requestNew based on internal_name
    await this.save((this.requestedChangeId || this.createMode) ? this.actions.requestNew(this.attributesToSave) : this.actions.requestChange(this.attributesToSave))
  }

  get formObject() {
    if (this.requestedChangeId) {
      return { ...this.props.requestedChanges.selected?.details, ...this.state.formAttributes }
    } else {
      return { ...this.props[this.resourceName].selected, ...this.props[this.resourceName].selected?.requestedChange?.details, ...this.state.formAttributes }
    }
  }

  get error(){
    return this.errors[this.props.match.path.match(/\/new(?:\/:[^/]+)?$/) ? 'requestNew' : 'requestChange']
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Store</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.handleSave}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name' />
          <TextField fullWidth member='latitude' />
          <TextField fullWidth member='longitude' />
          <TextField fullWidth member='address' />
          <TextField fullWidth member='suburb' />
          <TextField fullWidth member='city' />
          <TextField fullWidth member='postcode' />
          <TextField fullWidth member='state' />
          <LabeledSelect fullWidth member='countryCode' keyField="name" textField="name" options={this.props.categories.list} />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Submit Change Request</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
})

export default compose(
  Dependent,
  withStyles(styles),
  provide(StoresContext),
  provide(MerchantsContext),
  provide(CategoriesContext),
  provide(RequestedChangesContext),
)(Form)