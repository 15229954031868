import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, TilesContext } from 'contexts'

export class Show extends Component{


  static fetchDependencies({tiles,  match: { params: { id }}}){
    return tiles.actions.show(id, {
      include: ''
    })
  }

  get tile(){
    return this.props.tiles.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Tile - {this.tile.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/tiles/${this.tile.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Name</TableCell>
                <TableCell>{String(this.tile.name || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Url</TableCell>
                <TableCell>{String(this.tile.url || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Tile image</TableCell>
                <TableCell>{String(this.tile.tileImage || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Utm source</TableCell>
                <TableCell>{String(this.tile.utmSource || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Active starts at</TableCell>
                <TableCell>{String(this.tile.activeStartsAt || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Active ends at</TableCell>
                <TableCell>{String(this.tile.activeEndsAt || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Instore</TableCell>
                <TableCell>{String(this.tile.instore || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Online</TableCell>
                <TableCell>{String(this.tile.online || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Global</TableCell>
                <TableCell>{String(this.tile.global || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Coming soon</TableCell>
                <TableCell>{String(this.tile.comingSoon || '')}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default provide(TilesContext)(Dependent(Show))