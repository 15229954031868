import React, { Component } from 'react'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, MerchantsContext } from 'contexts'

export class Form extends InstanceFormMixin(Component, 'merchants'){

  static fetchDependencies({merchants, match: { path, params: {id}}}){
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if(editMode){
      return merchants.actions.show(id)
    }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Merchant - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <TextField fullWidth member='merchantId'/>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(MerchantsContext),
)(Form)