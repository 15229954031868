import React, { Component } from 'react'
import 'swagger-ui/dist/swagger-ui.css'
import { TokenStore } from 'services'

export class ApiDocs extends Component{

  componentDidMount(){
    const url = `${process.env.REACT_APP_API_BASE}/docs`
    import('swagger-ui').then(({default: SwaggerUI}) => {
      this.swaggerUi = SwaggerUI({
        dom_id: '#api-docs',
        url,
        requestInterceptor: (req) => {
          if (req.url === url){
            req.headers.Authorization = global.localStorage.auth;
            req.headers['X-Authorization'] = global.localStorage.auth;
          }
          return req;
        },
        deepLinking: true,
        sorter: "alpha",
        jsonEditor: true,
        defaultModelRendering: 'schema',
        showRequestHeaders: true,
        presets: [
          SwaggerUI.presets.apis,
          SwaggerUI.presets.base,
          SwaggerUI.presets.lowlight
        ],
        plugins: [
          SwaggerUI.plugins.DownloadUrl
        ],
        onComplete: () => {
          this.swaggerUi.preauthorizeApiKey('ApiKey', TokenStore.auth)
        }
      })
    })
  }

  render = () =>
    <div id='api-docs'>
    </div>

}

export default ApiDocs