import React, { Component } from 'react'
import { FormContext, DateTimePicker, FileDropZone, ConfirmationDialog } from 'components'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { userFriendlyDate } from 'utils'
import MuiList from '@material-ui/core/List'
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Fab, InputLabel } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

export class ScheduledBannerList extends Component {
  state = {}

  addScheduledBanner = () => {
    let newBanner = { internalName: '', activeStartsAt: '', activeEndsAt: '' }
    this.setState({ editBanner: newBanner, editBannerIndex: -1 })
  }

  handleChange = (newValue) => {
    this.props.onChange({ target: { value: newValue } })
  }

  handleBannerEditConfirm = () => {
    const existing = (this.props.value || [])
    const editIndex = this.state.editBannerIndex
    if (editIndex === -1) {
      this.handleChange([...existing, this.state.editBanner])
    } else {
      this.handleChange(Object.assign(existing.slice(), { [editIndex]: this.state.editBanner }))
    }
    this.setState({ editBanner: undefined, editBannerIndex: undefined })
  }

  handleDeleteScheduledBanner = i => () => {
    const existing = (this.props.value || [])
    this.handleChange(Object.assign(existing.slice(), { [i]: { ...existing[i], _destroy: true } }))
  }

  handleBannerEditCancel = () => {
    this.setState({ editBanner: undefined, editBannerIndex: undefined })
  }

  renderScheduledBannerEdit = () =>
    <ConfirmationDialog
      title={"Edit Scheduled Banner"}
      open={!!this.state.editBanner}
      onConfirm={this.handleBannerEditConfirm}
      onCancel={this.handleBannerEditCancel}
    >
      <FormContext onChange={state => this.setState({ editBanner: { ...this.state.editBanner, ...state } })} context={this.state.editBanner}>
        <TextField fullWidth member='internalName' />
        <DateTimePicker fullWidth member='activeStartsAt' />
        <DateTimePicker fullWidth member='activeEndsAt' />
        <FileDropZone member="bannerImage" width={"100%"} height={157} type="image" label="Banner Image" />
      </FormContext>
    </ConfirmationDialog>

  handleScheduledBannerClick = i => () => {
    this.setState({ editBanner: this.props.value[i], editBannerIndex: i })
  }

  handleInlineBannerChange = i => ({ target: { value } }) => {
    const existing = (this.props.value || [])
    this.handleChange(Object.assign(existing.slice(), { [i]: { ...existing[i], bannerImage: value } }))
  }

  renderScheduledBannerListItem = ({ internalName, activeStartsAt, activeEndsAt, active, bannerImage, _destroy }, i) =>
    (_destroy ? false : <ListItem button key={i} onClick={this.handleScheduledBannerClick(i)}>
      <ListItemIcon>
        <div style={{ marginRight: 16 }}>
          <FileDropZone value={bannerImage} width={196} height={64} type="image" noLabel={true} onChange={this.handleInlineBannerChange(i)} />
        </div>
      </ListItemIcon>
      <ListItemText primary={<b>{internalName}</b>} secondary={<span>{userFriendlyDate(activeStartsAt)}–{userFriendlyDate(activeEndsAt)}</span>} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.handleScheduledBannerClick(i)}><EditIcon /></IconButton>
        <IconButton onClick={this.handleDeleteScheduledBanner(i)}><DeleteIcon /></IconButton>
      </ListItemSecondaryAction>
    </ListItem>)

  render = () =>
    <div style={{ marginBottom: 16 }}>
      {this.renderScheduledBannerEdit()}
      <InputLabel style={{ margin: '8px 0px 8px 0px' }}>Scheduled Hero Banners</InputLabel>
      <Card>
        <CardContent>
          <MuiList dense>
            {(this.props.value || []).map(this.renderScheduledBannerListItem)}
          </MuiList>
          <Fab color='secondary' onClick={this.addScheduledBanner}>
            <AddIcon />
          </Fab>
        </CardContent>
      </Card>
    </div>
}

export default ScheduledBannerList