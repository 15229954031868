import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import FilteredSearchInput from './FilteredSearchInput'
import { FormContext, ConfirmationDialog, LinkButton } from 'components'
import { debounce } from '../utils'
import withStyles from 'styles'

export class SearchCombo extends Component{
  static propTypes = {
    className: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string,
    searchTextMember: PropTypes.string
  }

  static defaultProps = {
    className: '',
    hasFiltersApplied: false,
    dialogTitle: 'Filters',
    searchTextMember: 'searchText'
  }

  constructor(props) {
    super(props)

    this.state = {
      filtersOpen: false,
      filter: props.filter
    }
  }

  get hasFiltersApplied(){
    return Object.values(this.props.filter).some(x => x && (!Array.isArray(x) || x.length !== 0))
  }

  handleChange = debounce(() => {
    this.props.onFilterChange(this.state.filter)
  })

  handleOpenFilters = () => {
    this.setState({
      filtersOpen: true,
      filter: this.props.filter
    })
  }

  handleCloseFilters = () => {
    this.setState({filtersOpen: false})
  }

  handleFilterConfirm = () => {
    this.handleChange()
    this.handleCloseFilters()
  }

  handleFilterCancel = () => {
    this.setState({filter: this.props.filter})
    this.handleCloseFilters()
  }

  handleTextChange = ({target: { value: filterText }}) => {
    this.setState({
      filter: {...this.state.filter, [this.props.searchTextMember]: filterText }
    }, this.handleChange)
  }

  handleFilterChanged = (filter) => {
    this.setState({filter})
  }

  handleResetFilter = () => {
    this.setState({
      filter: Object.entries(this.state.filter).reduce((agg, [key, val]) => ({...agg, [key]: Array.isArray(val) ? [] : undefined}), {})
    })
  }

  render = () => {
    const { dialogTitle, classes, children, validate, searchTextMember, onFilterChange, ...textFieldProps } = this.props
    const { filter, filtersOpen } = this.state
    return (
      <Fragment>
        <FilteredSearchInput
          {...textFieldProps}
          onOpenFilters={this.handleOpenFilters}
          onChange={this.handleTextChange}
          hasFiltersApplied={this.hasFiltersApplied}
          hasAdditionalFilters={!!children}
          value={filter[searchTextMember] || ''}
        />
        <ConfirmationDialog
          title={dialogTitle}
          open={filtersOpen}
          onConfirm={this.handleFilterConfirm}
          onCancel={this.handleFilterCancel}
          confirmLabel={'Filter'}
          cancelLabel={'Cancel'}
          canConfirm={validate ? validate(filter) : true}
        >
          <FormContext onChange={this.handleFilterChanged} context={filter}>
            <div className={classes.form}>
              {children}
            </div>
            <LinkButton onClick={this.handleResetFilter}>Reset Filters</LinkButton>
          </FormContext>
        </ConfirmationDialog>

      </Fragment>
    )
  }
}

const styles = {
  form: {
    paddingBottom: 20,
    '& h6': {
      marginTop: 20
    }
  }
}

export default (withStyles(styles)(SearchCombo))
