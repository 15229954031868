import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'

export class Routes extends Component{

  render = () =>
    <Switch>
      <Route exact path="/"                           component={() =>  <Redirect to={"/tiles"}/>}/>
      <Route exact path="/stores"                     component={Containers.MerchantPortal.Store.List}/>
      <Route exact path="/stores/new/:rcid"           component={Containers.MerchantPortal.Store.Form}/>
      <Route exact path="/stores/new"                 component={Containers.MerchantPortal.Store.Form}/>
      <Route exact path="/stores/:id/edit"            component={Containers.MerchantPortal.Store.Form}/>
      <Route exact path="/stores/:id"                 component={Containers.MerchantPortal.Store.Show}/>

      <Route exact path="/tiles"                      component={Containers.MerchantPortal.Tile.List}/>
      <Route exact path="/tiles/:id/edit"             component={Containers.MerchantPortal.Tile.Form}/>

      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} />
      <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.superAdmin}/>
      <Route component={Containers.Status.NotFound} />
    </Switch>

}

export default withRouter(Routes)