import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, MediaItemsContext } from 'contexts'

export class Show extends Component {


  static fetchDependencies({ mediaItems, match: { params: { id } } }) {
    return mediaItems.actions.show(id, {
      include: ''
    })
  }

  get mediaItem() {
    return this.props.mediaItems.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Media Item</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant='head'>URL</TableCell>
              <TableCell>{String(this.mediaItem.url || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Image</TableCell>
              <TableCell><img alt="Media Image" src={this.mediaItem.url} style={{ maxWidth: '100%' }} /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
      </CardContent>
    </Card>
}


export default provide(MediaItemsContext)(Dependent(Show))