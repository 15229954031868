import BaseContext from 'contexts/base/BaseContext'
import { history, decodeJSONApiResponse } from 'utils'
import { TokenStore } from 'services'
import { SessionStatus } from '../constants'
import jwt_decode from 'jwt-decode'
import * as API from 'api'

export const SECOND                  = 1000
export const MINUTE                  = 60 * SECOND
export const INACTIVITY_PERIOD       = 5  * MINUTE
export const REFRESH_TOKEN_THRESHOLD = 10 * SECOND


export default class TokensContext extends BaseContext {

  static contextName = 'tokens'

  static initialState = {
    ...this.initialState,
    currentUser: {},
    loginState: SessionStatus.UNKNOWN,
    errors: {}
  }

  decodeToken = ({ auth: token }) => {
    const { user, exp, merchant_id } = jwt_decode(token)
    TokenStore.actorType = merchant_id ? 'merchant' : 'user'
    return { currentUser: (user ? decodeJSONApiResponse(user).data : undefined), exp, merchantId: merchant_id }
  }

  get timestamp(){
    return + new Date()
  }

  get inactive(){
    return parseInt(this.lastActivity, 10) < (this.timestamp - INACTIVITY_PERIOD)
  }

  registerActivity = () => {
    this.lastActivity = this.timestamp
  }

  startInactivityTimeout = () =>{
    clearInterval(this.onExpiryInterval)

    this.onExpiryInterval = setInterval(() => {
      const inactivityCheckTime = (this.state.exp * SECOND) - REFRESH_TOKEN_THRESHOLD
      if(this.timestamp > inactivityCheckTime){
        clearInterval(this.onExpiryInterval)
        this.inactive ? this.timedOut() : this.verify()
      }
    }, SECOND)
  }

  timedOut(){
    clearInterval(this.onExpiryInterval)
    this.saveWindowLocation()
    history.push('/inactive', {})
  }

  verify = async (initial) =>{
    let refreshToken = TokenStore.refresh
    if(initial){
      this.saveWindowLocation()
      const tokenFromUrl = (new URL(document.location)).searchParams.get("token")
      refreshToken = tokenFromUrl || refreshToken
    }
    try{
      const { data: payload } = await API.Tokens.refresh(refreshToken)
      this.startInactivityTimeout()
      this.setState({loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload) })
    }catch(error){
      await TokenStore.actorUnauthenticated()
      this.setState({loginState: SessionStatus.UNAUTHENTICATED })
      console.warn("Not logged in")
    }
  }

  create = async (credentials) =>{
    try{
      this.setState({errors: {...this.state.errors, create: null} })
      const { data: payload } = await API.Tokens.create(credentials)
      this.registerActivity()
      this.startInactivityTimeout()
      this.setState({loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload)})
    }catch(error){
      this.setState({loginState: SessionStatus.UNAUTHENTICATED, errors: {...this.state.errors, create: error} })
      throw error
    }
  }

  refresh = async (saveLocation=true) =>{
    if(saveLocation)
      this.saveWindowLocation()

    await API.Tokens.refresh(TokenStore.refresh)
    this.registerActivity()
    this.startInactivityTimeout()
  }

  destroy = async () =>{
    this.clearSavedWindowLocation()
    try{
      await API.Tokens.destroy()
    }catch(error){
      throw error
    } finally {
      await TokenStore.destroy()
      this.setState({ currentUser: {}, loginState: SessionStatus.UNAUTHENTICATED })
    }
  }

  forgot = async (credentials) =>{
    await API.Tokens.forgot(credentials)
  }

  reset = async(credentials) =>{
    try{
      this.setState({errors: {...this.state.errors, reset: null} })
      await API.Tokens.reset(credentials)
    }catch(error){
      this.setState({errors: {...this.state.errors, reset: error} })
      throw error
    }
  }

  unlock = async (credentials) =>{
    try{
      this.setState({errors: {...this.state.errors, reset: null} })
      await API.Tokens.unlock(credentials)
    }catch(error){
      this.setState({ errors: {...this.state.errors, reset: error} })
      throw error
    }
  }

  resendConfirmation = async (credentials) => {
    try{
      this.setState({errors: {...this.state.errors, resendConfirmation: null} })
      await API.Tokens.resendConfirmation(credentials)
    }catch(error){
      this.setState({errors: {...this.state.errors, resendConfirmation: error} })
      throw error
    }
  }

  confirm = async (credentials) => {
    try{
      this.setState({errors: {...this.state.errors, confirm: null} })
      await API.Tokens.confirm(credentials)
    }catch(error){
      this.setState({errors: {...this.state.errors, confirm: error} })
      throw error
    }
  }

  acceptInvite = async (credentials) => {
    try{
      this.setState({errors: {...this.state.errors, acceptInvite: null} })
      await API.Tokens.acceptInvite(credentials)
    }catch(error){
      this.setState({errors: {...this.state.errors, acceptInvite: error} })
      throw error
    }
  }

  saveWindowLocation = () =>{
    this.setState({savedLocation: window.location.pathname})
  }

  clearSavedWindowLocation = () =>{
    this.setState({savedLocation: null})
  }
}
