import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SyncIcon from '@material-ui/icons/Sync'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, OrderTypesContext } from 'contexts'
import Typography from '@material-ui/core/Typography'

export class List extends Component{

  state = {
    page: 1
  }

  static fetchDependencies({orderTypes, page}){
    return orderTypes.actions.index({
      page: page,
      fields: {orderTypes: 'name'}
    })
  }

  showOrderType = id => () => {
    this.props.history.push(`/order_types/${id}/edit`)
  }

  editOrderType = id => event => {
    this.props.history.push(`/order_types/${id}/edit`)
    event.stopPropagation()
  }

  deleteOrderType = (id, name) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Order Option Deletion', details: { name } })) {
      this.props.orderTypes.actions.destroy({id})
                  .then(this.props.onDependencyUpdate)
                  .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get orderTypes(){
    return this.props.orderTypes.list
  }

  handlePageSelected = page =>{
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  syncAutoSortData = async () => {
    const result = await this.props.orderTypes.actions.syncAutoSort()
    this.props.snackbar.actions.show(result.data.message)
  }

  get errors(){
    let errors = []
    if(this.props.orderTypes.errors.index){
      errors = errors.concat(this.props.orderTypes.errors.index)
    }
    if(this.props.orderTypes.errors.destroy){
      errors = errors.concat(this.props.orderTypes.errors.destroy)
    }
    return errors
  }

  renderOrderTypeListItem = ({id, name}) =>
    <ListItem button onClick={this.showOrderType(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editOrderType(id)}><EditIcon/></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deleteOrderType(id, name)}><DeleteIcon/></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' style={{display: 'flex'}}>
          Orders Options
          <div style={{flex: 1}}></div>
          <IconButton onClick={this.syncAutoSortData}><SyncIcon/></IconButton>
        </Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.orderTypes.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.orderTypes.map(this.renderOrderTypeListItem)}
        </MuiList>
        <Pagination totalPages={this.props.orderTypes.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <FABFixed color='secondary' onClick={() => this.props.history.push('/order_types/new')}>
          <AddIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('orderTypes'),
  provide(OrderTypesContext),
  consume(SnackbarContext),
)(List)