import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, SearchCombo, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MerchantIcon from '@material-ui/icons/Store'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, MerchantsContext } from 'contexts'
import Typography from '@material-ui/core/Typography'

export class List extends Component{

  state = {
    page: 1
  }

  static fetchDependencies({merchants, page, filter}){
    return merchants.actions.index({
      page: page,
      filter: filter,
      fields: {merchants: 'name'}
    })
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  showMerchant = id => () => {
    this.props.history.push(`/merchants/${id}`)
  }

  editMerchant = id => event => {
    this.props.history.push(`/merchants/${id}/edit`)
    event.stopPropagation()
  }

  deleteMerchant = (id,name) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Merchant Deletion', details: { name } })) {
      this.props.merchants.actions.destroy({id})
                  .then(this.props.onDependencyUpdate)
                  .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get merchants(){
    return this.props.merchants.list
  }

  handlePageSelected = page =>{
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors(){
    let errors = []
    if(this.props.merchants.errors.index){
      errors = errors.concat(this.props.merchants.errors.index)
    }
    if(this.props.merchants.errors.destroy){
      errors = errors.concat(this.props.merchants.errors.destroy)
    }
    return errors
  }

  renderMerchantListItem = ({id, name}) =>
    <ListItem button onClick={this.showMerchant(id)} key={id}>
      <ListItemIcon>
        <MerchantIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editMerchant(id)}><EditIcon/></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deleteMerchant(id,name)}><DeleteIcon/></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () => {
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter}>
      </SearchCombo>
    )
    return (
      <Card>
        <CardContent>
          <Typography variant='h3' paragraph>Merchants</Typography>
          {this.renderErrorMessages()}
          <Pagination totalPages={this.props.merchants.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
          <MuiList dense>
            {this.merchants.map(this.renderMerchantListItem)}
          </MuiList>
          <Pagination totalPages={this.props.merchants.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
          <FABFixed color='secondary' onClick={() => this.props.history.push('/merchants/new')}>
            <AddIcon/>
          </FABFixed>
        </CardContent>
      </Card>
    )
  }
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('merchants'),
  provide(MerchantsContext),
  consume(SnackbarContext),
)(List)