import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { nextKey } from 'utils'

const DraggableList = ({ value, onChange, render, direction, keyed, buildItem, keyField, keyExtractor, disabled, style, addStyle, refName = 'ref', listId, multi }) => {
  const handleAdd = () => {
    const decorate = keyed ? { key: nextKey(value) } : {}
    const newItem = { ...(!!buildItem && buildItem()), ...decorate }
    onChange([...value, newItem])
  }
  const handleDragEnd = ({ source, destination }) => {
    if (destination?.droppableId === `removeFrom-${listId}`) {
      const newList = [...value]
      newList.splice(source.index, 1)
      onChange(newList)
    } else if (source && destination) {
      const newList = [...value]
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      onChange(newList)
    }
  }

  if (keyField && !keyExtractor) {
    keyExtractor ||= item => item[keyField]
  }

  const renderList = () => <Droppable droppableId={listId || "droppable"} direction={direction}>
    {(provided, outerState) => (
      <div ref={provided.innerRef} {...provided.droppableProps} style={style}>
        {(value || []).map((item, i) =>
          <Draggable key={keyExtractor ? keyExtractor(item) : i + 1} draggableId={`${listId}_${keyExtractor ? keyExtractor(item) : i + 1}`} index={i} isDragDisabled={disabled}>
            {(provided) => render(item, i, { ...provided.draggableProps, ...provided.dragHandleProps, [refName]: provided.innerRef })}
          </Draggable>
        )}
        {provided.placeholder}
        <Droppable droppableId={`removeFrom-${listId}`} direction={'vertical'} isDropDisabled={disabled}>
          {(provided, innerState) => (
            <div ref={provided.innerRef} {...provided.droppableProps}
              style={{
                flex: '1 1 1', borderRadius: 8, display: (disabled ? 'none' : 'flex'), alignItems: 'center', justifyContent: 'center',
                padding: 16, ...addStyle, border: (!!outerState.draggingFromThisWith && innerState.isDraggingOver) ? 'solid 1px #ef5350' : (addStyle?.border || 'none')
              }}>
              <IconButton onClick={handleAdd} disabled={!!outerState.draggingFromThisWith}>
                {(!outerState?.draggingFromThisWith) ? <AddIcon /> :
                  <RemoveIcon style={{ color: innerState.isDraggingOver ? '#ef5350' : undefined }} />}
              </IconButton>
            </div>
          )}
        </Droppable>
      </div>
    )}
  </Droppable>

  return multi ? renderList() : (
    <DragDropContext onDragEnd={handleDragEnd}>
      {renderList()}
    </DragDropContext>
  )
}

DraggableList.Context = ({ children, value, onChange, canChange, keyed }) => {
  const handleDragEnd = ({ source, destination }) => {
    const item = value?.[source?.droppableId]?.[source?.index]
    if (canChange[source?.droppableId] && source?.droppableId == destination?.droppableId && item) { // Item moved within a list
      const newList = [...value?.[source?.droppableId]]
      newList.splice(destination.index, 0, newList.splice(source.index, 1)[0]);
      onChange({ ...value, [source.droppableId]: newList })
    } else if (source?.droppableId && destination?.droppableId && item) {
      let newValue = value
      if (canChange[source.droppableId] && !(destination.droppableId.startsWith('removeFrom-') && destination.droppableId !== `removeFrom-${source.droppableId}`)) {
        const newList = value[source.droppableId]
        newList.splice(source.index, 1)
        newValue = { ...newValue, [source.droppableId]: newList }
      }
      if (canChange[destination.droppableId]) {
        const newList = [...value?.[destination.droppableId]]
        newList.splice(destination.index, 0, { ...item, ...(keyed ? { key: nextKey(newList) } : {}) })
        newValue = { ...newValue, [destination.droppableId]: newList }
      }
      if (newValue !== value) {
        onChange(newValue)
      }
    }
  }
  return <DragDropContext onDragEnd={handleDragEnd}>
    {children}
  </DragDropContext>
}

export default DraggableList