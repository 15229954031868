import APIContext from 'contexts/base/APIContext'

export default class TilesContext extends APIContext {
  static contextName = 'tiles'

  requestChange = async(item, options={}) => {
    const { data: selected } = await this.performRequest('requestChange')({...item, options})
    return selected
  }

  xlsxGenerate = async({page = this.state.page, params={}, fields=null, include=null, filter=null, sort=null, pageSize=undefined, ...opts }={}) => {
    const {data: fileDownload} = await this.performRequest('xlsxGenerate')(
      {
        ...params,
        options: {
          page: { number: page, size: pageSize },
          ...sort && {sort},
          ...filter && {filter},
          ...fields && {fields},
          ...include && {include},
          ...opts
        }
      }
    )

    this.setState({ fileDownload })
    return fileDownload
  }

  xlsxStatus = async(fileDownloadId, {fields=null, include=null }={}) => {
    const { data: fileDownload } = await this.performRequest('xlsxStatus')({fileDownloadId, options: { ...fields && {fields}, ...include && {include} }})
    this.setState({ fileDownload })
    return fileDownload
  }
}
