import React, { Component } from 'react'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PromoIcon from '@material-ui/icons/MonetizationOn'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, userFriendlyDate } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, PromotionsContext } from 'contexts'
import Fab from '@material-ui/core/Fab'
import withStyles from 'styles'

export class List extends Component{

  state = {
    page: 1
  }

  static fetchDependencies({promotions, tile, page}){
    return tile.id && promotions.actions.index({
      page: page,
      params: { tileId: tile.id },
      fields: { promotions: 'title,internalName,activeStartsAt,activeEndsAt,active'}
    })
  }

  componentDidUpdate = prevProps => {
    if(prevProps.tile.id !== this.props.tile.id)
      this.props.onDependencyUpdate()
  }

  editPromotion = id => event => {
    this.props.history.push(`/promotions/${id}/edit`)
    event.stopPropagation()
  }

  deletePromotion = id => event => {
    this.props.promotions.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get promotions(){
    return this.props.promotions.list
  }

  handlePageSelected = page =>{
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors(){
    let errors = []
    if(this.props.promotions.errors.index){
      errors = errors.concat(this.props.promotions.errors.index)
    }
    if(this.props.promotions.errors.destroy){
      errors = errors.concat(this.props.promotions.errors.destroy)
    }
    return errors
  }

  renderPromotionListItem = ({id, title, internalName, activeStartsAt, activeEndsAt, active}) =>
    <ListItem button className={this.props.classes({active})} onClick={this.editPromotion(id)} key={id}>
      <ListItemIcon>
        <PromoIcon />
      </ListItemIcon>
      <ListItemText primary={
        <div>
          <div><b>{title}</b></div>
          <div>{internalName}</div>
        </div>
      } secondary={
        <>
          {userFriendlyDate(activeStartsAt)}
          –
          {userFriendlyDate(activeEndsAt)}
        </>
      }/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editPromotion(id)}><EditIcon/></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deletePromotion(id)}><DeleteIcon/></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.promotions.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.promotions.map(this.renderPromotionListItem)}
        </MuiList>
        <Pagination totalPages={this.props.promotions.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <Fab color='secondary' onClick={() => this.props.history.push(`/tiles/${this.props.tile.id}/promotions/new`)}>
          <AddIcon/>
        </Fab>
      </CardContent>
    </Card>
}

const styles = {
  active: {
    background: 'yellow'
  }
}
export default compose(
  Dependent,
  withStyles(styles),
  connectQueryString('promotions'),
  provide(PromotionsContext),
  consume(SnackbarContext),
)(List)