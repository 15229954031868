import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, SearchCombo, LabeledSelect, LabeledCheckbox, AutoSuggest, FormContext, ConfirmationDialog, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import UploadIcon from '@material-ui/icons/CloudUpload'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, debounce, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, TilesContext, CategoriesContext, MerchantsContext, OrderTypesContext, FileUploadsContext } from 'contexts'
import Typography from '@material-ui/core/Typography'
import { TextField } from '@material-ui/core'
import CategoryPicker from 'containers/categories/CategoryPicker'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from 'styles'

const UPLOAD_PARAM_DEFAULTS = {
  createUpdateTiles: false,
  createUpdatePromos: false,
  updateOrdering: false,
  uploadInBackground: true,
  timeZone: "Wellington",
}
export class List extends Component {

  state = {
    page: 1,
    uploading: false,
    confirmUploadOpen: false,
    uploadParams: { ...UPLOAD_PARAM_DEFAULTS }
  }

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  static fetchDependencies({ tiles, categories, filter, page }) {
    const categoriesLoaded = categories.list && categories.list.length > 0
    return Promise.all([
      categoriesLoaded ? false : List.fetchCategories({categories}),
      List.fetchTiles({tiles, filter, page}),
    ].filter(x => !!x))
  }

  static fetchCategories({ categories }) {
    return categories.actions.indexAllWithCountryCategoryHash({
      include: 'parent,allChildren',
      fields: {
        categories: 'name,label,parentId,parent,allChildren',
        allChildren: 'name,parentId,parent,allChildren',
        parent: 'name,parentId,parent,allChildren',
      }
    })
  }

  static fetchTiles({ tiles, filter, page }) {
    if (filter.merchant) {
      filter = { ...filter, merchant: undefined, merchantId: filter.merchant.id }
    }
    return tiles.actions.index({
      page: page,
      pageSize: 8,
      filter: filter,
      fields: {
        tiles: 'name,internalName,instore,online,global,comingSoon,currentTileUrl,currentLogoUrl,status,categories,activePromotion',
        categories: 'name,parentId',
        activePromotion: 'caption'
      },
      include: 'categories,activePromotion'
    })
  }

  loadTiles = () => {
    List.fetchTiles(this.props)
  }

  handleFilterChange = (filter) => {
    filter = Object.keys(filter).filter(key => !!filter[key]).reduce((obj, key) => ({...obj, [key]: filter[key]}), {})
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  showTile = id => () => {
    this.props.history.push(`/tiles/${id}/edit`)
  }

  editTile = id => event => {
    this.props.history.push(`/tiles/${id}/edit`)
    event.stopPropagation()
  }

  deleteTile = ({id, name, internalName}) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Tile Deletion', details: { name, internalName } })) {
      this.props.tiles.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get tiles() {
    return this.props.tiles.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.tiles.errors.index) {
      errors = errors.concat(this.props.tiles.errors.index)
    }
    if (this.props.tiles.errors.destroy) {
      errors = errors.concat(this.props.tiles.errors.destroy)
    }
    return errors
  }

  renderTileImages = ({ currentTileUrl, currentLogoUrl, name } = {}) =>
    <div className={this.props.classes.tileImages} draggable="false">
      <div draggable="false" style={{ background: currentTileUrl ? 'initial' : 'silver', borderRadius: 5 }}>
        {currentTileUrl && <img src={currentTileUrl} alt={name} draggable="false" />}
      </div>
      {
        (currentLogoUrl) ?
          <div draggable="false" style={{padding: '18.52%'}}>
            <img src={currentLogoUrl} alt={name} draggable="false" />
          </div> :
          false
      }
    </div>

  renderTileListItem = (tile) => {
    const { tag, promoTag, countryTag, thumb, indicator } = this.props.classes
    const { id, name, internalName, categories, instore, online, global, comingSoon, status, activePromotion } = tile
    const countries = (this.props.categories.categoryCountries && categories) ? Array.from(new Set(categories.map(c => this.props.categories.categoryCountries[c.id]))) : []
    const statusColor = status === "Active" ? "green" : status === "Inactive" ? "red" : "#888"
    return (
      <ListItem button onClick={this.showTile(id)} key={id} style={{ marginLeft: -16 }}>
        <ListItemIcon>
          <div className={thumb}>
            {this.renderTileImages(tile)}
          </div>
        </ListItemIcon>
        <div>
          <ListItemText primary={
            <span>
              <Tooltip title={status}>
                <div className={indicator} style={{ background: statusColor }} />
              </Tooltip>
              {name}
            </span>
          } secondary={internalName} />
          {countries.filter(c => !!c).map(country =>
            <span key={country} className={`${tag} ${countryTag}`}>{country}</span>
          )}
          {instore && <span className={tag}>INSTORE</span>}
          {online && <span className={tag}>ONLINE</span>}
          {global && <span className={tag}>GLOBAL</span>}
          {comingSoon && <span className={tag}>COMING SOON</span>}
          {activePromotion && <span className={`${tag} ${promoTag}`}>{activePromotion.caption}</span>}
        </div>
        <ListItemSecondaryAction>
          <IconButton onClick={this.editTile(id)}><EditIcon /></IconButton>
          {Authorization.superAdmin && <IconButton onClick={this.deleteTile(tile)}><DeleteIcon /></IconButton>}
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
      {(this.state.uploadErrors || []).map((error, i) => <div key={i}>{error}</div>)}
    </ErrorBanner>

  handleCategoryChosen = (category) => {
    this.handleFilterChange({ ...this.props.filter, categoryId: category.id, categoryLabel: category.name })
  }

  fetchMerchants = debounce(async (text, callback) => {
    const merchants = await this.props.merchants.actions.index({ pageSize: 5, filter: { name: text } })
    callback(merchants.map(m => ({ id: m.id, name: m.name })))
  }, 100)

  render = () => {
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter} searchTextMember="nameAndMerchant" />
    )
    return (<Card>
      <CardContent>
        {
          !this.props.embedded &&
          <>
            <Typography variant='h3' style={{ display: 'flex', marginBottom: 16 }}>
              Tiles
              <div style={{ flex: 1 }}></div>
              <input style={{ display: 'none' }} type='file' ref={this.fileInput} onChange={this.handleFileSelected} />
              <ConfirmationDialog title="Confirm Upload"
                open={this.state.confirmUploadOpen}
                onConfirm={this.handleUploadConfirm}
                onCancel={this.handleUploadCancel}
                confirmLabel="Confirm"
                cancelLabel="Cancel">
                <FormContext onChange={uploadParams => this.setState({ uploadParams })} context={this.state.uploadParams}>
                  {this.state.uploadWarning && <ErrorBanner>You must select one or more options</ErrorBanner>}
                  {Authorization.superAdmin && <div><LabeledCheckbox member="createUpdateTiles" label="Create & Update Tiles" /></div>}
                  <div><LabeledCheckbox member="createUpdatePromos" label="Create & Update Promos" /></div>
                  <div><LabeledSelect fullWidth member="timeZone" options={["Wellington", "Sydney", "London"]} /></div>
                  <div><LabeledCheckbox member="uploadInBackground" label="Send Results via Email" /></div>
                </FormContext>
              </ConfirmationDialog>
              {!Authorization.contentAdmin ? undefined: this.state.uploading ?
                <CircularProgress />
                :
                <Tooltip title='Update from file'>
                  <IconButton onClick={this.handleToggleFileInput} onDrop={this.handleFileDrop} onDragOver={this.handleDragOver}>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              }
              {!Authorization.contentAdmin ? undefined: this.state.exporting ?
                <CircularProgress /> :
                <Tooltip title='Export file'>
                  <IconButton onClick={this.handleExportFile}><DownloadIcon /></IconButton>
                </Tooltip>
              }
            </Typography>
            {this.renderErrorMessages()}
            <Pagination totalPages={this.props.tiles.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
           </>
        }
        <MuiList dense>
          {this.tiles.map(this.renderTileListItem)}
        </MuiList>
        <Pagination totalPages={this.props.tiles.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
      </CardContent>
    </Card>)
  }
}

const styles = theme => ({
  tileImages: {
    position: "relative",
    marginTop: "5px",
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    top: "5px",
    left: "5px",
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        width: 'auto',
        height: 'auto'
      }
    }
  },
  tag: {
    background: "#CCC",
    padding: '0px 4px 0px 4px',
    marginRight: 4,
    borderRadius: 4,
    fontSize: "0.8em",
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  countryTag: {
    background: "#533187",
    color: "#FFF",
  },
  promoTag: {
    background: "#786dff",
    color: "#FFF"
  },
  thumb: {
    width: 100,
    height: 80,
    marginRight: 4
  },
  indicator: {
    width: 10,
    height: 10,
    display: 'inline-block',
    marginRight: 5
  },
})

export default compose(
  Dependent,
  withConfirm,
  withStyles(styles),
  connectQueryString('tiles'),
  provide(
    TilesContext, CategoriesContext, OrderTypesContext,
    MerchantsContext, FileUploadsContext
  ),
  consume(SnackbarContext),
)(List)