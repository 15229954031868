import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import SegmentIcon from '@material-ui/icons/PeopleOutline'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, SegmentsContext } from 'contexts'
import { Typography } from '@material-ui/core'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ segments, page }) {
    return segments.actions.index({
      page: page,
      fields: { segments: 'name' }
    })
  }

  showSegment = id => () => {
    this.props.history.push(`/segments/${id}`)
  }

  editSegment = id => event => {
    this.props.history.push(`/segments/${id}/edit`)
    event.stopPropagation()
  }

  deleteSegment = id => event => {
    this.props.segments.actions.destroy({ id })
      .then(this.props.onDependencyUpdate)
      .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get segments() {
    return this.props.segments.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.segments.errors.index) {
      errors = errors.concat(this.props.segments.errors.index)
    }
    if (this.props.segments.errors.destroy) {
      errors = errors.concat(this.props.segments.errors.destroy)
    }
    return errors
  }

  renderSegmentListItem = ({ id, name }) =>
    <ListItem button onClick={this.showSegment(id)} key={id}>
      <ListItemIcon>
        <SegmentIcon />
      </ListItemIcon>
      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.editSegment(id)}><EditIcon /></IconButton>
        <IconButton onClick={this.deleteSegment(id)}><DeleteIcon /></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3'>Segments</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.segments.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <MuiList dense>
          {this.segments.map(this.renderSegmentListItem)}
        </MuiList>
        <Pagination totalPages={this.props.segments.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <FABFixed color='secondary' onClick={() => this.props.history.push('/segments/new')}>
          <AddIcon />
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent,
  connectQueryString('segments'),
  provide(SegmentsContext),
  consume(SnackbarContext),
)(List)