import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ExploreIcon from '@material-ui/icons/Explore'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Authorization, compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, ExploreFeedsContext } from 'contexts'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ exploreFeeds, page }) {
    return exploreFeeds.actions.index({
      page: page,
      fields: { exploreFeeds: 'segment,countryCode' }
    })
  }

  editExploreFeed = id => event => {
    this.props.history.push(`/explore_feeds/${id}/edit`)
    event.stopPropagation()
  }

  deleteExploreFeed = (id, countryCode, segment) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Explore Feed Deletion', details: { countryCode, segment } })) {
      this.props.exploreFeeds.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get exploreFeeds() {
    return this.props.exploreFeeds.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.exploreFeeds.errors.index) {
      errors = errors.concat(this.props.exploreFeeds.errors.index)
    }
    if (this.props.exploreFeeds.errors.destroy) {
      errors = errors.concat(this.props.exploreFeeds.errors.destroy)
    }
    return errors
  }

  renderExploreFeedListItem = ({ id, countryCode, segment }) =>
    <ListItem button onClick={this.editExploreFeed(id)} key={id}>
      <ListItemIcon>
        <ExploreIcon />
      </ListItemIcon>
      <ListItemText primary={countryCode} secondary={segment} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.editExploreFeed(id)}><EditIcon /></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deleteExploreFeed(id, countryCode, segment)}><DeleteIcon /></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3' style={{ display: 'flex' }}>
          Explore Feeds
          <div style={{ flex: 1 }}></div>
        </Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.exploreFeeds.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <MuiList dense>
          {this.exploreFeeds.map(this.renderExploreFeedListItem)}
        </MuiList>
        <Pagination totalPages={this.props.exploreFeeds.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <FABFixed color='secondary' onClick={() => this.props.history.push('/explore_feeds/new')}>
          <AddIcon />
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('exploreFeeds'),
  provide(ExploreFeedsContext),
  consume(SnackbarContext),
)(List)