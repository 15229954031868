import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { provide, PromotionsContext } from 'contexts'

export class Show extends Component{


  static fetchDependencies({promotions,  match: { params: { id }}}){
    return promotions.actions.show(id, {
      include: ''
    })
  }

  get promotion(){
    return this.props.promotions.selected
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Promotion - {this.promotion.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/promotions/${this.promotion.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Title</TableCell>
                <TableCell>{String(this.promotion.title || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Body</TableCell>
                <TableCell>{String(this.promotion.body || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Footer</TableCell>
                <TableCell>{String(this.promotion.footer || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Url</TableCell>
                <TableCell>{String(this.promotion.url || '')}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default provide(PromotionsContext)(Dependent(Show))