import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const MediaItems = ProtectedJsonAPI.extend(
  '',
  {
    create: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/media_items'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/media_items/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
  }
)