import React, { Component } from 'react'
import { provide, consume, SnackbarContext, AuditsContext } from 'contexts'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { compose, debounce, humanize, userFriendlyDate } from 'utils'
import { connectQueryString } from 'containers/shared'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Pagination,
  SearchCombo,
  MultiSelect,
  LinkButton,
  ConfirmationDialog,
  LabeledCheckbox,
  DatePicker,
} from 'components'
import withStyles from 'styles'

export class List extends Component {
  state = {
    page: 1
  }

  get audits() {
    return this.props.audits.list
  }

  get filter() {
    const { filter, filter: { event } } = this.props
    return { ...filter, event: (!event || Array.isArray(event)) ? event : [event] }
  }

  get changeset() {
    const changes = (this.state.showAudit && this.state.showAudit.papertrailChangeset) || {}
    return Object.keys(changes).map(k => ({ field: k, from: changes[k][0], to: changes[k][1] }))
  }

  static fetchDependencies({ audits, page, filter }) {
    return audits.actions.index({
      page,
      filter
    })
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  handleShowAudit = (audit) => () => {
    this.setState({ showAudit: audit })
  }

  humanizeValue(value) {
    if (value && isNaN(value) && moment(value).isValid()) {
      return userFriendlyDate(value)
    }
    else if (typeof value == 'boolean') {
      return value ? 'TRUE' : 'FALSE'
    } else {
      return value
    }
  }

  handleFilterChange = (filter) => {
    filter = Object.keys(filter).filter(key => !!filter[key]).reduce((obj, key) => ({...obj, [key]: filter[key]}), {})
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  eventColors = { destroy: '#FF000011', create: '#00FF0011', update: '#FFFF0011' }

  renderAudit = audit =>
    <TableRow key={audit.id} style={{ backgroundColor: this.eventColors[audit.event] }}>
      <TableCell>
        <LinkButton onClick={this.handleShowAudit(audit)} disabled={Object.keys(audit.papertrailChangeset || {}).length === 0}>
          {humanize(audit.event)}
        </LinkButton>
      </TableCell>
      <TableCell>{audit.itemType}</TableCell>
      <TableCell>{audit.itemId}</TableCell>
      <TableCell className={this.props.classes.linkContainer}>
        {audit.itemName.link ?
        <Link to={audit.itemName.link} target="_blank">
          {audit.itemName.text}
        </Link> : audit.itemName.text}
      </TableCell>
      <TableCell className={this.props.classes.linkContainer}>
        {audit.itemParentName.link ?
        <Link to={audit.itemParentName.link} target="_blank">
          {audit.itemParentName.text}
        </Link> : audit.itemParentName.text}
      </TableCell>
      <TableCell>{audit.userName || (audit.whodunnit ? `ID: ${audit.whodunnit} <deleted>` : '')}</TableCell>
      <TableCell>{userFriendlyDate(audit.createdAt)}</TableCell>
    </TableRow>

  render = () => {
    const everyEvent = ['create', 'update', 'destroy']
    const { classes } = this.props
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.filter} searchTextMember={'freeText'}>
        <div>
          <TextField label='Entity' member='itemType' fullWidth={false} />
          <TextField label='ID' member='itemId' fullWidth={false} />
        </div>
        <div>
          <DatePicker clearable label='Start Time' member='createdAtDateAfter' />
          <DatePicker clearable label='End Time' member='createdAtDateBefore' />
        </div>
        <div>
          <TextField member='userId' fullWidth={false} />
          <TextField member='userEmail' fullWidth={false} />
        </div>
        <div>
          <TextField member='relatedToTileId' fullWidth={false} />
          <TextField member='relatedToCategoryId' fullWidth={false} />
        </div>
        <div style={{marginTop: '0.5em'}}>
          <LabeledCheckbox member='userChangesOnly' type="checkbox" label="Hide System Changes" />
        </div>
        <div>
          <Typography variant="subtitle1">Events</Typography>
          <MultiSelect member="event" options={everyEvent} defaultOptions={everyEvent} unsetWhenDefault />
        </div>
      </SearchCombo>
    )

    const linkIfMediaItem = (field, value, child) => ['tile_image_id', 'logo_image_id'].includes(field) ? <Link to={`/media_items/${value}`} target="_blank">{child}</Link> : child

    return (
      <Card>
        <CardContent className={this.props.classes.cardContent} style={{ maxWidth: 'min(90vw, 1500px)' }}>
          <Typography variant='h3' paragraph>Audits</Typography>
          <br />
          <Pagination totalPages={this.props.audits.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Entity</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>On</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Create Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.audits.map(audit => this.renderAudit(audit))}
            </TableBody>
          </Table>
          <ConfirmationDialog title="Changes" onConfirm={this.handleShowAudit(null)} onClose={this.handleShowAudit(null)} open={!!this.state.showAudit}
            canCancel={false} maxWidth="xl"
          >
            <div className={classes.changes}>
              <Table breakpoint={400} className={classes.changes}>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Old Value</TableCell>
                    <TableCell>New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.changeset.map(change =>
                    <TableRow key={change.field}>
                      <TableCell>{humanize(change.field)}</TableCell>
                      <TableCell className={this.props.classes.linkContainer}>{linkIfMediaItem(change.field, change.from, this.humanizeValue(change.from))}</TableCell>
                      <TableCell className={this.props.classes.linkContainer}>{linkIfMediaItem(change.field, change.to, this.humanizeValue(change.to))}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </ConfirmationDialog>
        </CardContent>
      </Card>
    )
  }
}

const styles = (theme) => ({
  changes: {
    overflow: 'auto'
  },
  cardContent: {
    maxWidth: '90vw',
    minWidth: 1000,
  },
  linkContainer: {
    '& a': {
      textDecoration: 'none',
      color: '#786dff',
    }
  },
})

export default compose(
  Dependent,
  withStyles(styles),
  connectQueryString('audits'),
  provide(AuditsContext),
  consume(SnackbarContext),
)(List)