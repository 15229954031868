import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledCheckbox } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, jsNamingEnforcer } from 'utils'
import withStyles from 'styles'
import { provide, VariantTypesContext } from 'contexts'

export class Form extends InstanceFormMixin(Component, 'variantTypes') {

  static fetchDependencies({ variantTypes, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if (editMode) {
      return variantTypes.actions.show(id)
    }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Variant Type</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member="typeCode" label="Type Code" disabled={this.editMode} onChangeValueTransform={jsNamingEnforcer}
            helperText={(!this.error && !this.editMode) ? "Type Code should be in camel case with a lower case first letter, e.g. portraitTileImage" : undefined} />
          <TextField fullWidth member="description" />
          <TextField fullWidth member="width" type="number" />
          <TextField fullWidth member="height" type="number" />
          <LabeledCheckbox fullWidth member="enforcePixelSize" />
          <LabeledCheckbox fullWidth member="enforceRatio" />
          <LabeledCheckbox fullWidth member="enforceMinimumSize" />
        </CardContent>
        <CardActions>
          <Button color="secondary" fullWidth variant="contained" type="submit">Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(VariantTypesContext),
)(Form)