import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, DateTimePicker, FileDropZone, AutoSuggest, ConfirmationDialog, LabeledSelect } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, constrainDimensions, debounce, humanize } from 'utils'
import withStyles from 'styles'
import { provide, consume, TilesContext, MerchantsContext, CategoriesContext, VariantTypesContext, SnackbarContext, MediaItemsContext } from 'contexts'
import LabeledCheckbox from 'components/LabeledCheckbox'
import CategorySelector from 'containers/categories/CategorySelector'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { Promotion } from 'containers'
import TagList from 'containers/tiles/TagList'
import { List as StoreList } from 'containers/stores'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import CategoryPicker from 'containers/categories/CategoryPicker'

export class Form extends InstanceFormMixin(Component, 'tiles') {

  static fetchDependencies({ tiles, categories, variantTypes, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    const actions = []
    if (editMode) {
      actions.push(tiles.actions.show(id, { include: 'categories,merchant,tileTags' }))
    }
    actions.push(categories.actions.indexAll({ include: 'parent,allChildren' }))
    actions.push(variantTypes.actions.indexAll())
    return Promise.all(actions)
  }

  relationshipAttributes = ['categories']

  fetchMerchants = debounce(async (text, callback) => {
    const merchants = await this.props.merchants.actions.index({ pageSize: 5, filter: { name: text } })
    callback(merchants)
  }, 100)

  onDateChange = (key) => ({ target: { value } }) => {
    this.handleFormObjectChange({ ...this.formObject, [key]: value ? value : '' })
  }

  setImageValue = (key, value) => this.handleFormObjectChange({ ...this.formObject, images: { ...this.formObject.images, [key]: value } })

  handleImageChange = (variantType) => async ({ target: { value } }) => {
    if (value === null || value === "_delete") {
      this.setImageValue(variantType.typeCode, "_delete")
    } else {
      try {
        // Upload a MediaItem, then set the object
        const mediaItem = await this.props.mediaItems.actions.create({ file: value, definition: variantType })
        this.setImageValue(variantType.typeCode, mediaItem)
      } catch (error) {
        this.setImageValue(variantType.typeCode, "_delete")
        this.props.snackbar.actions.show(`${humanize(variantType.typeCode)}: ${(error?.meta?.file?.[0] || "is invalid or failed to upload")}`, 30000)
        console.warn(error)
      }
    }
  }

  onSaveError = () => {
    this.props.snackbar.actions.show('Error saving')
    window.scrollTo(0, 0)
  }

  render = () => {
    const variantsByCode = this.props.variantTypes.list.reduce((p, v) => ({ ...p, [v.typeCode]: v }), {})
    const newVariantOptions = this.props.variantTypes.list.filter(v => !Object.keys(this.formObject.images || {}).includes(v.typeCode)).sort((a, b) => a.typeCode.localeCompare(b.typeCode))
    return !this.props.loading && (<Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Tile - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name' />
          <TextField fullWidth member='url' />
          <Card className={this.props.classes.imagesCard}>
            <CardContent>
              <Typography variant='subtitle1'>Image Variants</Typography>
              <div className={this.props.classes.imageContainer}>
                {this.formObject.images && Object.keys(this.formObject.images).sort((a, b) => a.localeCompare(b)).map((key) => {
                  const variantType = variantsByCode[key]
                  const { width, height } = constrainDimensions(variantType?.width || 300, variantType?.height || 217, 300, 320)
                  return <div key={key}>
                    <FileDropZone member={`images.${key}`} onChange={this.handleImageChange(variantType)} width={width} height={height} type="image" noDropLabel immediateUpload clearable clearValue="_delete" />
                  </div>
                })}
                {newVariantOptions?.length > 0 && <div className={this.props.classes.addVariant}>
                  <OptionDialog title="Add Image Variant" keyField="typeCode" textField="typeCode" selectLabel="Variant Type"
                    options={newVariantOptions}
                    onConfirm={typeCode => this.setImageValue(typeCode, { url: undefined })}
                    renderOpener={onOpen =>
                      <IconButton onClick={() => onOpen()}><AddIcon /></IconButton>
                    } />
                </div>}
              </div>
            </CardContent>
          </Card>
          <TextField fullWidth member='internalName' />
          <AutoSuggest fullWidth member="merchant" labelProvider={item => item.name} onSuggestionsFetchRequested={this.fetchMerchants} />
          <TextField fullWidth member='callToAction' />
          <TextField fullWidth member='utmSource' />
          <TextField fullWidth member='utmMedium' />
          <TextField fullWidth member='utmCampaign' />
          <TextField fullWidth member='utmTerm' />
          <TextField fullWidth member='utmContent' />
          <DateTimePicker fullWidth member='activeStartsAt' clearable onChange={this.onDateChange('activeStartsAt')} />
          <DateTimePicker fullWidth member='activeEndsAt' clearable onChange={this.onDateChange('activeEndsAt')} />
          <LabeledCheckbox member='instore' type="checkbox" label="Instore" />
          <LabeledCheckbox member='online' type="checkbox" label="Online" />
          <LabeledCheckbox member='global' type="checkbox" label="Global" />
          <LabeledCheckbox member='comingSoon' type="checkbox" label="Coming Soon" />
          <LabeledCheckbox member='tapToPay' type="checkbox" label="Tap To Pay" />
          <LabeledCheckbox member='isAffiliateMerchant' type="checkbox" label="Is Affiliate Merchant" />
          <TextField fullWidth member='affiliateNetworkName' />
          <TextField fullWidth member='impressionUrl' />
          <CategorySelector member='categories' categories={this.props.categories.list} exactCategories={this.formObject.exactCategories} />
          <LabeledCheckbox member='exactCategories' type="checkbox" label="Allow Independent Selection Of Categories" />
          <CategoryPicker member='primaryCategoryId' categories={this.props.categories.list} type="selector" fullWidth />
          {
            this.editMode ?
              <>
                <Typography variant='h6'>Promotions</Typography>
                <Promotion.List tile={this.props.tiles.selected} />
              </> :
              false
          }
          <div style={{ marginTop: 16 }}>
            <Typography variant='h6'>Tags</Typography>
            <TagList member="tileTags" />
          </div>
          {this.editMode &&
            <div style={{ marginTop: 16 }}>
              <Typography variant='h6'>Stores</Typography>
              <StoreList embedded={true} tileId={this.objectId} />
            </div>
          }
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>)
  }
}

const OptionDialog = ({ onConfirm, renderOpener, selectLabel, keyField, textField, options, ...rest }) => {
  const [open, setOpen] = useState(false)
  const [option, setOption] = useState()
  return <>
    {renderOpener(() => setOpen(true))}
    <ConfirmationDialog
      open={open}
      onCancel={() => {
        setOpen(false)
        setOption(null)
      }}
      onConfirm={() => {
        onConfirm(option)
        setOption(null)
        setOpen(false)
      }}
      canConfirm={!!option}
      confirmLabel="Add"
      cancelLabel="Cancel"
      {...rest}>
      <LabeledSelect keyField={keyField} textField={textField} options={options} label={selectLabel} fullWidth
        value={option} onChange={({ target: { value } }) => setOption(value)} />
    </ConfirmationDialog>
  </>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  imagesCard: {
    margin: '8px 0px',
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 8,
    '& div': {
      marginRight: 10,
    },
  },
  addVariant: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  consume(SnackbarContext),
  provide(TilesContext),
  provide(MerchantsContext),
  provide(CategoriesContext),
  provide(VariantTypesContext),
  provide(MediaItemsContext),
)(Form)