import React, { Component, Fragment } from 'react'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ClearIcon from '@material-ui/icons/Clear'
import TickIcon from '@material-ui/icons/Check'
import UploadIcon from '@material-ui/icons/CloudUpload'
import LockIcon from '@material-ui/icons/Lock'
import LineWeightIcon from '@material-ui/icons/LineWeight'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Pagination, FormContext, LabeledSelect, LabeledCheckbox, ConfirmationDialog, ErrorBanner } from 'components'
import CircularProgress from '@material-ui/core/CircularProgress';
import { provide, consume, CategoriesContext, OrderTypesContext, OrderingsContext, TilesContext, SnackbarContext, FileUploadsContext } from 'contexts'
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import { InputAdornment } from '@material-ui/core'
import { compose, decodeJSONApiResponse, Authorization } from 'utils'
import withStyles from 'styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CategoryPicker from 'containers/categories/CategoryPicker'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import FeaturedTiles from './FeaturedTiles'

export const ITEMS_PER_PAGE = 8;
export const DEFAULT_ORDER = "Bestsellers";
const UPLOAD_PARAM_DEFAULTS = {
  createUpdateTiles: false,
  createUpdatePromos: false,
  updateOrdering: false,
  uploadInBackground: true,
  timeZone: "Wellington",
}
export class Grid extends Component {

  state = {
    currentPage: 1,
    filterText: '',
    uploading: false,
    confirmUploadOpen: false,
    uploadParams: { ...UPLOAD_PARAM_DEFAULTS }
  }

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  static fetchDependencies({ categories, orderTypes, match: { params: { id } } }) {
    return Promise.all([
      categories.actions.show(id, { include: 'parents' }),
      categories.actions.indexAll({ include: 'parent,allChildren' }),
      orderTypes.actions.indexAll()
    ])
  }

  get category() {
    return this.props.categories.selected
  }

  get orderTypes() {
    return this.props.orderTypes.list || []
  }

  get id() {
    return this.props.match.params.id
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (!this.state.orderTypeId && this.orderTypes.length > 0) {
      const defaultType = this.orderTypes.find(t => t.name === DEFAULT_ORDER) || this.orderTypes[0]
      this.setState({ orderTypeId: defaultType.id })
    }
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ orderTypeId: null, items: [] })
      await this.props.onDependencyUpdate()
    }
    if (this.state.orderTypeId && prevState.orderTypeId !== this.state.orderTypeId) {
      this.loadTiles()
    }
    if (!this.state.items || this.props.tiles.list !== prevProps.tiles.list) {
      this.setState({ items: this.props.tiles.list })
    }
    if (this.props.categories.selected !== prevProps.categories.selected) {
      this.setState({ menuOrderOpen: !this.parents.length })
    }
  }

  onGridOrderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (!targetId && !(sourceId === 'items' && targetIndex < ITEMS_PER_PAGE)) { return }
    const tileId = (sourceId === 'filtered') ? this.filteredItems[sourceIndex].id : this.currentItems[sourceIndex].id
    const tileIndex = this.state.items.findIndex(item => item.id === tileId)

    const newIndex = ((this.state.currentPage - 1) * ITEMS_PER_PAGE) + targetIndex
    const nextState = swap(this.state.items, tileIndex, newIndex);
    this.setState({ items: nextState });
  }

  loadTiles = () => {
    this.props.tiles.actions.indexAll({
      fields: { tiles: 'name,currentTileUrl,currentLogoUrl,tileOrderWeighting,tileOrderPinned' },
      filter: { categoryId: this.id, orderTypeId: this.state.orderTypeId }
    })
  }

  get parents() {
    return this.category.parents || []
  }
  get root() {
    return this.parents.length ? this.parents[this.parents.length - 1] : this.category
  }
  get orderings() {
    try {
      return decodeJSONApiResponse(this.props.tiles.meta.orderings).data
    } catch (err) {
      return []
    }
  }

  orderMenu = () => {
    this.props.history.push(`/categories/${this.root.id}/order`)
  }


  get ordering() {
    return this.orderings.find(ord => `${ord.orderTypeId}` === `${this.state.orderTypeId}`)
  }

  get items() {
    return (this.state.items || [])
  }

  get filteredItems() {
    return this.items.filter(item => (item.name.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1)).slice(0, 8)
  }

  get currentItems() {
    const startIdx = ((this.state.currentPage - 1) * ITEMS_PER_PAGE)
    return this.items.slice(startIdx, startIdx + ITEMS_PER_PAGE)
  }

  handleDeleteOrdering = async () => {
    await this.props.orderings.actions.destroy(this.ordering)
    this.loadTiles()
  }

  handleMakeDefaultOrdering = async () => {
    await this.props.orderings.actions.update({ ...this.ordering, default: true })
    this.loadTiles()
  }

  handleEditCategory = () => {
    this.props.history.push(`/categories/${this.category.id}/edit`)
  }

  handleGridItemMouseDown = event => {
    this._mouseDownCoords = [event.clientX, event.clientY]
  }

  handleGridItemClick = item => (event) => {
    const [startX, startY] = this._mouseDownCoords
    if (Math.sqrt(Math.pow(event.clientX - startX, 2) + Math.pow(event.clientY - startY, 2)) < 10)
      this.props.history.push(`/tiles/${item.id}/edit`)
  }

  handleNewTile = () => {
    this.props.history.push(`/tiles/new`)
  }

  handleCategoryChosen = category => {
    this.props.history.push(`/categories/${category.id}/grid`)
  }

  handleToggleFileInput = () => {
    this.fileInput.current.click()
  }

  handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleFileDrop = async event => {
    event.preventDefault();
    event.stopPropagation()
    const file = event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0]
    this.setState({ confirmUploadOpen: true, fileToUpload: file })
  }

  handleFileSelected = ({ target: { files: { "0": file } } }) => {
    this.fileInput.current.value = null
    this.setState({ confirmUploadOpen: true, fileToUpload: file })
  }

  handleFileUpload = async (file, uploadParams) => {
    this.setState({ uploading: true })
    const result = await this.props.fileUploads.actions.create({
      orderTypeId: this.state.orderTypeId,
      categoryId: this.id,
      ...uploadParams,
      file: file
    })
    const message = result.status === "Started" ? "Upload started, you will be emailed on completion" : result.status === "Success" ? "Uploaded Successfully" : "Error Uploading"
    this.props.snackbar.actions.show(message)
    if (result.status === "Success") { await this.loadTiles() }
    this.setState({ uploading: false, uploadErrors: result.errors })
  }

  handleExportFile = async () => {
    const orderType = this.props.orderTypes.list.find(ot => ot.id === this.state.orderTypeId)
    const autoSort = (orderType && orderType.sortMethod === 'auto')

    this.setState({ exporting: true })
    let fileDownload = await this.props.tiles.actions.xlsxGenerate({
      filter: { categoryId: this.id, orderTypeId: this.state.orderTypeId, pinnedAndWeightingInExport: autoSort },
    })

    while (true) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      fileDownload = await this.props.tiles.actions.xlsxStatus(fileDownload.id)
      if (fileDownload.status !== 'pending') {
        this.setState({ exporting: false })
        break;
      }
    }

    if (fileDownload.status === 'complete' && fileDownload.fileUrl) {
      window.location.href = fileDownload.fileUrl
    } else {
      console.log(fileDownload)
      this.props.snackbar.actions.show("Tile export failed")
    }
  }

  save = async () => {
    const success = await this.props.orderTypes.actions.updateCategoryOrder({
      id: this.state.orderTypeId,
      categoryId: this.id,
      tiles: this.state.items.map(item => ({
        id: item.id,
        ...(item.tileOrderPinned && { pinned: item.tileOrderPinned }),
        ...(item.tileOrderWeighting && { weighting: item.tileOrderWeighting }),
      }))
    })
    const message = success ? "Saved Successfully" : "Error Saving"
    this.props.snackbar.actions.show(message)
    this.loadTiles()
  }

  handlePinClicked = (tileId) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ items: this.state.items.map(item => item.id === tileId ? { ...item, tileOrderPinned: !item.tileOrderPinned } : item) })
  }

  handleWeightingClicked = (tileId) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    const item = this.items.find(item => item.id === tileId)
    this.setState({ editWeightingItem: { ...item } })
  }

  handleWeightingEditConfirm = () => {
    const tileId = this.state.editWeightingItem.id
    this.setState({ editWeightingItem: undefined, items: this.state.items.map(item => item.id === tileId ? this.state.editWeightingItem : item) })
  }

  renderTileImages = ({ id, currentTileUrl, currentLogoUrl, name, tileOrderPinned, tileOrderWeighting } = {}, showPins = false) =>
    <div className={this.props.classes.tileImages} draggable="false">
      <div draggable="false">
        <img src={currentTileUrl} alt={name} draggable="false" />
      </div>
      {
        (currentLogoUrl) ?
          <div draggable="false">
            <img src={currentLogoUrl} alt={name} draggable="false" />
          </div> :
          false
      }
      {showPins && <div style={{ color: 'white', width: '70px', height: '40px', background: '#FFFFFFAA', borderRadius: '4px', left: 'auto' }}>
        <IconButton style={{ padding: '4px' }} onClick={this.handleWeightingClicked(id)}>
          <LineWeightIcon style={(tileOrderWeighting ? { color: '#786dff' } : {})} />
        </IconButton>
        <IconButton style={{ padding: '4px' }} onClick={this.handlePinClicked(id)}>
          <LockIcon style={(tileOrderPinned ? { color: '#786dff' } : {})} />
        </IconButton>
      </div>}
    </div>

  handleUploadCancel = () => {
    this.setState({ confirmUploadOpen: false, uploadWarning: false, fileToUpload: undefined, uploadParams: { ...UPLOAD_PARAM_DEFAULTS } })
  }

  handleUploadConfirm = () => {
    if (['createUpdateTiles', 'createUpdatePromos', 'updateOrdering'].some(key => this.state.uploadParams[key])) {
      this.handleFileUpload(this.state.fileToUpload, this.state.uploadParams)
      this.setState({ confirmUploadOpen: false, fileToUpload: undefined, uploadWarning: false, uploadParams: { ...UPLOAD_PARAM_DEFAULTS } })
    } else {
      this.setState({ uploadWarning: true })
    }
  }

  render = () => {
    const orderType = this.props.orderTypes.list.find(ot => ot.id === this.state.orderTypeId)
    const autoSort = (orderType && orderType.sortMethod === 'auto')
    return (<Fragment>
      <input style={{ display: 'none' }} type='file' ref={this.fileInput} onChange={this.handleFileSelected} />
      {this.props.loading ? <div className={this.props.classes.progress}><CircularProgress /></div> : (<Card>
        <FormContext context={this.state} onChange={(state) => this.setState(state)} onSubmit={this.save}>
          <CardContent className={this.props.classes.cardContent}>
            {!!this.parents.length &&
              <div className={this.props.classes.breadcrumbs}>
                <span>{this.category.name}</span>
                {this.parents.map(({ id, name }) =>
                  <Link key={id} to={`/categories/${id}/grid`}>{name}</Link>
                )}
              </div>
            }
            <Typography variant='h4' style={{ display: 'flex' }}>
              Category - {this.category.name}&nbsp;
            <Tooltip title='Edit Category'>
                <IconButton onClick={this.handleEditCategory}><EditIcon /></IconButton>
              </Tooltip>
              <div style={{ flex: 1 }}></div>
              <ConfirmationDialog title="Confirm Upload"
                open={this.state.confirmUploadOpen}
                onConfirm={this.handleUploadConfirm}
                onCancel={this.handleUploadCancel}
                confirmLabel="Confirm"
                cancelLabel="Cancel">
                <FormContext onChange={uploadParams => this.setState({ uploadParams })} context={this.state.uploadParams}>
                  {this.state.uploadWarning && <ErrorBanner>You must select one or more options</ErrorBanner>}
                  {Authorization.superAdmin && <div><LabeledCheckbox member="createUpdateTiles" label="Create & Update Tiles" /></div>}
                  <div><LabeledCheckbox member="createUpdatePromos" label="Create & Update Promos" /></div>
                  <div><LabeledCheckbox member="updateOrdering" label="Update Category Order" /></div>
                  <div><LabeledSelect fullWidth member="timeZone" options={["Wellington", "Sydney", "London"]} /></div>
                  <div><LabeledCheckbox member="uploadInBackground" label="Send Results via Email" /></div>
                </FormContext>
              </ConfirmationDialog>
              <Tooltip title='Reorder Category Menu'>
                <IconButton onClick={this.orderMenu}><SortByAlphaIcon /></IconButton>
              </Tooltip>
              {this.state.uploading ?
                <CircularProgress />
                :
                <Tooltip title='Update from file'>
                  <IconButton onClick={this.handleToggleFileInput} onDrop={this.handleFileDrop} onDragOver={this.handleDragOver}>
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              }
              {!Authorization.contentAdmin ? undefined: this.state.exporting ?
                <CircularProgress /> :
                <Tooltip title='Export file'>
                    <IconButton onClick={this.handleExportFile}><DownloadIcon /></IconButton>
                </Tooltip>
              }
              <CategoryPicker onClick={this.handleCategoryChosen} categories={this.props.categories.list} />
            </Typography>
            <FeaturedTiles categoryId={this.id} />
            <Typography variant='h5'>Tile Order</Typography>
            <ErrorBanner>
              {(this.state.uploadErrors || []).map((error, i) => <div key={i}>{error}</div>)}
            </ErrorBanner>
            <LabeledSelect fullWidth label="Order Option" member="orderTypeId" options={this.props.orderTypes.list || []} keyField="id" textField="name" />
            <div className={this.props.classes.actionButtons}>
              {
                this.ordering ?
                  <>
                    <Button variant='contained' onClick={this.handleDeleteOrdering} >Delete Ordering</Button>
                    {
                      this.ordering.default ?
                        <Button variant='contained' disabled >Default <TickIcon /> </Button> :
                        <Button variant='contained' onClick={this.handleMakeDefaultOrdering} >Make Default </Button>
                    }
                  </> :
                  false
              }
              <Button color='secondary' variant='contained' type='submit'>Save Ordering</Button>
            </div>
            <GridContextProvider onChange={this.onGridOrderChange}>
              <TextField fullWidth member='filterText' label={"Type here to find a tile..."} style={{ marginBottom: 16 }} InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => this.setState({ filterText: '' })}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }} />
              {this.state.filterText && <GridDropZone
                id="filtered"
                boxesPerRow={8}
                rowHeight={90}
                disableDrop={true}
                style={{ height: 90, marginBottom: 16 }}
              >
                {this.filteredItems.map(item => (
                  <GridItem key={item.id}>
                    <div style={{ width: "100%", height: "100%" }}>
                      {this.renderTileImages({ ...item, ...item.activePromotion })}
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>}
              <Pagination totalPages={this.totalPages} page={this.state.currentPage} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
              <GridDropZone
                id="items"
                boxesPerRow={4}
                rowHeight={180}
                style={{ height: (Math.ceil(ITEMS_PER_PAGE / 4) * 180) }}
              >
                {this.currentItems.map(item => (
                  <GridItem key={item.id} onClick={this.handleGridItemClick(item)}>
                    <div className={this.props.classes.tile} onMouseDown={this.handleGridItemMouseDown} >
                      {this.renderTileImages({ ...item, ...item.activePromotion }, autoSort)}
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </GridContextProvider>
            <Fab className={this.props.classes.fab} color='primary' onClick={this.handleNewTile}>
              <AddIcon />
            </Fab>
            <br />
            <Pagination totalPages={this.totalPages} page={this.state.currentPage} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
          </CardContent>
        </FormContext>
      </Card>)}
      <ConfirmationDialog title="Edit Sort Weighting" open={!!this.state.editWeightingItem}
        onConfirm={this.handleWeightingEditConfirm} onCancel={() => this.setState({ editWeightingItem: undefined })}>
        <FormContext context={this.state} onChange={(state) => this.setState(state)}>
          <TextField member="editWeightingItem.tileOrderWeighting" onChange={this.handleTileOrderWeightingChange} />
        </FormContext>
      </ConfirmationDialog>
    </Fragment>)
  }

  handleTileOrderWeightingChange = ({ target: { value } }) => {
    if (value === '' || /^-?\d*\.?\d*$/.test(value)) {
      this.setState({ editWeightingItem: { ...this.state.editWeightingItem, tileOrderWeighting: value } })
    }
  }

  handlePageSelected = page => {
    this.setState({ currentPage: page })
  }

  get totalPages() {
    return Math.ceil(this.items.length / ITEMS_PER_PAGE)
  }
}

const styles = theme => ({
  cardContent: {
    position: 'relative'
  },
  tileImages: {
    position: "relative",
    marginTop: "5px",
    width: "calc(100% - 10px)",
    height: "calc(100% - 10px)",
    top: "5px",
    left: "5px",
    borderRadius: 8,
    overflow: 'hidden',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        width: 'auto',
        height: 'auto'
      }
    }
  },
  progress: {
    margin: theme.spacing(2),
    width: 300,
    height: 300
  },
  actionButtons: {
    marginTop: 10,
    display: 'flex',
    '& > button': {
      flex: '1'
    }
  },
  fab: {
    position: 'absolute',
    right: 0,
    bottom: 60,
  },
  tile: {
    width: '100%',
    height: '100%',
    '&::before': {
      opacity: 0,
      transition: "opacity 0.5s",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "-1",
      position: "absolute",
      borderRadius: "5px",
      background: "linear-gradient(-50deg,#ff7676,#f54ea2 45%,#994aff)",
    },
    '&:hover': {
      cursor: 'pointer',
      '&::before': {
        opacity: 1,
      }
    }
  },
  breadcrumbs: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'row-reverse',
    marginBottom: 3,
    '& a': {
      textDecoration: 'underline',
      '&::after': {
        content: "'>'",
        display: 'inline-block',
        margin: '0 10px',
        textDecoration: 'none'
      }
    }
  },
  expander: {
    transition: 'transform 300ms',
    '&.open': {
      transform: 'rotate(180deg)'
    }
  }
})

export default compose(
  Dependent,
  withStyles(styles),
  provide(OrderTypesContext),
  provide(OrderingsContext),
  provide(CategoriesContext),
  provide(TilesContext),
  provide(FileUploadsContext),
  consume(SnackbarContext),
)(Grid)