import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, SearchCombo, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CategoryIcon from '@material-ui/icons/Class'
import FeaturedIcon from '@material-ui/icons/Star'
import EditIcon from '@material-ui/icons/Edit'
import SortIcon from '@material-ui/icons/Sort'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, Authorization } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, CategoriesContext } from 'contexts'
import Typography from '@material-ui/core/Typography'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ categories, page, filter }) {
    return categories.actions.index({
      page: page,
      fields: { categories: 'name,parentId,label,featured' },
      filter: filter,
      include: 'parent',
      sort: 'parentId,appOnly,position,name'
    })
  }

  loadCategories = () => {
    List.fetchDependencies(this.props)
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.loadCategories)
  }

  orderCategory = id => () => {
    this.props.history.push(`/categories/${id}/grid`)
  }

  editCategory = id => event => {
    this.props.history.push(`/categories/${id}/edit`)
    event.stopPropagation()
  }

  deleteCategory = (id, category) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Category Deletion', details: { category } })) {
      this.props.categories.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get categories() {
    return this.props.categories.list
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.categories.errors.index) {
      errors = errors.concat(this.props.categories.errors.index)
    }
    if (this.props.categories.errors.destroy) {
      errors = errors.concat(this.props.categories.errors.destroy)
    }
    return errors
  }

  renderCategoryListItem = ({ id, label, featured }) =>
    <ListItem button onClick={this.orderCategory(id)} key={id}>
      <ListItemIcon>
        { featured ? <FeaturedIcon /> : <CategoryIcon /> }
      </ListItemIcon>
      <ListItemText primary={label} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.orderCategory(id)}><SortIcon /></IconButton>
        <IconButton onClick={this.editCategory(id)}><EditIcon /></IconButton>
        {Authorization.superAdmin && <IconButton onClick={this.deleteCategory(id, label)}><DeleteIcon /></IconButton>}
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () => {
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter}>
      </SearchCombo>
    )
    return (<Card>
      <CardContent>
        <Typography variant='h3' paragraph>Categories</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.categories.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
        <MuiList dense>
          {this.categories.map(this.renderCategoryListItem)}
        </MuiList>
        <Pagination totalPages={this.props.categories.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <FABFixed color='secondary' onClick={() => this.props.history.push('/categories/new')}>
          <AddIcon />
        </FABFixed>
      </CardContent>
    </Card>)
  }
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('categories'),
  provide(CategoriesContext),
  consume(SnackbarContext),
)(List)