import React          from 'react'
import ReactDOM          from 'react-dom'
import { capitalize } from 'utils'

export const ContextsByType = {}
export const Actions        = {}

const cacheContext   = (name, context) => {
  (ContextsByType[name] = ContextsByType[name] || []).push(context)
  Actions[name] = context.state.actions
}

const uncacheContext = (name, context) =>
  (ContextsByType[name] = ContextsByType[name].filter(ctx => ctx !== context))

const combinedState = () =>
  Object.entries(ContextsByType).reduce((agg, [type, contexts]) => ({
    ...agg,
    [type]: contexts.length === 1 ? contexts[0].state : contexts.map(({state}) => state)
  }), {})

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

const logRedux = (contextName, action, args=[]) => {
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__.send(
      `${capitalize(contextName)}Context.${action}(${JSON.stringify(args, getCircularReplacer()).replace(/^\[|\]$/g,'')})`,
      combinedState()
    )

 }


class BaseContext extends React.Component{

  static get Consumer(){
    return this.ReactContext.Consumer
  }

  static get Provider(){
    return this.ReactContext.Provider
  }

  static get ReactContext(){
    if(this.initialized)
      return this._ReactContext
    if(!this.contextName){
      throw(new Error(`A context should be named. ${this.name}.contextName is ${this.contextName}`))
    }
    this.initialized = true
    this._ReactContext = React.createContext(null)
    return this._ReactContext
  }

  get actions(){
    return this.propertyNames
               .filter(propertyName => propertyName !== 'constructor' && typeof this[propertyName] === 'function')
               .reduce((agg, propertyName) => ({...agg, [propertyName]: (...args) => {
                  logRedux(this.constructor.contextName, propertyName, args)
                  return this[propertyName](...args)
                 }}), {})
  }

  constructor(props){
    super(props)
    this.state = {...this.constructor.initialState}
  }

  get DOMNode(){
    return ReactDOM.findDOMNode(this)
  }

  get propertyNames() {
    const props = new Set();
    let obj = this
    do {
      if(obj.constructor === BaseContext) break
      Object.getOwnPropertyNames(obj).forEach(p => props.add(p));
    } while (obj = Object.getPrototypeOf(obj)); // eslint-disable-line
    return Array.from(props);
  }

  componentDidMount(){
    cacheContext(this.constructor.contextName, this)
    logRedux(this.constructor.contextName, 'mount')
    this._mounted = true
  }

  componentWillUnmount(){
    uncacheContext(this.constructor.contextName, this)
    logRedux(this.constructor.contextName, 'unmount')
    this._mounted = false
  }

  setState(state, cb){
    this._mounted && super.setState(state, (...args) => {
      logRedux(this.constructor.contextName, 'setState', state)
      cb && cb(...args)
    })
  }

  render(){
    this.state.actions = this.state.actions || this.actions // eslint-disable-line
    return (
      <this.constructor.Provider value={this.state}>
        {this.props.children}
      </this.constructor.Provider>
    )
  }
}
export default BaseContext