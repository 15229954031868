import React, { Component } from 'react'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, LabeledSelect } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, OrderTypesContext } from 'contexts'
import {Link} from 'react-router-dom'
import { MenuItem } from '@material-ui/core'

export class Form extends InstanceFormMixin(Component, 'orderTypes'){

  static fetchDependencies({orderTypes, match: { path, params: {id}}}){
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if(editMode){
      return orderTypes.actions.show(id)
    }
  }

  get formObject(){
    return { sortMethod: 'manual', sortDirection: 'asc', ...super.formObject }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Order Option - {this.formObject.name}</Typography>
      <small>(Change tile orders from the <Link to='/categories'>category</Link>)</small>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <LabeledSelect fullWidth member='sortMethod'>
            <MenuItem value='manual'>Manual</MenuItem>
            <MenuItem value='auto'>Auto</MenuItem>
          </LabeledSelect>
          <LabeledSelect disabled={this.formObject.sortMethod !== 'auto'} fullWidth member='sortDirection'>
            <MenuItem value='asc'>Ascending</MenuItem>
            <MenuItem value='desc'>Descending</MenuItem>
          </LabeledSelect>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto',
    '& a': {
      textDecoration: 'underline',
      color: '#786dff'
    }
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(OrderTypesContext),
)(Form)