import React, { Component } from 'react'
import { LoadedContext } from 'contexts'
import hoistNonReactStatic from 'hoist-non-react-statics';
/**
 *
 *
 */
export class Dependent extends Component{

  state = {
    dependenciesMet: false,
    loading: false
  }

  async componentDidMount(){
    if(!this.props.component.fetchDependencies){
      throw new Error("Dependent components must have a fetchDependencies method defined, none found on ", this.props.component)
    }
    this._mounted = true
    this.attemptFetchDependencies()
  }

  componentWillUnmount(){
    this._mounted = false
  }

  componentDidUpdate(){
    if(!this.state.fetchFailed)
      this.attemptFetchDependencies()
  }

  setState = (state) => {
    this._mounted && super.setState(state)
  }

  attemptFetchDependencies = async () => {
    if(!this.state.dependenciesMet && !this.state.loading){
      const depencyPromise = this.props.component.fetchDependencies(this.componentProps)
      try{
        this.setState({loading: true})
        await depencyPromise
        this.setState({dependenciesMet: true, loading: false})
      }
      catch(error){
        this.setState({loading: false, fetchFailed: true})
        this.handleDependenciesFailed(error)
      }
    }
  }

  get componentProps(){
    const { dependentOptions, component, ...componentProps } = this.props
    return componentProps
  }

  get Component(){
    return this.props.component
  }

  get loading(){
    return this.state.dependenciesMet
  }

  handleUpdateDependencies = async(...opts) => {
    this.setState({loading: true})
    try{
      await this.props.component.fetchDependencies(this.componentProps, ...opts)
    }
    catch(error){
      this.handleDependenciesFailed(error)
    }
    finally{
      this.setState({loading: false})
    }
  }

  redirect = (path) => {
    if (this.props.history)
      this.props.history.replace(path)
  }

  handleDependenciesFailed(error){
    error = error.length ? error[0] : error
    switch(error.status){
    case 403: break
    case 408: {
      this.redirect('/timed_out')
      break
    }
    case 504: {
      this.redirect('/timed_out')
      break
    }
    case 404: {
      this.redirect('/not_found')
      break
    }
    case undefined:
    case null: {
      console.error("Error ", arguments[0])
      break
    }
    default: {
      console.error("Redirecting due to error", error)
      this.redirect('/not_found')
      break
    }}
  }

  render(){
    return <LoadedContext.Provider value={{
      loading:         this.state.loading,
      dependenciesMet: this.state.dependenciesMet
    }}>
      <this.Component
        onDependencyUpdate={this.handleUpdateDependencies}
        loading={this.state.loading}
        dependenciesMet={this.state.dependenciesMet}
        {...this.componentProps}
      />
    </LoadedContext.Provider>
  }
}

export default (optionsOrComponent) => {

  const wrapWithDependent = (WrappedComponent, options= {}) => {
    const componentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    const withDependent = props => <Dependent {...props} component={WrappedComponent} dependentOptions={options} />
    withDependent.displayName = `WithDependent(${componentName})`
    hoistNonReactStatic(withDependent, WrappedComponent)
    return withDependent
  }

  return typeof optionsOrComponent === 'function' ?
    wrapWithDependent(optionsOrComponent) :
    component => wrapWithDependent(component, optionsOrComponent)
}

