import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, withConfirm } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import VariantIcon from '@material-ui/icons/AddPhotoAlternate'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { provide, consume, SnackbarContext, VariantTypesContext } from 'contexts'

export class List extends Component {

  state = {
    page: 1
  }

  static fetchDependencies({ variantTypes, page }) {
    return variantTypes.actions.index({
      page: page,
      fields: { variantTypes: 'typeCode' }
    })
  }

  showVariantType = id => () => {
    this.props.history.push(`/variant_types/${id}`)
  }

  editVariantType = id => event => {
    this.props.history.push(`/variant_types/${id}/edit`)
    event.stopPropagation()
  }

  deleteVariantType = (id, typeCode) => async event => {
    event.stopPropagation()
    if (await this.props.confirm({ title: 'Confirm Variant Type Deletion', details: { typeCode } })) {
      this.props.variantTypes.actions.destroy({ id })
        .then(this.props.onDependencyUpdate)
        .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    }
  }

  get variantTypes() {
    return this.props.variantTypes.list
  }
  typeCode
  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  get errors() {
    let errors = []
    if (this.props.variantTypes.errors.index) {
      errors = errors.concat(this.props.variantTypes.errors.index)
    }
    if (this.props.variantTypes.errors.destroy) {
      errors = errors.concat(this.props.variantTypes.errors.destroy)
    }
    return errors
  }

  renderVariantTypeListItem = ({ id, typeCode }) =>
    <ListItem button onClick={this.showVariantType(id)} key={id}>
      <ListItemIcon>
        <VariantIcon />
      </ListItemIcon>
      <ListItemText primary={typeCode} />
      <ListItemSecondaryAction>
        <IconButton onClick={this.editVariantType(id)}><EditIcon /></IconButton>
        <IconButton onClick={this.deleteVariantType(id, typeCode)}><DeleteIcon /></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h3'>Variant Types</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.variantTypes.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <MuiList dense>
          {this.variantTypes.map(this.renderVariantTypeListItem)}
        </MuiList>
        <Pagination totalPages={this.props.variantTypes.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} />
        <FABFixed color='secondary' onClick={() => this.props.history.push('/variant_types/new')}>
          <AddIcon />
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent,
  withConfirm,
  connectQueryString('variantTypes'),
  provide(VariantTypesContext),
  consume(SnackbarContext),
)(List)