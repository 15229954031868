import React, { Component } from 'react'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, Select }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Authorization } from 'utils'
import { provide, UsersContext }          from 'contexts'

export class Form extends InstanceFormMixin(Component, 'users'){

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit User - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <TextField fullWidth member='email'/>
          <Select disabled={this.editMode && !Authorization.superAdmin} fullWidth member='role'>
            <MenuItem value='member'>Member</MenuItem>
            <MenuItem value='admin'>Admin</MenuItem>
            <MenuItem value='super_admin'>Super Admin</MenuItem>
          </Select>
          {
            (this.createMode) &&
            <FormControlLabel control={
              <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                <Checkbox member='skipInvite'/>
              </FormContext>} label="Skip Invite"
            />
          }
          <br/>
          {
            ((this.editMode && Authorization.user.id === this.props.users.selected.id) || this.formObject.skipInvite) &&
            <CardContent className={this.props.classes.passwords}>
              <Typography variant='body1'>Password {this.editMode && '(Optional)'}</Typography>
              {
                Authorization.user.id === this.props.users.selected.id &&
                <TextField  type="password" fullWidth member="oldPassword"/>
              }
              <TextField type='password' fullWidth member='password'/>
              <TextField disabled={!this.formObject.password} type='password' fullWidth member='passwordConfirmation'/>
            </CardContent>
          }

        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  passwords: {
    marginTop: 5,
    background: theme.palette.grey[100]
  }
})

export default compose(
  Dependent,
  withStyles(styles),
  provide(UsersContext),
)(Form)