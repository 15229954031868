import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const FileUploads = ProtectedJsonAPI.extend(
  '',
  {
    undo: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/file_uploads/${id}/undo`
      }
      throw new ApiPathError('undo', {id})
    }),
    create: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/file_uploads'
    }),
    index: endpoint => endpoint.path(() => {
      return '/file_uploads'
    }),
  }
)