import React, { useState, useRef } from 'react'
import { ConfirmationDialog } from 'components'
import IconButton from '@material-ui/core/IconButton'
import UploadIcon from '@material-ui/icons/CloudUpload'
import Tooltip from '@material-ui/core/Tooltip'

export const UploadButton = ({ onUpload, renderConfirmationBody, tooltip = "Upload file" }) => {
  const fileInput = useRef()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [fileToUpload, setFileToUpload] = useState()

  const handleToggleFileInput = () => {
    fileInput.current.click()
  }

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleFileDrop = event => {
    event.preventDefault();
    event.stopPropagation()
    setFileToUpload(
      event.dataTransfer.items ? event.dataTransfer.items[0].getAsFile() : event.dataTransfer.files[0]
    )
    setConfirmOpen(true)
  }

  const handleFileSelected = ({ target: { files: { "0": file } } }) => {
    fileInput.current.value = null
    setFileToUpload(file)
    setConfirmOpen(true)
  }

  const handleUploadConfirm = () => {
    setConfirmOpen(false)
    onUpload && onUpload(fileToUpload)
  }

  const handleUploadCancel = () => {
    setConfirmOpen(false)
  }

  return (
    <>
      <input style={{ display: 'none' }} type='file' ref={fileInput} onChange={handleFileSelected} />
      <Tooltip title={tooltip}>
        <IconButton onClick={handleToggleFileInput} onDrop={handleFileDrop} onDragOver={handleDragOver}>
          <UploadIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        title="Confirm Upload"
        open={confirmOpen}
        onConfirm={handleUploadConfirm}
        onCancel={handleUploadCancel}
        confirmLabel="Confirm"
        cancelLabel="Cancel">
        { renderConfirmationBody ? renderConfirmationBody() : 'Confirm file upload' }
      </ConfirmationDialog>
    </>
  )
}