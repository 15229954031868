import { ApiPathError } from './generated'
import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Categories = ProtectedJsonAPI.extend(
  '',
  {
    updateOrder: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}/update_order`
      }
      throw new ApiPathError('update_order', {id})
    }),
    index: endpoint => endpoint.path(() => {
      return '/categories'
    }),
    create: endpoint => endpoint.useFormData(true).method('post').path(() => {
      return '/categories'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.useFormData(true).method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    updateWithJson: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/categories/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)