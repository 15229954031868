import React, { Component, useState } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledCheckbox, LabeledSelect, DraggableList } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { compose, jsNamingEnforcer } from 'utils'
import withStyles from 'styles'

import { provide, ModuleTypesContext } from 'contexts'

export class Form extends InstanceFormMixin(Component, 'moduleTypes') {

  static fetchDependencies({ moduleTypes, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    if (editMode) {
      return moduleTypes.actions.show(id)
    }
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Module Type - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          {this.createMode && <TextField fullWidth member="name" label="Module Name" />}
          <ModuleDefinitionEditor member="definition" classes={this.props.classes} editMode={this.editMode} />
        </CardContent>
        <CardActions>
          <Button color="secondary" fullWidth variant="contained" type="submit">Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const ModuleDefinitionEditor = ({ value, onChange, classes, editMode }) => {
  const [locked, setLocked] = useState(editMode)
  const handleChange = (value) => onChange({ target: { value } })
  const buildSection = () => ({ name: '', repeatable: false, attributes: [{ key: 1, type: '', name: '', required: false }] })
  return <div className={classes.mde}>
    <div>
      {locked && <Button onClick={() => setLocked(false)} style={{ textTransform: 'initial' }}>
        UNLOCK EDITOR&nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>WARNING: if the type is in use, editing it may cause app issues</span>
      </Button>}
      {
        <DraggableList value={value} onChange={handleChange} keyed={true}
          keyField="key" buildItem={buildSection} disabled={locked} render={(_, i, draggableProps) => (
            <Card key={i} className={classes.sectionCard} {...draggableProps}>
              <CardContent>
                <FormContext member={`${i}`} context={value} onChange={handleChange}>
                  <TextField member="name" label="Section Name" style={{ minWidth: 396, marginRight: 16 }} onChangeValueTransform={jsNamingEnforcer} disabled={locked} />
                  <LabeledCheckbox member="repeatable" label="Repeatable" labelClass={classes.cbLabel} disabled={locked} />
                  <ModuleAttributesEditor member="attributes" classes={classes} locked={locked} />
                </FormContext>
              </CardContent>
            </Card>
          )} />
      }
    </div>
  </div>
}

const ModuleAttributesEditor = ({ value, onChange, classes, locked }) => {
  const handleChange = (value) => onChange({ target: { value } })
  const buildAttribute = () => ({ type: '', name: '', required: false })
  return <div className={classes.attributes}>
    <Typography variant="subtitle1">Attributes</Typography>
    <DraggableList value={value} onChange={handleChange} refName="inputRef"
      keyed={true} keyField="key" buildItem={buildAttribute} disabled={locked} render={(_, i, draggableProps) =>
        <FormContext key={i} context={value} onChange={handleChange}>
          <ModuleAttributeEditor member={`${i}`} classes={classes} locked={locked} {...draggableProps} />
        </FormContext>
      } />
  </div>
}

const integerMutator = (value, previous) => {
  if (value === '') {
    return null
  }
  if (!`${value}`.match(/^[1-9][0-9]*$/)) {
    return previous
  }
  return parseFloat(value)
}

const StringListEditor = ({ value, label, onChange }) => {
  const [newValue, setNewValue] = useState('')
  value = Array.isArray(value) ? value : []

  const handleChange = (value) => {
    onChange({ target: { value } })
  }

  const addValue = () => {
    handleChange([...value, newValue])
    setNewValue('')
  }

  const removeValue = (i) => () => {
    const newList = [...value]
    newList.splice(i, 1)
    handleChange(newList)
  }

  return <div style={{ border: 'solid 1px #EFEFEF', borderRadius: 8, padding: 16, margin: '8px 0px' }}>
    <Typography variant='subtitle1'>{label}</Typography>
    <div>
      {(value || []).map((str, i) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>{str}</div>
        <div style={{ flex: 1 }}></div>
        <IconButton><RemoveIcon onClick={removeValue(i)} /></IconButton>
      </div>)}
    </div>
    <TextField onChange={({ target: { value } }) => setNewValue(value)} value={newValue} label="Add Value"
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={addValue}><AddIcon /></IconButton>
          </InputAdornment>
        )
      }}
    />
  </div>
}

const ModuleAttributeEditor = React.forwardRef(({ value, onChange, classes, locked, ...props }, ref) => {
  const handleChange = (value) => onChange({ target: { value } })
  const [open, setOpen] = useState(false)
  const renderDialogFields = {
    image: () => <FormContext context={value} member="dimensions" onChange={handleChange}>
      <TextField member="width" type="number" min="1" step="1" onChangeValueTransform={integerMutator} />
      <TextField member="height" type="number" min="1" step="1" onChangeValueTransform={integerMutator} />
      <br/>
      <LabeledCheckbox member="enforcePixelSize" labelClass={classes.cbLabel} />
      <LabeledCheckbox member="enforceRatio" labelClass={classes.cbLabel} />
      <LabeledCheckbox member="enforceMinimumSize" labelClass={classes.cbLabel} />
    </FormContext>,
    text: () => <>
      <TextField member="defaultValue" fullWidth />
      <StringListEditor member="permittedValues" />
    </>
  }
  return <div className={classes.attribute} ref={ref} {...props}>
    <FormContext context={value} onChange={handleChange}>
      <LabeledSelect member="type" options={['text', 'image', 'tile']} style={{ minWidth: 80, marginRight: 16 }} disabled={locked} />
      <TextField member="name" label="Attribute Name" style={{ minWidth: 300, marginRight: 16 }} disabled={locked} onChangeValueTransform={jsNamingEnforcer} />
      <LabeledCheckbox member="required" labelClass={classes.cbLabel} />
      {!!renderDialogFields[value?.type] && <><Tooltip title="More">
        <IconButton onClick={() => setOpen(true)}><MoreIcon /></IconButton>
      </Tooltip>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Additional Settings...</DialogTitle>
          <DialogContent style={{ paddingBottom: 24 }}>
            {renderDialogFields[value?.type]()}
          </DialogContent>
        </Dialog>
      </>
      }
    </FormContext>
  </div>
})

const styles = {
  mde: {
    marginTop: 16,
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto',
  },
  cbLabel: {
    fontSize: '0.85rem',
    lineHeight: 'initial',
    marginLeft: -4,
  },
  attributes: {
    marginTop: 16,
  },
  attribute: {
    marginTop: 16,
    padding: 8,
    border: 'solid 1px #EFEFEF',
    borderRadius: 8,
    position: 'relative',
  },
  sectionCard: {
    margin: '16px 0px'
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  provide(ModuleTypesContext),
)(Form)