import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import TileIcon from '@material-ui/icons/Apps'
import StoreIcon from '@material-ui/icons/LocalMall'
import PersonIcon from '@material-ui/icons/Person'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'utils'
import withStyles from 'styles'
import { LaybuyMono } from 'components'
import { consume, provide, TokensContext, VersionsContext } from 'contexts'

export class Nav extends Component {

  state = { drawerOpen: false }

  componentDidMount() {
    this.props.versions.actions.getTag()
  }

  handleRequestOpen = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }

  render = () =>
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <LaybuyMono />
            <Link to='/'>Shop Directory</Link>
          </Typography>
          <IconButton color="inherit" onClick={this.handleRequestOpen}>
            <PersonIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({ drawerOpen: true })}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={!!this.state.open}
        onClose={this.handleRequestClose}
      >
        <MenuItem onClick={this.props.tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{ paper: this.props.classes.drawer }}
        open={this.state.drawerOpen}
        onClose={() => this.setState({ drawerOpen: false })}
        onClick={() => this.setState({ drawerOpen: false })}
      >
        <Link to='/tiles'>
          <ListItem button>
            <ListItemIcon>
              <TileIcon />
            </ListItemIcon>
            <ListItemText primary="Tiles" />
          </ListItem>
        </Link>
      </Drawer>
    </Fragment>
}

const styles = theme => ({
  title: {
    flex: 1,
    '& > div': {
      top: 3,
      position: 'relative',
      display: 'inline-block',
      marginTop: 5,
      marginRight: 15
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  },
  drawer: {
    background: theme.palette.primary.dark,
    paddingTop: 10,
    '& a, & svg': {
      color: 'white',
      fill: 'white !important'
    },
    '& a > div': {
      paddingLeft: 30,
      paddingRight: 70
    }
  }
})
export default compose(
  consume(TokensContext),
  provide(VersionsContext),
  withStyles(styles)
)(Nav)