import React, { Component } from 'react'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledSelect, ModuleListEditor, Pagination } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, formatLocaleDateTime } from 'utils'
import withStyles from 'styles'
import { provide, consume, ExploreFeedsContext, ExploreFeedVersionsContext, ModuleTypesContext, SnackbarContext, SegmentsContext, CategoriesContext } from 'contexts'
import ExploreIcon from '@material-ui/icons/Explore'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

export class Form extends InstanceFormMixin(Component, 'exploreFeeds') {

  state = { versionsPage: 1 }

  static fetchDependencies({ exploreFeeds, exploreFeedVersions, moduleTypes, categories, segments, match: { path, params: { id } } }) {
    const editMode = !path.match(/\/new(?:\/:[^/]+)?$/)
    const moduleTypesPromise = moduleTypes.actions.index({ pageSize: 999 })
    const segmentsPromise = segments.actions.indexAll()
    const categoriesPromise = categories.actions.indexAll({ filter: { countriesOnly: true }, sort: 'name' })
    if (editMode) {
      return Promise.all([
        moduleTypesPromise,
        segmentsPromise,
        categoriesPromise,
        exploreFeeds.actions.show(id),
        exploreFeedVersions.actions.index({ pageSize: 5, filter: { exploreFeedId: id } }),
      ])
    } else {
      return Promise.all([
        moduleTypesPromise,
        categoriesPromise,
        segmentsPromise,
      ])
    }
  }

  renderVersionListItem = (version) => {
    const { id, publicationDate } = version
    return (
      <ListItem key={id} classes={{ container: this.props.classes.listItem }}>
        <ListItemIcon>
          <ExploreIcon />
        </ListItemIcon>
        <ListItemText secondary={"Publication Date"} primary={publicationDate ? formatLocaleDateTime(publicationDate, { includeSeconds: true }) : 'Unknown'} />
        <ListItemSecondaryAction className={this.props.classes.listItemSecondaryAction}>
          <Button onClick={this.editVersionAsDraft(version)}>Edit as Draft</Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  editVersionAsDraft = (version) => () => {
    if (confirm('Warning: this will overwrite any unsaved changes')) {
      this.handleFormObjectChange({ ...this.formObject, draftContent: version.content })
    }
  }

  handleSave = (publish) => async () => {
    const saveAction = this.editMode ?
      this.actions.update(this.attributesToSave, { publish }) :
      this.actions.create(this.attributesToSave, { publish })
    await this.save(saveAction)
  }

  onSaveError = () => {
    let errorCount = 0;
    try { errorCount = Object.keys(this?.error?.meta || {}).length } catch { }
    this.props.snackbar.actions.show(errorCount > 0 ? `Error saving: ${errorCount} fields with errors` : "Error saving", 5000)
    window.scrollTo(0, 0)
  }

  handleVersionsPage = (page) => {
    this.props.exploreFeedVersions.actions.index({ pageSize: 5, page, filter: { exploreFeedId: this.objectId } }),
      this.setState({ versionsPage: page })
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Explore Feed</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent style={{ maxWidth: 'calc(100vw - 32px)', width: 'calc(100vw - 32px)' }}>
          <LabeledSelect member="countryCode" options={this.props.categories.list} keyField="name" textField="name" fullWidth />
          <LabeledSelect member="segment" options={this.props.segments.list} keyField="name" textField="name" fullWidth />
          <Card style={{ marginTop: 16 }}>
            <Pagination totalPages={this.props.exploreFeedVersions.totalPages} page={this.state.versionsPage} onPageSelected={this.handleVersionsPage} startAdornment={
              <div style={{ display: 'flex', alignItems: 'center', height: '100%', padding: 4 }}>
                <Typography variant="subtitle1">Versions</Typography>
              </div>
            } style={{ background: 'red' }} />
            <MuiList dense>
              {this.props.exploreFeedVersions.list.map(this.renderVersionListItem)}
            </MuiList>
          </Card>
          <div>
            <ModuleListEditor member='draftContent' moduleTypes={this.props.moduleTypes.list} errors={this.error} />
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' onClick={this.handleSave(false)}>Save</Button>
          <Button color='primary' fullWidth variant='contained' onClick={this.handleSave(true)}>Save & Publish</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  },
  listItemSecondaryAction: {
    visibility: "hidden",
  },
  listItem: {
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit"
    }
  }
}

export default compose(
  Dependent,
  withStyles(styles),
  consume(SnackbarContext),
  provide(ExploreFeedsContext, ExploreFeedVersionsContext, ModuleTypesContext, SegmentsContext, CategoriesContext),
)(Form)